<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="addArticleToWarehouseModal" ref="modalEle" tabindex="-1" role="dialog"
         aria-labelledby="modelTitleId"
         aria-hidden="true">
      <div class="modal-dialog modal-full-width" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Wprowadź artykuł do magazynu głównego</h5>

            <button type="button" class="close ms-auto" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form class="modal-body" v-if="selectedArticle" @submit.prevent="submit">
            <div class="mb-5" style="max-width: 300px;">
              <label class="form-label" for="quantity">Ilość</label>
              <div id="quantityError" class="text-danger d-none">Ilość musi być większa niż 0</div>
              <input type="number" class="form-control form-control-sm" id="quantity" v-model="quantity">
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered">
                <thead>
                <tr>
                  <th>#</th>
                  <th>Nr katalogowy</th>
                  <th>Model</th>
                  <th>J.m.</th>
                  <th>Nazwa/Opis</th>
                  <th>Szczegóły</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>{{ selectedArticle.id }}</td>
                  <td>{{ selectedArticle.catalog_number }}</td>
                  <td>{{ selectedArticle.model }}</td>
                  <td>{{ selectedArticle.article_unit.name }}</td>
                  <td>{{ selectedArticle.description }}</td>
                  <td>{{ selectedArticle.details }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">zamknij</button>
              <button type="submit" class="btn btn-primary">Wprowadź na magazyn</button>
            </div>
          </form>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {Modal} from 'bootstrap';

export default {
  props: {
    selectedArticle: Object,
  },

  data() {
    return {
      quantity: 0,
      modalObj: null
    };
  },

  mounted() {
    // Poczekaj, aż Vue zrenderuje element
    this.$nextTick(() => {
      const modalElement = this.$refs.modalEle;
      if (modalElement) {
        this.modalObj = new Modal(modalElement);
      }
    });
  },

  methods: {
    async submit() {
      const error = document.getElementById('quantityError');
      error.classList.add('d-none');

      if (this.quantity <= 0) {
        error.classList.remove('d-none');
        return false;
      }

      try {
        const response = await axios.post(route('warehouse.article.add-to-main-warehouse', {
          'article': this.selectedArticle,
          'quantity': this.quantity
        }));

        this.$emit('added-success', response.data);
        this.hide();

      } catch (error) {
        console.log(error);
        this.$emit('added-error', error.response.data);
        return false;
      }

    },
    show() {
      if (this.modalObj) {
        this.modalObj.show();
      }
    },
    hide() {
      if (this.modalObj) {
        this.modalObj.hide();
      }
    },
  }
};
</script>