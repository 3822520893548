<template>
  <AppLayout>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-six">
        <div class="widget-heading">
          <h4 class="me-3 my-0">Harmonogram zasobów w projektach</h4>
          <div v-if="loggedAsAdmin" class="ms-auto">
            <CustomTasksModal :customProjects=availableCustomProjectsArr></CustomTasksModal>
          </div>
        </div>

        <div class="widget-content">
          <div id="errors" class="mt-4" v-if="$page.props.errors">
            <div class="alert alert-danger" v-for="error in $page.props.errors">
              {{ error }}
            </div>
          </div>
          <div id="message" class="text-danger mt-2">&nbsp;</div>

          <div id="legendList" class="legendList">
            <template v-for="project in availableProjects">
              <button class="legend" type="button"
                      :style="{'background-color': project.bg_color, 'color': project.color}"
                      :title="project.name" @click="handleFilterButtonClick(project.name)">
                {{ truncateText(project.name, 20) }}
              </button>
            </template>
          </div>

          <template v-if="isSubmiting">
            <div class="position-absolute w-100 h-100" style="z-index: 99; background-color:rgba(229,229,229,0.5)">
              <div style="margin: 0 auto; position: fixed; left: 50%; top: 50%;">
                <div class="spinner-grow text-danger me-2 align-self-center loader-sm"></div>
              </div>
            </div>
          </template>

          <div class="mt-3">
            <!-- :columns="columns"-->
            <ejs-gantt id="ganttId"
                       ref="ganttRef"
                       :height="ganttHeight"
                       :allowFiltering="true"
                       :allowResizing="true"
                       :allowReordering='true'
                       :allowRowDragAndDrop="true"
                       :allowSelection="true"
                       :allowSorting="true"
                       :highlightWeekends="true"
                       :enableMultiTaskbar="true"
                       :enablePersistence="false"
                       :enableCollapseAll="true"
                       :treeColumnIndex="1"
                       :dataSource="data"
                       :splitterSettings="splitterSettings"
                       :editSettings="editSettings"
                       :addDialogFields="addDialogFields"
                       :editDialogFields="editDialogFields"
                       :filterSettings="filterSettings"
                       :projectStartDate="projectStartDate"
                       :projectEndDate="projectEndDate"
                       :resourceFields="resourceFields"
                       :resources="resources"
                       :sortSettings="sortSettings"
                       :toolbar="toolbar"
                       :timelineSettings="timelineSettings"
                       :taskFields="taskFields"
                       :holidays="holidays"
                       :actionBegin="actionBegin"
                       :actionComplete="actionComplete"
                       :actionFailure="actionFailure"
                       :cellEdit="cellEdit"
                       :rowSelected="rowSelected"
                       :rowDataBound="rowDataBound"
                       :dataBound="dataBound"
                       :queryTaskbarInfo="queryTaskbarInfo"
                       :taskbarEditing="taskbarEditing"
                       :labelSettings="labelSettings"
                       :viewType="viewType">

              <e-columns>
                <e-column field="TaskName" headerText="Zadanie" width="150"></e-column>
                <e-column field="ProjectName" headerText="Projekt" width="150" editType="dropdownedit"
                          clipMode="EllipsisWithTooltip"></e-column>
                <e-column field="ProjectManager" headerText="Kierownik projektu" width="150"></e-column>
                <e-column field="Department" headerText="Dział" width="150"></e-column>
                <e-column field="StartDate" headerText="Data rozpoczęcia" width="150"></e-column>
                <e-column field="Duration" headerText="Trwanie" width="150"></e-column>
                <e-column field="EndDate" headerText="Data zakończenia" width="150"></e-column>
                <e-column field="Progress" headerText="Postęp" width="150"></e-column>
                <e-column field="bg_color" headerText="kolor tła" :visible="false"></e-column>
                <e-column field="color" headerText="kolor" :visible="false"></e-column>
              </e-columns>
            </ejs-gantt>
          </div>
        </div>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from "@/Layouts/App.vue";
import {
  DayMarkers,
  Edit,
  Filter,
  GanttComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
  Reorder,
  RowDD,
  Selection,
  Sort,
  Toolbar,
} from "@syncfusion/ej2-vue-gantt";
import {DropDownList} from "@syncfusion/ej2-dropdowns";
import {getInstance} from "@syncfusion/ej2-base";

let divElement;

let inputs = {
  dropdownedit: DropDownList,
};

import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-buttons/styles/material.css';
import '@syncfusion/ej2-calendars/styles/material.css';
import '@syncfusion/ej2-dropdowns/styles/material.css';
import '@syncfusion/ej2-navigations/styles/material.css';
import '@syncfusion/ej2-layouts/styles/material.css';
import '@syncfusion/ej2-popups/styles/material.css';
import '@syncfusion/ej2-richtexteditor/styles/material.css';
import '@syncfusion/ej2-treegrid/styles/material.css';
import "@syncfusion/ej2-vue-gantt/styles/material.css";
import {ref} from "vue";
import CustomTasksModal from "./ResourceScheduleComponents/CustomTasksModal.vue";

export default {
  components: {
    AppLayout,
    CustomTasksModal,
    'ejs-gantt': GanttComponent,
    'e-columns': ColumnsDirective,
    'e-column': ColumnDirective,
    DropDownList,
  },
  props: {
    tasksList: Object,
    availableProjectsArr: Object,
    availableCustomProjectsArr: Object,
    resourcesList: Object,
    viewStartDate: String,
    viewEndDate: String,
    loggedAsAdmin: Boolean,
  },

  mounted() {
    this.restoreGanttState();
  },

  data() {
    let availableProjects = this.availableProjectsArr;
    let self = this;
    let tasksData = this.tasksList; // projekty
    let resourceData = this.resourcesList; // osoby
    let isAdmin = this.loggedAsAdmin;
    let loggedUserId = this.$page.props.user.id;
    let standardToolbar = ['Add', 'Edit', 'Update', 'Delete', 'Cancel', 'ExpandAll', 'CollapseAll', 'ZoomIn', 'ZoomOut', 'ZoomToFit', 'Indent', 'Outdent'];
    let readOnlyToolbar = ['Add', 'Cancel', 'ExpandAll', 'CollapseAll', 'ZoomIn', 'ZoomOut', 'ZoomToFit'];
    let alreadyCollapsed = false;

    return {
      ganttHeight: this.calcHeight(80),
      isSubmiting: false,
      alreadyCollapsed: alreadyCollapsed,
      availableProjects,
      data: tasksData, // wszystkie tasks
      resources: resourceData, // wszyscy users
      resourceFields: {  // Mapowanie dla resources. Kolumny, które pojawiają się w szczegółach zadania->zakładka zasoby
        id: 'resourceId',
        name: 'resourceName',
        unit: 'resourceUnit',
        group: 'resourceGroup',
      },
      holidays: [
        // {
        //   from: '2019/04/20',
        //   to: '2019/04/27',
        //   cssClass: 'e-custom-holiday',
        //   label: 'Firma zamknięta, czy coś'
        // }
      ],
      viewType: 'ResourceView',
      taskFields: {  // pola dla szczegółów zadania
        id: 'TaskID',
        name: 'TaskName',
        projectName: 'ProjectName',
        startDate: 'StartDate',
        endDate: 'EndDate',
        duration: 'Duration',
        progress: 'Progress',
        dependency: 'Predecessor',
        child: 'subtasks',
        resourceInfo: 'resources',
        notes: 'Notes',
        segments: 'Segments'
        //work: 'Work', // TO powoduje, że nie są to paski a kwadraty przewrócone i nie da się resize'ować
      },
      addDialogFields: [
        {
          type: 'General',
          headerText: 'Główna',
          fields: ['TaskName', 'Progress', 'StartDate', 'EndDate', 'Duration']
        },
        {type: 'Dependency'},
        {type: 'Resources'},
        {type: 'Notes'},
        {type: 'Segments'},
      ],
      editDialogFields: [
        {
          type: 'General', headerText: 'General',
          fields: ['TaskName', 'Progress', 'StartDate', 'EndDate', 'Duration']
        },
        {type: 'Dependency'},
        {type: 'Resources'},
        {type: 'Notes'},
        {type: 'Segments'},
      ],
      editSettings: {
        allowAdding: true,
        allowEditing: true,
        allowDeleting: true,
        allowTaskbarEditing: true,
        showDeleteConfirmDialog: true,
        mode: 'Auto'
      },
      toolbar: standardToolbar,
      labelSettings: {
        leftLabel: '${ProjectName}',
        rightLabel: '${TaskName}',
        taskLabel: 'Progress'
      },
      splitterSettings: {
        columnIndex: 3
      },
      filterSettings: {
        type: 'Excel'
      },
      sortSettings: {
        type: 'Menu'
      },
      timelineSettings: {
        timelineViewMode: 'Month',
        showTooltip: true,
        weekStartDay: 1,
        updateTimescaleView: false,
        topTier: {
          unit: 'Month',
          formatter: (date) => {
            const currentDate = date;
            let d = currentDate.getDate();
            d = d < 10 ? '0' + d : d;
            let m = currentDate.getMonth();
            const month = date.toLocaleString('default', {month: 'long'});
            m = m < 10 ? '0' + m : m;
            let y = currentDate.getFullYear();

            const startOfYear = new Date(y, 0, 1);
            const millisecondsSinceStartOfYear = currentDate - startOfYear;
            const week = Math.ceil(millisecondsSinceStartOfYear / 604800000);
            return `${month} ${y}, w:${week}`
          },
        },
        bottomTier: {
          unit: 'Week',
        },
      },
      projectStartDate: this.viewStartDate,
      projectEndDate: this.viewEndDate,

      rowDataBound: function (args) {
        /**
         * kolorowanie wierszy
         */
        if (args.data.level === 0) {
          // jeżeli wiersz jest zasobem to koloruj na szaro
          args.row.style.backgroundColor = '#f5f5f5';
          return;
        }

        args.row.style.backgroundColor = args.data.bg_color;
        let cells = args.row.children;
        for (let cell of cells) {
          cell.style.color = args.data.color;
        }
        args.row.style.color = args.data.color;
      },

      dataBound: function (args) {
        if (this.alreadyCollapsed) return;

        this.alreadyCollapsed = true;
        let collapseAll = document.getElementById('ganttId_collapseall');
        if (collapseAll)
          collapseAll.click();
      },

      taskbarEditing: function (args) {
        /**
         * Wyłączanie edycji dla poszczególnych osób dla wykresu (taskbar)
         */
        if (!isAdmin && loggedUserId !== args.data.ProjectManagerId) {
          args.cancel = true;
          self.actionUnauthorized();
        }
      },

      queryTaskbarInfo: function (args) {
        /**
         * Wyłączanie widoczności edycji dla poszczególnych osób dla wykresu (taskbar)
         */
        if (!isAdmin && loggedUserId !== args.data.ProjectManagerId) {
          args.taskbarElement.classList.add('e-preventEdit');
          args.cancel = true;
        }

        /**
         * Kolorowanie taskbaru na kolor projektu
         */
        const bg_color = args.data.bg_color;
        const color = args.data.color;
        args.taskbarBgColor = bg_color;
        args.taskbarBorderColor = bg_color;
        args.taskLabelColor = color;
      },

      actionBegin: function (args) {
        /**
         * usuwanie- sprawdzanie, czy można
         */
        if (args.requestType === 'beforeDelete') {
          if (!isAdmin && loggedUserId !== args.data.ProjectManagerId) {
            args.cancel = true;
            self.actionUnauthorized();
          }
        }

        /**
         * Wyłączanie edycji dla niepowołanych osób
         */
        if (args.requestType === 'beforeOpenEditDialog') {
          const rowData = args.rowData || args.data;
          if (!isAdmin && loggedUserId !== rowData.ProjectManagerId) {
            args.cancel = true;

            self.actionUnauthorized();
          }
        }

        /**
         * Ustawianie kalendarza na dzisiejszy dzień startDate i endDate
         */
        if (args.requestType === "beforeOpenAddDialog") {
          args.rowData.StartDate = new Date();
          args.rowData.EndDate = new Date();
          args.rowData.ganttProperties.startDate = new Date();
        }

        /**
         * Dodawanie custom field do dialog add/edit
         * projectName
         */
        if (
            args.requestType === 'beforeOpenEditDialog' ||
            args.requestType === 'beforeOpenAddDialog'
        ) {
          var column = this.columnByField['ProjectName'];
          divElement = this.createElement('div', {
            className: 'e-edit-form-column',
          });
          var inputElement;
          inputElement = this.createElement('input', {
            attrs: {
              type: 'text',
              id: this.controlId + '' + column.field,
              name: column.field,
              title: column.field,
            },
          });

          let options = Object.values(availableProjects).map(item => {
            return {
              id: item.id,
              name: item.name,
            }
          });

          divElement.appendChild(inputElement);
          let input = {
            enabled: true,
            floatLabelType: 'Always',
            dataSource: options,
            fields: {text: 'name', value: 'name'},
            value: args.rowData.ProjectName,
            placeholder: 'Wybierz projekt',
          };
          let inputObj = new inputs[column.editType](input);
          inputObj.appendTo(inputElement);
        }

      },

      actionComplete(args) {
        /**
         * zapis ustawień gantta
         */
        if (args.requestType !== 'scroll' && args.requestType !== 'refresh') {
          const ganttInstance = document.getElementById('ganttId').ej2_instances[0];
          const ganttState = ganttInstance.getPersistData();

          axios.post(route('save-gantt-state'), {state: ganttState})
              .catch(error => console.error('Błąd zapisywania stanu Gantt', error));
        }

        /**
         * po otworzeniu dodawania lub edycji taska
         */
        if (args.requestType === 'openEditDialog' || args.requestType === 'openAddDialog') {
          var generalTab = document.getElementById(
              this.controlId + 'GeneralTabContainer'
          );
          generalTab.appendChild(divElement);
        }

        /**
         * Handler do renderowanie kalendarza customowego
         * np. dodanie weekNumbers (numer tygodnia)
         */
        if (args.requestType === 'openEditDialog' || args.requestType === 'openAddDialog') {
          let datepickers = args.element.ej2_instances[0].contentEle.querySelectorAll('.e-datepicker');
          if (datepickers)
            datepickers.forEach((datepicker, idx) => {
              datepicker.ej2_instances[0].weekNumber = true;
              datepicker.ej2_instances[0].weekRule = "FirstFourDayWeek";
            })
        }

        /**
         * Przy dodaniu lub aktualizowaniu danych, pokaż przycisk zapisu
         */

        if (args.requestType === 'add') {
          self.addTaskData(args);
        }
        if (args.requestType === 'save' || args.requestType === 'rowDropped') {
          self.modifyTaskData(args);
        }
        if (args.requestType === 'delete') {
          self.deleteTaskData(args);
        }
      },

      cellEdit(args) {
        /**
         * Wyłączanie edycji dla niepowołanych osób
         */
        const rowData = args.rowData || args.data;
        if (!isAdmin && loggedUserId !== rowData.ProjectManagerId) {
          args.cancel = true;
          self.actionUnauthorized();
        }
      },

      rowSelected(args) {

        /**
         * wyłączenie edycji i toolbara dla nieautoryzowanych osób
         * i dla usuwania zasobów i projektów
         */

        const rowData = args.data;
        const gantt = document.getElementById('ganttId').ej2_instances[0];

        // jeśli user nie jest adminem i nie jest kierownikiem tego projektu
        if (!isAdmin && loggedUserId !== rowData.ProjectManagerId) {
          gantt.toolbar = readOnlyToolbar;
        } else {
          gantt.toolbar = standardToolbar;
        }

        // jeśli jest to zasób to usuń edycje
        if (rowData.level === 0) {
          gantt.toolbar = readOnlyToolbar;
        }
      },

      actionFailure: function (args) {
        console.log(args);
        this.messageError(args);
      },
    };
  },
  provide: {
    gantt: [RowDD, Edit, Filter, Sort, Selection, Toolbar, Resize, Reorder, DayMarkers]
  },

  methods: {
    addTaskData(args) {
      this.submitChanges(args.newTaskData, 'new')
    },

    modifyTaskData(args) {
      this.submitChanges(args.modifiedRecords, 'modify')
    },

    deleteTaskData(args) {
      this.submitChanges(args.data, 'delete')
    },

    actionUnauthorized() {
      let message = document.getElementById("message");
      message.innerHTML = 'Brak autoryzacji';

      setTimeout(function () {
        message.innerHTML = '&nbsp;';
      }, 3000);
    },

    messageError(args) {
      let message = document.getElementById("message");
      message.innerHTML = 'Błąd: ' + args.error.message;

      setTimeout(function () {
        message.innerHTML = '&nbsp;';
      }, 3000);
    },

    truncateText(text, length) {
      if (!text) return '';
      if (text.length <= length) {
        return text;
      }

      return text.substring(0, length) + '...'
    },

    handleFilterButtonClick(filterText) {
      const ganttObj = document.getElementById('ganttId').ej2_instances[0];
      ganttObj.clearFiltering();
      const legendList = document.getElementById('legendList');
      let legendButtons = legendList.querySelectorAll('button');

      const thisIsFilterAlready = event.target.classList.contains('active');

      if (thisIsFilterAlready) {
        for (const btn of legendButtons) {
          btn.classList.remove('active');
          btn.classList.remove('unfiltered');
        }

        return;
      }

      for (const btn of legendButtons) {
        if (btn.innerText !== filterText) {
          btn.classList.add('unfiltered');
          btn.classList.remove('active');
        } else {
          btn.classList.remove('unfiltered');
          btn.classList.add('active');
        }
      }

      ganttObj.filterByColumn('ProjectName', 'equal', filterText);
    },

    submitChanges(task, action) {
      this.isSubmiting = true;
      this.$inertia.put(route('projects-resourceSchedule-update'), {
        task: task,
        action: action
      }, {
        preserveScroll: true,
        onSuccess: (response) => {
        },
        onError: (error) => {
          console.log(error);
        },
        onFinish: () => {
          this.isSubmiting = false;
        }
      })
    },

    calcHeight(vh = 80) {
      return vh * window.innerHeight / 100 + 'px';
    },

    restoreGanttState() {
      // Przywracanie stanu Gantt z bazy danych przy załadowaniu komponentu
      // axios.get(route('get-gantt-state'))
      //     .then((response) => {
      //       const savedState = response.data.state;
      //       if (savedState) {
      //         let ganttState = JSON.parse(savedState.state);
      //
      //         // Ensure dates are proper Date objects
      //         if (typeof ganttState.cloneProjectStartDate === 'string') {
      //           ganttState.cloneProjectStartDate = new Date(ganttState.cloneProjectStartDate);
      //         }
      //         if (typeof ganttState.cloneProjectEndDate === 'string') {
      //           ganttState.cloneProjectEndDate = new Date(ganttState.cloneProjectEndDate);
      //         }
      //
      //         // Ensure the Gantt instance is available
      //         const ganttInstance = this.$refs.ganttRef.$el.ej2_instances[0].setProperties(ganttState);
      //       }
      //     })
      //     .catch((error) => {
      //       console.error('Błąd podczas ładowania stanu Gantt:', error);
      //     });
    },
  }
}
;
</script>

<style>
.e-gantt-chart .e-preventEdit .e-right-resize-gripper,
.e-gantt-chart .e-preventEdit .e-left-resize-gripper,
.e-gantt-chart .e-preventEdit .e-progress-resize-gripper,
.e-gantt-chart .e-preventEdit .e-left-connectorpoint-outer-div,
.e-gantt-chart .e-preventEdit .e-right-connectorpoint-outer-div {
  display: none;
}

.legendList {
  display: inline-flex;
}

.legend {
  padding: 5px 10px;
  margin-right: 10px;
}

.legend.active {
  font-weight: 600;
  box-shadow: inset 0px 0px 0px 3px #d7b42a;
}

.legendList .unfiltered {
  filter: grayscale(100%) contrast(.8);
}
</style>
