<template>

  <button type="button" class="btn btn-sm btn-outline-info" data-bs-toggle="modal"
          :data-bs-target="'#modalHistory_'+model" @click="openModal">
    <i class="bi bi-clock-history"></i>&nbsp; Historia zmian
  </button>

  <!-- Modal -->
  <div class="modal fade" :id="'modalHistory_'+model" tabindex="-1" role="dialog" @click.self="closeModal"
       aria-labelledby="modelTitleId" aria-hidden="true" ref="modalHistory">
    <div class="modal-dialog modal-full-width" role="document">
      <div class="modal-content" :ref="'modalContent_'+model">
        <div class="modal-header">
          <h5 class="modal-title">Ostatnie zmiany</h5>
          <button type="button" class="close ms-auto" data-bs-dismiss="modal" aria-label="Close" @click="closeModal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body table-responsive text-start">
          <ejs-grid gridLines="Both" height='100%' ref="grid"
                    :allowTextWrap='false'
                    :dataSource="histories"
                    :allowPaging="false"
                    :allowSorting='false'
                    :allowFiltering='false'
                    :allowReordering='true'
                    :allowResizing='true'
                    :showColumnChooser='true'
                    :allowSelection='true'
                    :enablePersistence='false'
                    :dataBound="dataBound"
          >
            <e-columns>
              <e-column field='lp' headerText='Lp.' width="70" type="numeric"></e-column>
              <e-column field='project' headerText='Projekt' width="95" type="numeric"></e-column>
              <e-column field="user" headerText="Osoba" type="string"></e-column>
              <e-column field="overall" headerText="Ogólny" type="string"></e-column>
              <e-column field="work_schedule" headerText="Harmonogram prac" type="string"></e-column>
              <e-column field="design3d" headerText="Project 3D" type="string"></e-column>
              <e-column field="material_flow" headerText="Przepływ materiału" type="string"></e-column>
              <e-column field="electrical_diagram" headerText="Schemat elektryczny" type="string"></e-column>
              <e-column field="plc_program" headerText="Program PLC" type="string"></e-column>
              <e-column field="documentation" headerText="Dokumentacja" type="string"></e-column>
              <e-column field="layout" headerText="Layout" type="string"></e-column>
              <e-column field="created_by" headerText="Utworzone Przez" type="string"></e-column>
              <e-column field="created_at" headerText="Data Utworzenia" type="date" format="dd.MM.yyyy hh:mm:ss"></e-column>
              <e-column field="deleted_at" headerText="Data usunięcia" format="dd.MM.yyyy hh:mm:ss"></e-column>
              <e-column field="deleted_by" headerText="Usunięte Przez" type="string"></e-column>
            </e-columns>
          </ejs-grid>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="closeModal">Zamknij</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import route from "../../../../../vendor/tightenco/ziggy/src/js/index.js";

import {
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  Filter,
  GridComponent,
  Page,
  Reorder,
  Resize,
  Sort,
} from '@syncfusion/ej2-vue-grids';
import {ref} from "vue";

export default {
  components: {
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    'e-column': ColumnDirective
  },
  props: {
    project: Object,
    model: String
  },

  setup() {
    let isModalOpen = ref(false);

    const openModal = () => {
      isModalOpen.value = true;
    }

    return {isModalOpen}
  },

  data() {
    return {
      isLoading: false,
      histories: [],
      previousRowData: null,
    };
  },

  provide: {
    grid: [Page, Sort, Filter, Reorder, Resize, ColumnChooser]
  },

  watch: {
    isModalOpen(newValue) {
      if (newValue === true) {
        this.getHistoryForProjects()
      }
    }
  },

  methods: {

    getHistoryForProjects() {
      const self = this;
      self.isLoading = true;

      axios.post(route("projects.get-history-for-project", {'project': this.project, 'model': this.model}), {
        project: this.project,
        stringModel: this.model,
      })
          .then((response) => {
            self.histories = response.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
    },

    dataBound() {
      this.$nextTick(() => {
        let previousRowElement = null; // Zmienna do przechowywania poprzedniego elementu wiersza
        this.previousRowData = null; // Zresetuj `previousRowData` przed iteracją

        this.histories.forEach((data, rowIndex) => {
          const rowElement = this.$refs.grid.getRowByIndex(rowIndex);
          if (previousRowElement && this.previousRowData) {
            // Porównaj bieżący wiersz z `previousRowData`, dodając klasy do `previousRowElement`
            Object.keys(data).forEach((field, index) => {
              if (field !== 'lp' && data[field] !== this.previousRowData[field]) {
                const cell = previousRowElement.cells[index];
                if (cell) {
                  cell.classList.add('different-cell');
                }
              }
            });
          }

          // Zaktualizuj `previousRowData` i `previousRowElement` na potrzeby kolejnego wiersza
          this.previousRowData = { ...data };
          previousRowElement = rowElement;
        });
      });
    },


    openModal() {
      this.isModalOpen = true;
    },

    closeModal(model) {
      this.isModalOpen = false;
      this.histories = [];
      // Usunięcie nasłuchiwania zdarzenia hidden.bs.modal
      let refModal = 'modalHistory_' + model;
      $(this.$refs[refModal]).off("hidden.bs.modal", () => this.closeModal(model));
    },
  },
};
</script>

<style >
.different-cell{
  color:orangered !important;
  border:1px solid orangered !important;
}
</style>
