<template>
  <AppLayout>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-six">
        <div class="widget-heading d-block">
          <div class="row">
            <div class="col-xl-12 col-md-12 col-sm-12 col-12 d-flex align-items-center">
              <h4 class="me-3 my-0">Zapytania ofertowe</h4>
            </div>
            <div class="mt-3 row">

              <div class="col-md-12 col-lg-6">
                <label for="project-select">Wybierz projekt:</label>
                <select id="project-select" class="form-select form-select-sm" @change="updateSelectedProject"
                        v-model="selectedProjectId">
                  <option value="0" selected>Wszystkie projekty</option>
                  <option v-for="project in projects" :key="project.id" :value="project.id">
                    {{ project.label }}
                  </option>
                </select>
              </div>

              <div class="col-md-12 col-lg-6">
                <label for="team-select">Wybierz zespół:</label>
                <select id="team-select" class="form-select form-select-sm" @change="updateSelectedTeam"
                        v-model="selectedTeamName" :disabled="!selectedProjectId || (teams && teams.length === 0)">
                  <option value="">Wszystkie zespoły</option>
                  <option v-for="team in teams" :key="team.name" :value="team.name">
                    {{ team.name }}
                  </option>
                </select>
              </div>

            </div>
          </div>
        </div>

        <div class="widget-content">
          <div>
            <ejs-grid ref="quoteListGrid" id="quoteListGridId" height="300px"
                      :dataSource="quoteRequestsDataManager"
                      :allowTextWrap='false'
                      :allowExcelExport='true'
                      :allowPaging="true"
                      :allowSorting='true'
                      :allowFiltering='true'
                      :allowReordering='true'
                      :allowResizing='true'
                      :showColumnChooser='true'
                      :allowSelection='true'
                      :enablePersistence='true'
                      :pageSettings="pageSettings"
                      :editSettings="editSettings"
                      :filterSettings="filterOptions"
                      :toolbar="toolbar"
                      :contextMenuItems="contextMenuItems"
                      :selectionSettings="selectionOptions"
                      :aggregates="aggregateOptions"
                      :toolbarClick="clickToolbarHandler"
                      :contextMenuClick="clickContextMenuHandler"
                      :actionBegin="actionBeginHandler"
                      :actionComplete="actionCompleteHandler"
                      @queryCellInfo="queryCellInfo"
                      :rowSelected="onRowSelected"
                      :keyPressed="onKeyDown">
              <e-columns>
                <e-column field="id" headerText="ID" :allowEditing="false" :isPrimaryKey="true"
                          :visible="false" />
                <e-column field="request_number" headerText="Zapytanie ofertowe" :allowEditing="false"
                          :filter="filterDefault" width="180" type="string" />
                <e-column field="required_project_id" headerText="Z zapotrzebowania" :allowEditing="false"
                          :filter="filterDefault" type="string" />
                <e-column field="project_number" headerText="Projekt" :allowEditing="false"
                          :filter="filterDefault" type="string" />
                <e-column field="created_at" headerText="Data stworzenia" :allowEditing="false" format="dd.MM.yyyy"
                          :filter="filterDefault" type="date" />
                <e-column field="deliverers" headerText="Wysłano do" :allowEditing="false"
                          :filter="filterDefault" type="string" />
                <e-column field="sent_to_emails" headerText="Wysłano na maile" :allowEditing="false"
                          :filter="filterDefault" type="string" />
                <e-column field="suppliers" headerText="Wybrany dostawca" :allowEditing="false"
                          :filter="filterSuppliers" :dataSource="suppliers" type="string" editType="dropdownedit" />
                <e-column field="due_date" headerText="Termin odpowiedzi" :allowEditing="false"
                          :filter="filterDefault" format="dd.MM.yyyy" type="date" />
                <e-column field="status" headerText="Status" :allowEditing="true" editType="dropdownedit" type="string"
                          :dataSource="quoteRequestItemsStatus"
                          :edit="statusParams" :filter="filterStatuses" />
                <e-column field="created_by" headerText="Utworzone przez" :allowEditing="false"
                          :filter="filterDefault" />
                <e-column field="delivery_date" headerText="termin dostawy" :allowEditing="true"
                          format="dd.MM.yyyy" type="date" editType="datepickeredit" />
                <e-column field="delivery_date_deadline" headerText="Ostateczny termin dostawy" :allowEditing="true"
                          format="dd.MM.yyyy" type="date" editType="datepickeredit" />
                <e-column field="quoted_date" headerText="Data otrzymania wyceny" :allowEditing="true"
                          format="dd.MM.yyyy" type="date" editType="datepickeredit" />
                <e-column field="vat" headerText="VAT" :allowEditing="true" type="number"
                          :filter="filterNumeric" editType="numericedit" />
                <e-column field="rabate" headerText="Rabat" :allowEditing="true" editType="numericedit" format="N2"
                          :filter="filterNumeric" type="number" />
                <e-column field="quoted_price" headerText="Cena Netto" :allowEditing="true"
                          :filter="filterNumeric" editType="numericedit" format="N2" type="number" />
                <e-column field="currency" headerText="Waluta" :allowEditing="true" editType="dropdownedit"
                          :edit='currenciesParams' :filter="filterDefault" />
                <e-column field="comment" headerText="Komentarz" :allowEditing="true" :allowFiltering="false"
                          :filter="filterDefault" />

                <template v-slot:deliverersShortNamesTemplate="{data}">
                  {{ formatDeliverers(data) }}
                </template>
              </e-columns>

              <e-aggregates>
                <e-aggregate>
                  <e-columns>
                    <e-column type="Sum" field="quoted_price" format="N2"
                              groupCaptionTemplate='Suma: ${Sum}'></e-column>
                  </e-columns>
                </e-aggregate>
              </e-aggregates>

            </ejs-grid>

            <SelectedQuoteRequestItem :quoteRequestId="quoteRequestId" :quoteRequestItemsStatus="quoteRequestItemsStatus"
                                @refresh="this.$refs.addQuoteRequestRef.refresh()" :suppliers="suppliers" :units="units"
                                :selectedProjectId="selectedProjectId" :selectedTeamName="selectedTeamName" />
          </div>

        </div>
      </div>
    </div>

    <AddQuoteRequestItemsModal ref="addQuoteRequestRef" :project="selectedProjectId" :team="selectedTeamName"
                               :units="units" :suppliers="suppliers" @added-success="successMsg" @added-error="errorMsg"
                               @added-article="this.$refs.quoteListGrid.refresh()"/>

    <SendQuoteRequestModal ref="sendQuoteRequestModalRef" :selectedItems="selectedArticles"
                           @added-success="successMsg" @added-error="errorMsg"/>

  </AppLayout>
</template>

<script>
import AppLayout from "../../Layouts/App.vue";
import {useForm, usePage} from "@inertiajs/vue3";
import {AutoComplete, MultiSelect} from '@syncfusion/ej2-vue-dropdowns';
import {
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  ContextMenu,
  Aggregate,
  Edit,
  ExcelExport,
  Filter, ForeignKey,
  GridComponent,
  AggregatesDirective,
  AggregateDirective,
  Page,
  Reorder,
  Resize,
  Sort,
  Toolbar,
} from '@syncfusion/ej2-vue-grids';
import {createElement} from "@syncfusion/ej2-base";
import {DataManager, Predicate, Query, WebMethodAdaptor} from '@syncfusion/ej2-data';
import {ComboBox, DropDownList} from "@syncfusion/ej2-dropdowns";
import moment from "moment";
import {ref} from "vue";
import AddQuoteRequestItemsModal from "./AddQuoteRequestItemsModal.vue";
import SendQuoteRequestModal from "./SendQuoteRequestModal.vue";
import SelectedQuoteRequestItem from "./SelectedQuoteRequestItem.vue";
import SelectedOrderItems from "../OrderLists/SelectedOrderItems.vue";


let dropInstanceFilterUnits, statusElem, statusObj;
export default {

  components: {
    SelectedOrderItems,
    SelectedQuoteRequestItem,
    AddQuoteRequestItemsModal,
    SendQuoteRequestModal,
    AppLayout,
    MultiSelect,
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    'e-column': ColumnDirective,
    "e-aggregates": AggregatesDirective,
    "e-aggregate": AggregateDirective
  },

  provide: {
    grid: [Page, Edit, Toolbar, ContextMenu, Sort, Filter, Reorder, Resize, ColumnChooser, ExcelExport, ForeignKey, Aggregate]
  },

  props: {
    projects: Object,
    quoteRequestItemsStatus: Object,
    suppliers: Object,
    units: Object
  },

  beforeMount() {

    // Odczytanie danych z sesji
    const storedProjectId = sessionStorage.getItem('selectedProjectId');
    const storedTeam = sessionStorage.getItem('selectedTeam');

    if (storedProjectId) {
      this.selectedProjectId = (storedProjectId === 'null' || storedProjectId === null) ? 0 : storedProjectId;
      this.updateTeams();
    }

    this.selectedTeamName = storedTeam;
    this.getQutoeRequestsForProject();
  },

  data() {

    return {
      shouldCollapseByInitial: true,
      teams: [],
      quoteRequestsDataManager: [],
      fetchedOrders: [],
      quoteRequestId: null,
      selectedProjectId: null,
      selectedTeamName: null,
      editArticleModalRef: ref(null),
      showEditArticleModal: false,
      selectedArticleToEdit: null,
      selectedArticles: null,
      selectedRecordsToCreateOrder: null,
      pageSettings: {pageSize: 50, pageCount: 5},
      filterOptions: {type: "Menu", operator: 'contains'},
      filterDefault: {operator: 'contains', params: {minLength: 2}},
      filterNumeric: {operator: 'equal'},
      toolbar: this.getToolbar(),
      editSettings: this.getEditSettings(),
      selectionOptions: {cellSelectionMode: 'Box'},
      contextMenuItems: this.getContextMenu(),

      statusParams: {
        create: () => {
          return document.createElement('input');
        },
        read: () => {
          return this.comboBoxObj.value;
        },
        destroy: () => {
          this.comboBoxObj.destroy();
        },
        write: (args) => {
          this.comboBoxObj = new ComboBox({
            dataSource: this.quoteRequestItemsStatus,
            fields: {text: "name", value: "id"},
          });
          this.comboBoxObj.appendTo(args.element);
        }
      },

      currenciesParams: {
        create: () => {
          return document.createElement('input');
        },
        read: () => {
          return this.comboBoxObj.value;
        },
        destroy: () => {
          this.comboBoxObj.destroy();
        },
        write: (args) => {
          this.comboBoxObj = new ComboBox({
            dataSource: [
              {text: 'ZŁ', value: 'ZŁ'},
              {text: 'EUR', value: 'EUR'},
              {text: 'USD', value: 'USD'},
            ],
            fields: {text: "text", value: "value"},
          });
          this.comboBoxObj.appendTo(args.element);
        }
      },

      filterSuppliers: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.suppliers,
              fields: {text: 'short_name', value: 'short_name'},
              placeholder: 'Szukaj dostawców',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterWarehouses: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.warehouses,
              fields: {text: 'short_name', value: 'id'},
              placeholder: 'Szukaj magazynu',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterStatuses: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.quoteRequestItemsStatus,
              fields: {text: 'name', value: 'id'},
              placeholder: 'Szukaj statusu',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterUnits: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.units,
              fields: {text: 'name', value: 'id'},
              placeholder: 'Szukaj jednostki',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      inventoryStatusesParams: {
        create: () => {
          statusElem = document.createElement('input');
          return statusElem;
        },
        read: () => {
          return statusObj.text;
        },
        destroy: () => {
          statusObj.destroy();
        },
        write: () => {
          statusObj = new DropDownList({
            dataSource: this.inventory_statuses,
            fields: {value: 'name', text: 'name'},
            enabled: true,
            placeholder: 'Wybierz status',
            floatLabelType: 'Never'
          });
          statusObj.appendTo(statusElem);
        }
      },

      aggregateOptions: {
        columns: [
          {
            field: 'quoted_price',
            type: 'Sum',
            groupCaptionTemplate: 'Suma: ${Sum}', // Dla wierszy grupy
          },
        ],
      }
    }
  },

  methods: {
    getEditSettings() {
      return {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        showConfirmDialog: false,
        showDeleteConfirmDialog: false,
        mode: 'Batch',
        newRowPosition: 'Top',
      };
    },

    getToolbar() {
      return [
        {text: 'Dodaj', prefixIcon: 'e-add', id: 'add_btn'},
        {text: 'Usuń', prefixIcon: 'e-delete', id: 'delete_btn'},
        "Update",
        "Cancel",
        {text: "Resetuj filtry", prefixIcon: 'e-reset', id: 'reset_filters'},
        {text: "Search", prefixIcon: 'e-search', id: "search", align: 'center'},
        {text: "Przywróć domyślny", prefixIcon: 'e-undo', id: "restore", align: 'right'},
        {text: "Export do Excela", prefixIcon: 'e-excelexport', id: "excelexport", align: 'right'},
        "ColumnChooser",
      ];
    },

    clickToolbarHandler: async function (args) {
      /**
       * Niestandardowa akcja przy dodawaniu
       */
      if (args.item.text === 'Dodaj') {
        args.cancel = true;

        if (this.selectedProjectId && this.selectedTeamName)
          await this.$nextTick(() => {
            this.$refs.addQuoteRequestRef.show();
          });
      }

      /**
       * Akcja przy usuwaniu artykułu.
       */
      if (args.item.text === 'Usuń') {
        const selectedRecords = this.$refs.quoteListGrid.getSelectedRecords();
        if (selectedRecords.length > 0) {
          selectedRecords.forEach((record) => {
            this.$refs.quoteListGrid.deleteRecord("id", record);
          });
        }
      }

      /**
       * Akcja przy kliknięciu resetowania filtrów
       */
      if (args.item.id === 'reset_filters') {
        this.$refs.quoteListGrid.clearFiltering();
      }

      if (args.item.id === 'restore') {
        let grid = this.$refs.quoteListGrid;
        let gridName = 'grid' + grid.$el.id;
        grid.ej2Instances.enablePersistence = false;
        window.localStorage.setItem(gridName, "");
        grid.ej2Instances.destroy();
        location.reload();
      }

      if (args.item.id === 'excelexport') {
        this.$refs.quoteListGrid.excelExport({
          fileName: `zapytania ofertowe.xlsx`
        });
      }
    },

    getContextMenu() {
      return [
        {text: 'Wyślij zapytanie', target: '.e-content', id: 'send_quote_request'},
      ];
    },

    clickContextMenuHandler(args) {
      if (args.item.id === 'send_quote_request') {
        this.selectedArticles = this.$refs.quoteListGrid.getSelectedRecords();
        this.$nextTick(() => {
          this.$refs.sendQuoteRequestModalRef.show();
        });
      }
    },

    updateSelectedProject(event) {
      this.selectedProjectId = Number(event.target.value);
      this.teams = null;
      this.selectedTeamName = null;
      if (this.selectedProjectId) {
        this.updateTeams()
      }

      this.getRequiredProjectItemsForProject();
    },

    updateTeams() {
      if (this.selectedProjectId) {
        const project = this.projects.find(project => project.id === Number(this.selectedProjectId));
        if (project) {
          this.teams = project.teams;
          this.selectedTeamName = null;
        }
      } else {
        this.teams = [];
        this.selectedTeamName = null;
      }
    },

    updateSelectedTeam(event) {
      this.selectedTeamName = event.target.value;
      this.getQutoeRequestsForProject();
    },

    getQutoeRequestsForProject() {
      sessionStorage.setItem('selectedProjectId', this.selectedProjectId);
      sessionStorage.setItem('selectedTeam', this.selectedTeamName);

      this.quoteRequestsDataManager = new DataManager({
        url: route('quoteRequests.getList', {project: this.selectedProjectId, team: this.selectedTeamName}),
        adaptor: new WebMethodAdaptor(),
        batchUrl: route('quoteRequests.saveChanges'),
        updateUrl: route('quoteRequests.updateChanges'),
        crossDomain: true,
        headers: [
          {'X-CSRF-TOKEN': usePage().props.csrf_token}
        ],
      });
    },


    dateFormatter(field, data) {
      const date = data.created_at ? data.created_at : null;
      if (date) {
        try {
          return moment(date).format('DD.MM.YYYY HH:mm:ss');
        } catch (error) {
          return data
        }
      }

      return '';
    },

    queryCellInfo(args) {
      // Sprawdź, czy artykuł został usunięty
      if (args.column.field === "article.catalog_number" || args.column.field === "article.model") {
        if (args.data.article && args.data.article.deleted_at) {
          args.cell.setAttribute('title', 'Ten artykuł został usunięty!');
          args.cell.classList.add("deleted-article");
        }
      }
    },

    async onRowSelected(args) {
      this.quoteRequestId = args.data.id;
      this.selectedQuoteRequest = args.data;
    },

    onKeyDown: function (args) {
      let keyCode = args.which || args.keyCode;
      let isCtrlKey = (args.ctrlKey || args.metaKey) ? true : ((keyCode === 17));

      // code 83 to 'S'
      if (isCtrlKey && keyCode === 83) {
        args.preventDefault();

        // aktualizuj
        let toolbar = document.getElementsByClassName('e-toolbar-left')[0];
        let buttons = toolbar.querySelectorAll('.e-toolbar-item');
        let updateButton = buttons[2].querySelector('button');
        if (updateButton) {
          updateButton.click();

        }
      }
    },

    actionBeginHandler(args) {
      //
      // WYSZUKIWARKA- wyszukiwanie wielu wartości z separatorem (przecinek
      //
      if (args.requestType === 'searching') {
        const keys = args.searchString.split(',');
        let flag = true;
        let predicate;

        if (this.$refs.quoteListGrid.ej2Instances.searchSettings.key !== '') {
          this.values = args.searchString;
          keys.forEach((key) => {
            this.$refs.quoteListGrid.ej2Instances.getColumns().forEach((col) => {
              if (flag) {
                predicate = new Predicate(col.field, 'contains', key, true);
                flag = false;
              } else {
                let predic = new Predicate(col.field, 'contains', key, true);
                predicate = predicate.or(predic);
              }
            });
          });

          this.$refs.quoteListGrid.ej2Instances.query = new Query().where(predicate);
          this.$refs.quoteListGrid.ej2Instances.searchSettings.key = '';
          this.refresh = true;
          this.valueAssign = true;
          this.removeQuery = true;
          this.$refs.quoteListGrid.ej2Instances.refresh();
        } else {
          this.$refs.quoteListGrid.ej2Instances.searchSettings.key = '';
          this.refresh = true;
          this.valueAssign = true;
          this.removeQuery = true;
          this.$refs.quoteListGrid.ej2Instances.refresh();
        }
      }
    },

    actionCompleteHandler(args) {
      //
      // WYSZUKIWARKA - wyszukiwanie wielu wartości z separatorem (przecinek
      //
      if (args.requestType === "refresh") {
        const searchBar = document.getElementById(
            this.$refs.quoteListGrid.ej2Instances.element.id + "_searchbar"
        );

        if (this.valueAssign) {
          searchBar.value = this.values; // Przywraca wartość tylko raz
          this.valueAssign = false;
        }

        // Jeśli pasek wyszukiwania jest pusty i `removeQuery` jest aktywny, to resetujemy tylko raz
        if (searchBar.value.trim() === "" && this.removeQuery) {
          this.$refs.quoteListGrid.ej2Instances.query = new Query();
          this.removeQuery = false;
          this.$refs.quoteListGrid.ej2Instances.refresh();
        }
      }

      if (args.requestType === "batchsave") {
        if (args.changes && args.changes.changedRecords) {
          this.successMsg("Dane zostały pomyślnie zapisane!");
        } else if (args.error) {
          this.errorMsg("Wystąpił błąd podczas zapisu danych: " + args.error);
        }
      }
    },

    successMsg(resp) {
      Toast.fire({
        position: 'top-end',
        toast: true,
        icon: 'success',
        title: 'Sukces!',
        html: resp,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true
      });
    },

    errorMsg(resp) {
      Toast.fire({
        position: 'top-end',
        toast: true,
        icon: 'warning',
        title: 'Błąd!',
        html: resp,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true
      });
    },

    formatDeliverers(data) {
      // Paruj JSON i formatuj jako ciąg znaków
      try {
        const names = JSON.parse(data.deliverers_short_names);
        return names.join(', ');
      } catch (e) {
        // Jeśli dane nie są poprawnym JSON-em, zwróć je bez zmian
        return data.deliverers_short_names;
      }
    }

  }
}
</script>

<style scoped>
.not-editable {
  color: orangered;
}
</style>