<script setup>
import {defineProps, ref} from 'vue';

const {
  project,
  customers,
  users,
  additionalArrangements
} = defineProps(['project', 'customers', 'users', 'additionalArrangements', 'usersCanChange']);

let orgProject = ref({
  delivery_zip_code: project.delivery_zip_code ?? '',
  delivery_city: project.delivery_city ?? '',
  delivery_street: project.delivery_street ?? '',
  delivery_apartment: project.delivery_apartment ?? '',
  end_of_warranty: project.end_of_warranty ?? '',
  series: project.series ?? '',
  type: project.type ?? '',
  archived: project.archived === 1,
  order_opening_date: project.order_opening_date ?? '',
  planned_completion_date: project.planned_completion_date ?? '',
  fat: project.fat ?? '',
  deadline_for_completion: project.deadline_for_completion ?? '',
  conditional_acceptance: project.conditional_acceptance === 1,
  sales_representative_id: project.sales_representative_id ?? '',
  project_manager_id: project.project_manager_id ?? '',
  engineer_id: project.engineer_id ?? '',
  programmer_id: project.programmer_id ?? '',
  electrician_id: project.electrician_id ?? '',
  assembly_leader_id: project.assembly_leader_id ?? '',
  planning_manager_id: project.planning_manager_id ?? '',
  engineering_manager_id: project.engineering_manager_id ?? '',
  automation_manager_id: project.automation_manager_id ?? '',
  documentation_manager_id: project.documentation_manager_id ?? '',
});

const isLoading = ref(false);

const updateProject = async () => {
  isLoading.value = true;

  // usuwaj gwarancję, jeśli nie ma podanego ostatecznego terminu zakończenia
  if (!orgProject.value.deadline_for_completion || orgProject.value.deadline_for_completion === '') {
    orgProject.value.end_of_warranty = null;
  }

  try {
    const response = await axios.post(route('projects.update', project), {
      project: orgProject.value
    });
    if (response.status === 200) {
      await Toast.fire({
        position: 'top-end',
        toast: true,
        icon: response.data.icon,
        title: response.data.title,
        html: response.data.message,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true
      });
    } else {
      await Toast.fire({
        position: 'center',
        toast: false,
        icon: response.data.icon,
        title: response.data.title,
        html: response.data.message,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true
      });
    }
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
};

const changeDeadlineForCompletion = (newVal) => {
  orgProject.value.deadline_for_completion = newVal;
}
const changeEndOfWarranty = (newVal) => {
  orgProject.value.end_of_warranty = newVal;
}
const changeOpeningDate = (newVal) => {
  orgProject.value.order_opening_date = newVal;
}
const changePlannedCompletionDate = (newVal) => {
  orgProject.value.planned_completion_date = newVal;
}
const changeFat = (newVal) => {
  orgProject.value.fat = newVal;
}
let templateForDateElement = () => {
  return '<i class="bi bi-calendar" style="font-size 8px"></i>';
}
</script>

<style>
#projectTable tr th, #projectTable tr td {
  padding: .5rem;
}
</style>

<template>
  <AppLayout>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-six">
        <projectMenu :project="project"></projectMenu>
        <div class="widget-content">
          <div v-if="$page.props.errors">
            <ul style="list-style-type: none; padding: 0" v-for="error in $page.props.errors">
              <li class="alert alert-danger">{{ error }}</li>
            </ul>
          </div>
          <div class="mb-4 position-relative">
            <Link class="btn btn-sm btn-primary" :href="route('orderCard.index', project)">
              Otwórz kartę zlecenia
            </Link>
            <div class="mb-3 text-start float-end">
              <ProjectsHistory
                  :project="project"
                  :model="'project'">
              </ProjectsHistory>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-12 col-lg-12">
                <form @submit.prevent="updateProject">
                  <table id="projectTable" class="table table-bordered p-2">
                    <tr>
                      <th>Klient</th>
                      <td>
                        {{ project.customer?.name }}
                      </td>

                      <th class="text-end">Adres Klienta</th>
                      <td>
                        {{ project.customer?.customers_data?.address }}
                        {{ project.customer?.customers_data?.address2 }},
                        {{ project.customer?.customers_data?.zip_code }}
                        {{ project.customer?.customers_data?.city }}
                      </td>
                      <th class="text-end">Data końca gwarancja</th>
                      <td v-if="orgProject?.end_of_warranty">
                        <Vueform>
                          <DateElement id="conditional_acceptance"
                                       :addons="{ after: { template: templateForDateElement() }}"
                                       value-format="YYYY-MM-DD"
                                       load-format="YYYY-MM-DD"
                                       :default="orgProject.end_of_warranty"
                                       @change="changeEndOfWarranty"
                                       :extend-options="{weekNumbers: true}"
                                       name="conditional_acceptance">
                          </DateElement>
                        </Vueform>
                      </td>
                      <td v-else>
                        <Link class="link link-primary"
                              :href="route('projects.show.attachments', {project:project.id})">
                          <i>Prześlij plik SAT</i>
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th>Adres Dostawy</th>
                      <td colspan="5">
                        <div class="p-0 pe-2 d-inline-block w-25">
                          <label class="mb-0 small">Kod pocztowy</label>
                          <input type="text" v-model="orgProject.delivery_zip_code"
                                 class="border-1 form-control form-control-sm" placeholder="Kod pocztowy">
                        </div>
                        <div class="p-0 pe-2 d-inline-block w-25">
                          <label class="mb-0 small">Miasto</label>
                          <input type="text" v-model="orgProject.delivery_city"
                                 class="border-1 form-control form-control-sm" placeholder="Miasto">
                        </div>
                        <div class="p-0 pe-2 d-inline-block w-25">
                          <label class="mb-0 small">Ulica</label>
                          <input type="text" v-model="orgProject.delivery_street"
                                 class="border-1 form-control form-control-sm" placeholder="Ulica">
                        </div>
                        <div class="p-0 pe-2 d-inline-block w-25">
                          <label class="mb-0 small">Nr lokalu</label>
                          <input type="text" v-model="orgProject.delivery_apartment"
                                 class="border-1 form-control form-control-sm" placeholder="Nr lokalu">
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Numer Oferty</th>
                      <td>
                          <a v-if="project.offer_id"
                             :href="route('offerPdf.preview.from-offer', {offer:project.offer_id})"
                             class="btn btn-sm btn-outline-success" target="_blank">
                            <i class="bi bi-search"></i>
                            <span class="ms-2">{{ project.active_offer_version?.number }}</span>
                          </a>
                      </td>

                      <th class="text-end">Seria</th>
                      <td>
                        <input type="text" class="form-control form-control-sm border-1" v-model="orgProject.series">
                      </td>

                      <th class="text-end">Typ</th>
                      <td>
                        <input type="text" class="form-control form-control-sm border-1" v-model="orgProject.type">
                      </td>
                    </tr>
                    <tr>
                      <th>Data otwarcia zlecenia</th>
                      <td>
                        <Vueform>
                          <DateElement id="order_opening_date"
                                       :addons="{ after: { template: templateForDateElement() }}"
                                       value-format="YYYY-MM-DD"
                                       load-format="YYYY-MM-DD"
                                       :default="orgProject.order_opening_date"
                                       @change="changeOpeningDate"
                                       :extend-options="{weekNumbers: true}"
                                       name="order_opening_date">
                          </DateElement>
                        </Vueform>
                      </td>

                      <th class="text-end">
                        <div>
                          Planowana data zakończenia (SAT)
                        </div>
                        <div>
                          Data FAT
                        </div>
                      </th>
                      <td>
                        <div>
                          <Vueform>
                            <DateElement id="planned_completion_date"
                                         :addons="{ after: { template: templateForDateElement() }}"
                                         value-format="YYYY-MM-DD"
                                         load-format="YYYY-MM-DD"
                                         :default="orgProject.planned_completion_date"
                                         @change="changePlannedCompletionDate"
                                         :extend-options="{weekNumbers: true}"
                                         name="planned_completion_date">
                            </DateElement>
                          </Vueform>
                        </div>
                        <div>
                          <Vueform>
                            <DateElement id="fat"
                                         :addons="{ after: { template: templateForDateElement() }}"
                                         value-format="YYYY-MM-DD"
                                         load-format="YYYY-MM-DD"
                                         :default="orgProject.fat"
                                         @change="changeFat"
                                         :extend-options="{weekNumbers: true}"
                                         name="fat">
                            </DateElement>
                          </Vueform>
                        </div>
                      </td>

                      <th class="text-end">Ostateczny termin zakończenia</th>
                      <td>
                        <div class="pb-0">
                          <Vueform>
                            <DateElement id="conditional_acceptance" class="mb-2"
                                         :addons="{ after: { template: templateForDateElement() }}"
                                         value-format="YYYY-MM-DD"
                                         load-format="YYYY-MM-DD"
                                         :default="orgProject.deadline_for_completion"
                                         @change="changeDeadlineForCompletion"
                                         :extend-options="{weekNumbers: true}"
                                         name="conditional_acceptance">
                            </DateElement>
                          </Vueform>
                          <div class="checkbox-success custom-control custom-checkbox text-color pb-2">
                            <input id="conditionalAcceptance" type="checkbox" class="custom-control-input" value="1"
                                   v-model="orgProject.conditional_acceptance">
                            <label for="conditionalAcceptance" class="custom-control-label">Czy odbiór
                              warunkowy?</label>
                          </div>
                          <div
                              v-if="orgProject.deadline_for_completion && $page.props.user.permissions.includes('canArchiveRestoreProjects')"
                              class="pb-0">
                            <div class="checkbox-success custom-control custom-checkbox text-color">
                              <input id="archived" type="checkbox" class="custom-control-input"
                                     v-model="orgProject.archived" value="1">
                              <label for="archived" class="custom-control-label">Czy zarchiwizować projekt?</label>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Przedstawiciel handlowy</th>
                      <td>
                        <select class="form-select form-select-sm border-1"
                                v-model="orgProject.sales_representative_id">
                          <option></option>
                          <option v-for="user in users" :value="user.id">
                            {{ user.first_name }} {{ user.last_name }} - {{ user.position }}
                          </option>
                        </select>
                      </td>

                      <th class="text-end">Kierownik projektu</th>
                      <td>
                        <select class="form-select form-select-sm border-1" v-model="orgProject.project_manager_id">
                          <option></option>
                          <option v-for="user in users" :value="user.id">
                            {{ user.first_name }} {{ user.last_name }} - {{ user.position }}
                          </option>
                        </select>
                      </td>

                      <th class="text-end">Inżynier projektu</th>
                      <td>
                        <select class="form-select form-select-sm border-1" v-model="orgProject.engineer_id">
                          <option></option>
                          <option v-for="user in users" :value="user.id">
                            {{ user.first_name }} {{ user.last_name }} - {{ user.position }}
                          </option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <th>Programista/Automatyk</th>
                      <td>
                        <select class="form-select form-select-sm border-1" v-model="orgProject.programmer_id">
                          <option></option>
                          <option v-for="user in users" :value="user.id">
                            {{ user.first_name }} {{ user.last_name }} - {{ user.position }}
                          </option>
                        </select>
                      </td>

                      <th class="text-end">Elektryk</th>
                      <td>
                        <select class="form-select form-select-sm border-1" v-model="orgProject.electrician_id">
                          <option></option>
                          <option v-for="user in users" :value="user.id">
                            {{ user.first_name }} {{ user.last_name }} - {{ user.position }}
                          </option>
                        </select>
                      </td>

                      <th class="text-end">Lider montażu</th>
                      <td>
                        <select class="form-select form-select-sm border-1" v-model="orgProject.assembly_leader_id">
                          <option></option>
                          <option v-for="user in users" :value="user.id">
                            {{ user.first_name }} {{ user.last_name }} - {{ user.position }}
                          </option>
                        </select>
                      </td>
                    </tr>

                    <tr>
                      <th>Kierownik działu planowania</th>
                      <td>
                        <select class="form-select form-select-sm border-1" v-model="orgProject.planning_manager_id">
                          <option></option>
                          <option v-for="user in users" :value="user.id">
                            {{ user.first_name }} {{ user.last_name }} - {{ user.position }}
                          </option>
                        </select>
                      </td>

                      <th class="text-end">Kierownik działu konstrukcyjnego</th>
                      <td>
                        <select class="form-select form-select-sm border-1" v-model="orgProject.engineering_manager_id">
                          <option></option>
                          <option v-for="user in users" :value="user.id">
                            {{ user.first_name }} {{ user.last_name }} - {{ user.position }}
                          </option>
                        </select>
                      </td>

                      <th class="text-end">Kierownik działu automatyki i programowania</th>
                      <td>
                        <select class="form-select form-select-sm border-1" v-model="orgProject.automation_manager_id">
                          <option></option>
                          <option v-for="user in users" :value="user.id">
                            {{ user.first_name }} {{ user.last_name }} - {{ user.position }}
                          </option>
                        </select>
                      </td>
                    </tr>

                    <tr>
                      <th>Kierownik działu dokumentacji<br> technicznej i certyfikacji maszyn</th>
                      <td>
                        <select class="form-select form-select-sm border-1"
                                v-model="orgProject.documentation_manager_id">
                          <option></option>
                          <option v-for="user in users" :value="user.id">
                            {{ user.first_name }} {{ user.last_name }} - {{ user.position }}
                          </option>
                        </select>
                      </td>
                    </tr>
                  </table>

                  <button type="submit" class="float-end btn btn-primary" :disabled="isLoading"
                          v-if="hasPermission('canEditAdvancedProject')">
                    {{ isLoading ? 'Wysyłanie...' : 'Zaktualizuj' }}
                  </button>
                </form>

                <div class="mt-5 pt-5">
                  <hr class="mb-5">
                  <AdditionalArrangements :project="project" :users="users" :usersCanChange="usersCanChange"
                                          :additionalArrangements="additionalArrangements"></AdditionalArrangements>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </AppLayout>
</template>


<script>
import AppLayout from "@/Layouts/App.vue";
import projectMenu from "@/Pages/Projects/parts/projectMenu.vue";
import route from "../../../../vendor/tightenco/ziggy/src/js/index.js";
import ProjectsHistory from "@/Pages/Projects/Histories/ProjectsHistory.vue";
import AdditionalArrangements from '@/Pages/Projects/Components/AdditionalArrangements.vue';
import {usePermission} from "@/composables/resources/js/composables/permissions.js";

const {hasPermission} = usePermission();
export default {
  components: {AppLayout, projectMenu, ProjectsHistory, AdditionalArrangements, hasPermission},

  methods: {
    route,
    isRouteActive(routeName) {
      return route().current() === routeName;
    },
  },
}
</script>
