<template>
  <button type="button" class="mt-3 btn btn-sm btn-outline-primary" data-bs-toggle="modal"
          data-bs-target="#modelCreateEditCustomer">
    Dodaj klienta/osobę kontaktową
  </button>

  <!-- Modal -->
  <div class="modal fade" id="modelCreateEditCustomer" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
       aria-hidden="true">
    <div class="modal-dialog modal-xxl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Dodaj/edytuj klienta</h5>
          <div class="position-absolute" style="right:25px;">
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"
                    style="background-color: transparent">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div class="modal-body" v-if="customers">

          <Vueform>
            <SelectElement ref="selectCustomerRef" name="customer" placeholder="Wybierz klienta do edycji"
                           :items="customers"
                           :search="true"
                           label-prop="name"
                           value-prop="id"
                           @change="getCustomerToEdit"
                           :object="true"/>
          </Vueform>

          <div class="mt-5" v-if="!selectedCustomer">
            <div class="spinner-grow text-danger me-2 align-self-center loader-sm "></div>
          </div>
          <div class="mt-5" v-else>
            <CustomerEditForm :customer="selectedCustomer"/>
          </div>
        </div>
        <div class="modal-footer justify-content-between">
          <a class="btn btn-outline-primary" :href="route('customers.create')" target="_blank"><i
              class="bi bi-box-arrow-up-right me-2"></i>Dodaj klienta</a>
          <div>
            <button type="button" class="btn btn-outline-success" @click="refreshCustomerList" data-bs-dismiss="modal">
              Gotowe. Odśwież listę klientów
            </button>
          </div>
          <div>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Zamknij</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomersShow from '@/Pages/Customers/EditForm.vue';
import CustomerEditForm from '@/Pages/Customers/EditForm.vue';

export default {
  components: {CustomersShow, CustomerEditForm},
  emits: ['refreshCustomerList'],
  props: {
    customers: Object,
    chosenCustomer: Object
  },

  watch: {
    chosenCustomer(newVal) {
      if (newVal) {
        this.getCustomerToEdit(newVal);
      }
    }
  },

  data() {
    return {
      selectedCustomer: null,
    }
  },

  mounted() {
    this.getCustomerToEdit(this.chosenCustomer);
  },

  methods: {
    getCustomerToEdit(elem) {
      if(!elem || !elem.id)
        return;

      this.selectedCustomer = null;
      axios.get(route('customer.get-customer', {customer: elem.id})).then(response => {
        this.selectedCustomer = response.data;
      }).catch(error => {
        console.log(error);
      });
    },

    refreshCustomerList() {
      this.$emit('refreshCustomerList', true);
    }
  }

}
</script>
