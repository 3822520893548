<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="addRequiredProjectItemsModal" ref="modalEle" tabindex="-1" role="dialog"
         aria-labelledby="modelTitleId"
         aria-hidden="true">
      <div class="modal-dialog modal-full-width" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Dodaj pozycje do zapotrzebowania projektowego</h5>
            <Link :href="route('articles.index')"></Link>
            <button type="button" class="close ms-auto" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="container-fluid">
              <template v-if="!project || !team">
                <h4 class="text-warning">Najpierw wybierz projekt oraz zespół</h4>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                  <i class="bi bi-x-square me-2"></i>Zamknij
                </button>
              </template>

              <template v-else>

                <div v-if="selectedArticles.length > 0" class="table-responsive"
                     style="border-bottom:1px solid #ddd; padding-bottom:20px">
                  <h5>Lista wybranych artykułów</h5>
                  <table class="table table-striped">
                    <thead>
                    <tr>
                      <th></th>
                      <th>#</th>
                      <th>Nr Katalogowy</th>
                      <th>Model</th>
                      <th>J.m.</th>
                      <th>Nazwa/Opis</th>
                      <th>Ilość/Stany magazynowe</th>
                      <th>Ilość wymagana</th>
                      <th>Dostawcy</th>
                      <th>Uwagi</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="article in selectedArticles">
                      <td>
                        <button @click="removeFromSelectedArticles(article.id)" class="btn btn-outline-danger btn-xs">
                          Usuń
                        </button>
                      </td>
                      <td>{{ article.id }}</td>
                      <td>{{ article.catalog_number }}</td>
                      <td>{{ article.model }}</td>
                      <td>{{ article.article_unit.name }}</td>
                      <td>{{ article.description }}</td>
                      <td>{{ article.available_items?.quantity ?? 0 }}</td>
                      <td class="cell-editable">
                        <input type="number" step="0.01" min="1" v-model="article.quantity_required"
                               class="form-control"/>
                      </td>
                      <td class="cell-editable">
                        <Vueform>
                          <TagsElement id="suppliers_short_names"
                                       name="suppliers_short_names"
                                       autocomplete="off"
                                       :search="true"
                                       :native="false"
                                       :items="suppliers"
                                       labelProp="short_name"
                                       valueProp="short_name"
                                       :default="article.suppliers_short_names_array"
                                       @change="changeSuppliersHandler(article, $event)">
                            <template v-slot:tag="{ option, remove }">
                                          <span
                                              :class="{
                                              'original-tag': isOriginalSupplier(option, article),
                                              'new-tag': !isOriginalSupplier(option, article)
                                            }"
                                              class="tag-item"
                                          >
                                            {{ option.short_name }}
                                            <button type="button" @click="remove">x</button>
                                          </span>
                            </template>
                          </TagsElement>
                        </Vueform>
                      </td>
                      <td class="cell-editable">
                        <input type="text" v-model="article.comments" class="form-control"/>
                      </td>
                    </tr>
                    </tbody>
                  </table>

                  <button type="button" class="btn btn-primary" @click="saveSelectedPositions" :disabled="isSubmiting">
                    Dodaj wybrane pozycje
                  </button>
                </div>

                <h5 class="pt-4 pb-2">Lista dostępnych artykułów</h5>
                <ejs-grid ref="addRequiredProjectItemsGrid" id="addRequiredProjectItemsGrid" height="500px"
                          :dataSource="articlesDataManager"
                          :allowTextWrap='false'
                          :allowExcelExport='false'
                          :allowPaging="true"
                          :allowSorting='true'
                          :allowFiltering='true'
                          :allowReordering='true'
                          :allowResizing='true'
                          :showColumnChooser='true'
                          :allowSelection='true'
                          :enablePersistence='false'
                          :pageSettings="pageSettings"
                          :editSettings="editSettings"
                          :filterSettings="filterOptions"
                          :toolbar="toolbar"
                          :selectionSettings="selectionOptions"
                          :toolbarClick="clickToolbarHandler">
                  <e-columns>
                    <e-column headerText="Dodaj" width="80" :template="'addTemplate'" textAlign="Center"></e-column>
                    <e-column field="id" headerText="ID" width="100" :isPrimaryKey="true" :visible="false"
                              :allowEditing="false"/>
                    <e-column field="photos" headerText="Zdjęcia" width="150" :template="'imageTemplate'"
                              :allowEditing="false" :allowFiltering="false" :allowSorting="false"></e-column>
                    <e-column field="catalog_number" headerText="Nr katalogowy" width="150" :filter="filterDefault"
                              :allowEditing="false"/>
                    <e-column field="model" headerText="Model" width="150" :filter="filterDefault"
                              :allowEditing="false"/>
                    <e-column field="article_unit.id" headerText="J.m." width="150" editType="dropdownedit"
                              :dataSource="units" foreignKeyValue='name' foreignKeyField='id'
                              :filter='filterUnits' :allowEditing="false"/>
                    <e-column field="description" headerText="Nazwa/Opis" :defaultValue="null" width="200"
                              :filter="filterDefault" :allowEditing="false"/>
                    <e-column field="link" headerText="Link" :defaultValue="null" :filter="filterDefault"
                              :template="'linkTemplate'" :allowEditing="false"/>
                    <e-column field="available_items.quantity" headerText="Ilość/Stany magazynowe" width="150"
                              format="N2" :filter="filterNumeric" type="string" :allowEditing="false" />
                    <e-column field="suppliers_short_names" headerText="Dostawcy" editType="string"
                              :allowEditing="false" :dataSource="suppliers"
                              :filter="filterSuppliers"/>
                  </e-columns>

                  <template v-slot:addTemplate="{ data }">
                    <template v-if="!isArticleSelected(data.id)">
                      <button @click="addToNewItems(data)"
                              class="btn btn-xs btn-outline-primary">Dodaj
                      </button>
                    </template>
                    <template v-else><span class="text-success">Wybrano</span></template>
                  </template>

                  <template v-slot:linkTemplate="{data}">
                    <a class="link link-primary" target="_blank" rel="noopener noreferrer" :href=data.link>{{
                        data.link
                      }}</a>
                  </template>

                  <template v-slot:imageTemplate="{ data }">
                    <div v-if="data.photos && data.photos.length > 0">
                      <img :src="`/${data.photos[0].photo_path}`" alt="Zdjęcie"
                           style="max-height: 100px; max-width: 150px"/>
                    </div>
                  </template>

                </ejs-grid>
              </template>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              <i class="bi bi-x-square me-2"></i>Zamknij
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import {Modal} from "bootstrap";
import {
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  ContextMenu,
  Edit,
  ExcelExport,
  Filter, ForeignKey,
  GridComponent,
  Page,
  Reorder,
  Resize,
  Sort,
  Toolbar,
  RowDD,
  VirtualScroll
} from '@syncfusion/ej2-vue-grids';
import {createElement} from "@syncfusion/ej2-base";
import {DataManager, WebMethodAdaptor} from '@syncfusion/ej2-data';
import {DropDownList} from "@syncfusion/ej2-dropdowns";
import {router, usePage} from "@inertiajs/vue3";
import {MultiSelect} from "@syncfusion/ej2-vue-dropdowns";
import {ref} from "vue";

let dropInstanceFilterUnits, delivererElem = ref(null), multiSelectObj = ref(null);

export default {
  components: {
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    'e-column': ColumnDirective
  },

  provide: {
    grid: [Page, Edit, Toolbar, ContextMenu, Sort, Filter, Reorder, Resize, ColumnChooser, ExcelExport, ForeignKey, RowDD, VirtualScroll]
  },

  props: {
    project: Number | String,
    team: Boolean | String,
    units: Object,
    suppliers: Object,
  },

  data() {

    return {
      articlesDataManager: [],
      modalObj: null,
      selectedArticles: [],
      isSubmiting: false,
      pageSettings: {pageSize: 50, pageCount: 5},
      filterOptions: {type: "Menu", operator: 'contains'},
      filterDefault: {operator: 'contains', params: {minLength: 2}},
      filterNumeric: {operator: 'equal'},
      toolbar: this.getToolbar(),
      editSettings: this.getEditSettings(),
      selectionOptions: {checkboxOnly: true, persistSelection: true},
      filterUnits: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.units,
              fields: {text: 'name', value: 'id'},
              placeholder: 'Szukaj jednostki',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterSuppliers: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.suppliers,
              fields: {text: 'short_name', value: 'short_name'},
              placeholder: 'Szukaj dostawców',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      suppliersParams: {
        create: () => {
          this.delivererElem = document.createElement("input");
          return this.delivererElem;
        },
        read: () => {
          return this.multiSelectObj ? this.multiSelectObj.value.join(",") : "";
        },
        destroy: () => {
          if (this.multiSelectObj) {
            this.multiSelectObj.destroy();
            this.multiSelectObj = null;
          }
        },
        write: (args) => {
          const values = args.rowData.suppliers_short_names || ""; // Zabezpieczenie przed null
          const selectedValues = values ? values.split(",") : [];

          this.multiSelectObj = new MultiSelect({
            value: selectedValues,
            dataSource: this.suppliers,
            fields: {text: "short_name", value: "short_name"},
            placeholder: "Wybierz dostawców",
            mode: "Box",
            allowFiltering: true,
            popupHeight: "200px",
            closePopupOnSelect: false,
          });

          this.multiSelectObj.appendTo(this.delivererElem);
        },
      },
    }
  },

  mounted() {
    this.modalObj = new Modal(this.$refs.modalEle);
  },

  beforeMount() {
    this.getArticles();
  },

  methods: {

    getToolbar() {
      return [
        {text: "Resetuj filtry", prefixIcon: 'e-reset', id: 'reset_filters'},
        {text: "Lista artykułów", prefixIcon: 'e-table-of-content', id: 'articles_list'},
        {text: "Search", prefixIcon: 'e-search', id: "search", align: 'center'},
        {text: "Odśwież", prefixIcon: 'e-refresh', id: "refresh", align: 'right'},
      ]
    },

    getEditSettings() {
      return {
        allowEditing: true,
        allowAdding: false,
        allowDeleting: false,
        showConfirmDialog: false,
        showDeleteConfirmDialog: false,
        mode: 'Batch',
        newRowPosition: 'Top',
      };
    },

    addToNewItems(rowData) {
      if (!this.selectedArticles.find(item => {
        return item.id === rowData.id
      })) {
        this.selectedArticles.push({
          ...rowData,
          original_suppliers_short_names_array: this.parseSuppliersString(rowData.suppliers_short_names),
          suppliers_short_names_array: this.parseSuppliersString(rowData.suppliers_short_names)
        });
      }
    },

    removeFromSelectedArticles(articleId) {
      this.selectedArticles = this.selectedArticles.filter(item => item.id !== articleId);
    },

    isArticleSelected(articleId) {
      return this.selectedArticles.some(item => item.id === articleId);
    },

    parseSuppliersString(suppliersString) {
      // Zamiana stringa "a, b, c" na ["a", "b", "c"]
      return suppliersString ? suppliersString.split(',').map(s => s.trim()) : [];
    },

    clickToolbarHandler: async function (args) {
      /**
       * Akcja przy kliknięciu resetowania filtrów
       */
      if (args.item.id === 'reset_filters') {
        this.$refs.addRequiredProjectItemsGrid.clearFiltering();
      }

      /**
       * Akcja przy kliknięciu listy artykułów
       */
      if (args.item.id === 'articles_list') {
        window.open(route('articles.index'), '_blank');
      }

      /**
       * Akcja przy kliknięciu odświeżania tabeli
       */
      if (args.item.id === 'refresh') {
        this.$refs.addRequiredProjectItemsGrid.ej2Instances.refresh();
      }
    },

    async saveSelectedPositions() {
      this.isSubmiting = true;
      // sprawdź, czy któraś z zaznaczonych pozycji nie ma wpisanej lub jest 0 ilości wymaganej
      const hasZeroQuantity = Object.values(this.selectedArticles).some(item => !item.quantity_required);

      // info czy na pewno chcesz dodać bez wprowadzonej ilości wymaganej
      if (hasZeroQuantity) {
        const result = await Toast.fire({
          toast: false,
          position: 'center',
          icon: 'question',
          title: 'Brak ilości wymaganej',
          html: "Niektóre pozycje mają brakującą lub zerową ilość wymaganą. Dodać mimo to?",
          showClass: {popup: 'animate__animated animate__fadeInDown'},
          hideClass: {popup: 'animate__animated animate__fadeOutUp'},
          timer: false,
          showConfirmButton: true,
          confirmButtonText: 'Tak',
          confirmButtonColor: 'success',
          showCancelButton: true,
          cancelButtonText: 'Nie',
        });

        if (!result.isConfirmed) {
          this.isSubmiting = false;
          return;
        }
      }

      this.saveArticles();
    },


    async getArticles() {
      this.articlesDataManager = new DataManager({
        url: route('articles.get', {
          project: this.selectedProjectId,
          team: this.selectedTeamName,
          without_deleted: true
        }),
        adaptor: new WebMethodAdaptor(),
        batchUrl: route('requiredProjectItems.saveChanges'),
        updateUrl: route('requiredProjectItems.updateChanges'),
        crossDomain: true,
        headers: [
          {'X-CSRF-TOKEN': usePage().props.csrf_token}
        ],
      });
    },


    saveArticles() {
      if (!this.project) {
        this.$emit("added-error", 'Nie został wybrany projekt.');
        return;
      }

      if (!this.team) {
        this.$emit("added-error", 'Nie został wybrany zespół.');
        return;
      }

      if (Object.keys(this.selectedArticles).length === 0) {
        this.$emit("added-error", 'Nie został wybrany żaden artykuł');
        return;
      }

      // ogranicz wysyłanie danych tylko do tych potrzebnych.
      const payload = this.selectedArticles.map(article => ({
        id: article.id,
        quantity_required: article.quantity_required,
        comments: article.comments,
        suppliers_short_names: article.suppliers_short_names
      }));

      this.$inertia.post(route("requiredProjectItems.addArticleToList", {
        project: this.project,
        team: this.team,
        articles: payload
      }), {}, {
        preserveState: true,
        onSuccess: () => {
          this.$emit("added-article");
          this.$emit("added-success", "Artykuły zostały poprawnie dodane do zapotrzebowania.");
          this.selectedArticles = [];
          this.$refs.addRequiredProjectItemsGrid.clearSelection();
          this.hide();
        },
        onError: (resp) => {
          console.error("Błąd zapisu:", resp);
          this.$emit("added-error", Object.keys(resp)[0] ?? resp);
        },
        onFinish: () => {
          this.isSubmiting = false;
        }
      });
    },

    changeSuppliersHandler(article, selectedItems) {
      if (!selectedItems || selectedItems.length === 0) {
        article.suppliers_short_names_array = [];
        article.suppliers_short_names = "";
      } else {
        // Aktualizujemy array
        article.suppliers_short_names_array = selectedItems;

        // Zamieniamy array na string z przecinkami
        article.suppliers_short_names = selectedItems.join(', ');
      }
    },

    isOriginalSupplier(option, article) {
      return article.original_suppliers_short_names_array.includes(option.short_name);
    },


    show() {
      if (this.modalObj) {
        this.modalObj.show();
      }
    }
    ,
    hide() {
      if (this.modalObj) {
        this.modalObj.hide();
      }
    }
    ,
  }
}

</script>

<style scoped>
.tag-item {
  display: inline-flex;
  align-items: center;
  padding: 0.15em 0.5em;
  margin: 0.15em;
  border-radius: 0.25em;
}

.original-tag {
  background-color: #e0e0e0;
  color: #000;
}

.new-tag {
  background-color: orange;
  color: #fff;
}

.tag-item button {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  margin-left: 0.5em;
}
</style>