<template>
  <div class="mt-2" v-if="quoteRequestId">
    <ejs-grid ref="quoteRequestItemsRef" id="quoteRequestItemsId" height="300px"
              :dataSource="quoteRequestItems"
              :allowTextWrap='false'
              :allowExcelExport='true'
              :allowPaging="true"
              :allowSorting='true'
              :allowFiltering='true'
              :allowReordering='true'
              :allowResizing='true'
              :showColumnChooser='true'
              :allowSelection='true'
              :enablePersistence='true'
              :pageSettings="pageSettings"
              :editSettings="editSettings"
              :filterSettings="filterOptions"
              :toolbar="toolbar"
              :contextMenuItems="contextMenuItems"
              :selectionSettings="selectionOptions"
              :toolbarClick="clickToolbarHandler"
              :contextMenuClick="clickContextMenuHandler"
              :actionBegin="actionBeginHandler"
              :actionComplete="actionCompleteHandler"
              :rowDataBound="rowDataBound"
              :rowSelecting="rowSelecting"
              :keyPressed="onKeyDown">
      <e-columns>
        <e-column type="checkbox" width="100"/>
        <e-column field="id" headerText="ID" :allowEditing="false" isPrimaryKey="true"
                  :visible="false" :filter="filterDefault" width="180"/>
        <e-column field="quote_request.request_number" headerText="Zapytanie ofertowe" :allowEditing="false"
                  :filter="filterDefault"/>
        <e-column field="project.number" headerText="Projekt" :allowEditing="false" :visible="true"/>
        <e-column field="quote_request.team_name" headerText="Zespół" :allowEditing="false" :visible="true"/>
        <e-column field="article.id" headerText="ID artykułu" :allowEditing="false" :visible="false"/>
        <e-column field="article.catalog_number" headerText="Nr artykułu" :allowEditing="false"
                  :filter="filterDefault"/>
        <e-column field="article.model" headerText="Model artykułu" :allowEditing="false"
                  :filter="filterDefault"/>
        <e-column field="supplier.id" headerText="Wybrany dostawca" :allowEditing="true"
                  :filter="filterSuppliers" editType="dropdownedit" foreignKeyValue='short_name'
                  foreignKeyField='id' :dataSource="suppliers"/>
        <e-column field="sent_to_emails" headerText="Wysłano na maile" :allowEditing="false" type="string"
                  :filter="filterDefault"/>
        <e-column field="quote_date" headerText="Oferta od dostawcy" :allowEditing="true"
                  :filter="filterDefault" format="dd.MM.yyyy" type="date" editType="datepickeredit"/>
        <e-column field="delivery_date" headerText="Zobligowana data dostawy" :allowEditing="true"
                  :filter="filterDefault" format="dd.MM.yyyy" type="date" editType="datepickeredit"/>
        <e-column field="delivery_date_deadline" headerText="Ostateczny termin dostawy" :allowEditing="true"
                  :filter="filterDefault" format="dd.MM.yyyy" type="date" editType="datepickeredit"/>
        <e-column field="quantity_required" headerText="Wymagana ilość" :allowEditing="true" format="N2"
                  :filter="filterNumeric" editType="numericedit" min="0" type="number"
                  :edit="editNumbersPlusParams"/>
        <e-column field="quoted_price" headerText="Cena netto" :allowEditing="true" type="number"
                  :filter="filterNumeric" editType="numericedit" format="N2"/>
        <e-column field="vat" headerText="VAT" :allowEditing="true" type="number"
                  :filter="filterNumeric" editType="numericedit" format="N0"/>
        <e-column field="rabate" headerText="Rabat" :allowEditing="true" type="number"
                  :filter="filterNumeric" editType="numericedit" format="N2"/>
        <e-column field="currency" headerText="Waluta" :allowEditing="true" :edit="currenciesParams"
                  :filter="filterDefault" editType="dropdownedit" type="string"/>
        <e-column field="status.id" headerText="Status" :allowEditing="true" editType="dropdownedit"
                  foreignKeyValue='name' foreignKeyField='id' :dataSource="quoteRequestItemsStatus"
                  :edit="quoteRequestItemStatusesParams" :filter="filterStatuses"/>
        <e-column field="comment" headerText="Komentarz do pozycji" :allowEditing="true"
                  :allowFiltering="false" :allowSorting="false"
                  :filter="filterDefault"/>
        <e-column field="created_at" headerText="Data utworzenia" :allowEditing="false" type="date"
                  format="dd.MM.yyyy" :filter="filterDefault"/>
        <e-column field="created_by_name" headerText="Utworzone przez" :allowEditing="false"
                  :filter="filterDefault"/>

      </e-columns>

      <e-aggregates>
        <e-aggregate>
          <e-columns>
            <e-column field="price_netto" type="Sum" format="N2" footerTemplate="${Sum}"/>
            <e-column field="price_brutto" type="Sum" format="N2" footerTemplate="${Sum}"/>
            <e-column field="value_netto" type="Sum" format="N2" footerTemplate="${Sum}"/>
            <e-column field="value_brutto" type="Sum" format="N2" footerTemplate="${Sum}"/>
          </e-columns>
        </e-aggregate>
      </e-aggregates>
    </ejs-grid>

    <AddOrderModal ref="addOrderModalRef" :project="selectedProjectId" :team="selectedTeamName"
                   :selectedItems="selectedRecordsToCreateOrder" @added-success="successMsg" @added-error="errorMsg"/>


  </div>
</template>

<script>
import {
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  ContextMenu,
  Edit,
  ExcelExport,
  Filter, ForeignKey,
  GridComponent,
  AggregatesDirective,
  AggregateDirective,
  Page,
  Reorder,
  Aggregate,
  Resize,
  Sort,
  Toolbar,
} from '@syncfusion/ej2-vue-grids';
import {createElement} from "@syncfusion/ej2-base";
import {MultiSelect} from "@syncfusion/ej2-vue-dropdowns";
import {ComboBox, DropDownList} from "@syncfusion/ej2-dropdowns";
import {Predicate, Query} from "@syncfusion/ej2-data";
import AddOrderModal from "./AddOrderModal.vue";
import AddQuoteRequestItemsModal from "./AddQuoteRequestItemsModal.vue";

let statusObj, statusElem, dropInstanceFilterUnits, statusFilter;
export default {

  components: {
    AddQuoteRequestItemsModal,
    AddOrderModal,
    MultiSelect,
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    'e-column': ColumnDirective,
    "e-aggregates": AggregatesDirective,
    "e-aggregate": AggregateDirective
  },

  provide: {
    grid: [Page, Edit, Toolbar, ContextMenu, Sort, Filter, Reorder, Resize, ColumnChooser, ExcelExport, ForeignKey, Aggregate]
  },

  props: {
    quoteRequestId: Number,
    quoteRequestItemsStatus: Object,
    selectedTeamName: String,
    selectedProjectId: Number | String,
    suppliers: Object,
    units: Object
  },

  watch: {
    quoteRequestId: {
      handler: async function (newVal) {
        await this.getItems(newVal);
      }
    }
  },


  data() {

    return {
      quoteRequestItems: [],
      selectedRecordsToMove: null,
      selectedRecordsToCreateOrder: null,
      pageSettings: {pageSize: 50, pageCount: 5},
      filterOptions: {type: "Menu", operator: 'contains'},
      filterDefault: {operator: 'contains', params: {minLength: 2}},
      filterNumeric: {operator: 'equal'},
      isRestoring: false,
      toolbar: this.getToolbar(),
      editSettings: this.getEditSettings(),
      selectionOptions: {cellSelectionMode: 'Box'},
      contextMenuItems: this.getContextMenu(),
      editNumbersPlusParams: {
        params: {
          min: 0,
          validateDecimalOnType: true
        }
      },

      currenciesParams: {
        create: () => {
          return document.createElement('input');
        },
        read: () => {
          return this.comboBoxObj.value;
        },
        destroy: () => {
          this.comboBoxObj.destroy();
        },
        write: (args) => {
          this.comboBoxObj = new ComboBox({
            dataSource: [
              {text: 'ZŁ', value: 'ZŁ'},
              {text: 'EUR', value: 'EUR'},
              {text: 'USD', value: 'USD'},
            ],
            fields: {text: "text", value: "value"},
          });
          this.comboBoxObj.appendTo(args.element);
        }
      },

      filterUnits: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.units,
              fields: {text: 'name', value: 'id'},
              placeholder: 'Szukaj jednostki',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterCategories: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.categories,
              fields: {text: 'name', value: 'id'},
              placeholder: 'Szukaj kategorii',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterProducers: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.producers,
              fields: {text: 'short_name', value: 'id'},
              placeholder: 'Szukaj producentów',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterSuppliers: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.suppliers,
              fields: {text: 'short_name', value: 'short_name'},
              placeholder: 'Szukaj dostawców',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterWarehouses: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.warehouses,
              fields: {text: 'short_name', value: 'id'},
              placeholder: 'Szukaj magazynu',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterStatuses: {
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            statusFilter = new DropDownList({
              dataSource: this.quoteRequestItemsStatus,
              fields: {text: 'name', value: 'id'},
              placeholder: 'Szukaj Statusu',
              popupHeight: '200px',
              allowFiltering: true
            });
            statusFilter.appendTo(flValInput);
          },
          write: (args) => {
            statusFilter.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, statusFilter.text);
          }
        }
      },

      quoteRequestItemStatusesParams: {
        create: () => {
          statusElem = createElement('input');
          return statusElem;
        },
        read: () => {
          return statusObj.value;
        },
        destroy: () => {
          statusObj.destroy();
        },
        write: (args) => {
          statusObj = new DropDownList({
            dataSource: this.quoteRequestItemsStatus,
            fields: {value: 'id', text: 'name'},
            enabled: true,
            placeholder: 'Wybierz status',
            floatLabelType: 'Never',
            value: args.rowData.status?.id,
            change: async (event) => {
              statusObj.value = await this.onChangeStatus(event, args.rowData);
            }
          });
          statusObj.appendTo(statusElem);
        }
      },

      inventoryStatusesParams: {
        create: () => {
          statusElem = document.createElement('input');
          return statusElem;
        },
        read: () => {
          return statusObj.text;
        },
        destroy: () => {
          statusObj.destroy();
        },
        write: () => {
          statusObj = new DropDownList({
            dataSource: this.inventory_statuses,
            fields: {value: 'name', text: 'name'},
            enabled: true,
            placeholder: 'Wybierz status',
            floatLabelType: 'Never'
          });
          statusObj.appendTo(statusElem);
        }
      },
    }
  },

  methods: {
    async getItems(newVal) {
      try {
        const response = await axios.post(route('quote-request-items-list.get', {quoteRequestId: newVal}));
        this.quoteRequestItems = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    getEditSettings() {
      return {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        showConfirmDialog: false,
        showDeleteConfirmDialog: false,
        mode: 'Batch',
        newRowPosition: 'Top',
      };
    },

    getToolbar() {
      const toolbar = [
        // {text: "Dodaj", prefixIcon: 'e-add', id: 'add_btn'},
        "Update",
        "Cancel",
        {text: "Resetuj filtry", prefixIcon: 'e-reset', id: 'reset_filters'},
        {text: "Search", prefixIcon: 'e-search', id: "search", align: 'center'},
        {text: "Przywróć domyślny", prefixIcon: 'e-undo', id: "restore", align: 'right'},
        {text: "Export do Excela", prefixIcon: 'e-excelexport', id: "excelexport", align: 'right'},
        "ColumnChooser",
      ];

      return toolbar;
    },

    clickToolbarHandler: async function (args) {
      /**
       * Niestandardowa akcja przy dodawaniu pozycji do zlecenia
       */
      if (args.item.id === 'add_btn') {
        this.$refs.addQuoteRequestItemsModal.show();
      }


      /**
       * Niestandardowa akcja przy aktualizacji
       */
      if (args.item.text === 'Aktualizuj') {
        const grid = this.$refs.quoteRequestItemsRef;

        // odczytanie zmian (Syncfusion trzyma to w tej właściwości)
        const batchChanges = grid.getBatchChanges(); // { added: [], changed: [], deleted: [] }

        // Zamiana kluczy na oczekiwane przez backend
        const payload = {
          added: batchChanges.addedRecords || [],
          changed: batchChanges.changedRecords || [],
          deleted: batchChanges.deletedRecords || []
        };


        // jeśli nie ma żadnych zmian, nie wysyłaj żądania
        if (
            payload.added.length === 0 &&
            payload.changed.length === 0 &&
            payload.deleted.length === 0
        ) {
          this.successMsg('Brak zmian do zapisania.');
          return;
        }

        try {
          const response = await axios.post(route('quoteRequests.saveChanges'), payload);

          this.successMsg(response.data.message || 'Poprawnie zapisano zmiany.');

          // Odśwież dane w gridzie
          this.getItems(this.quoteRequestId);

        } catch (error) {
          console.error(error);
          const errors = error.response?.data;
          if (Array.isArray(errors)) {
            errors.forEach(err => {
              this.errorMsg(err)
            });
          } else {
            this.errorMsg('Wystąpił błąd zapisu:' + errors)
          }
        }
      }

      /**
       * Niestandardowa akcja przy dodawaniu
       */
      if (args.item.text === 'Dodaj') {
        // args.cancel = true;

        // if (this.selectedProjectId && this.selectedTeamName)
        //   await this.$nextTick(() => {
        //     this.$refs.addRequiredProjectItemsModalRef.show();
        //   });
      }

      /**
       * Akcja przy usuwaniu artykułu.
       */
      if (args.item.text === 'Usuń') {
        const selectedRecords = this.$refs.quoteRequestItemsRef.getSelectedRecords();
        if (selectedRecords.length > 0) {
          selectedRecords.forEach((record) => {
            this.$refs.quoteRequestItemsRef.deleteRecord("id", record);
          });
        }
      }

      /**
       * Akcja przy kliknięciu resetowania filtrów
       */
      if (args.item.id === 'reset_filters') {
        this.$refs.quoteRequestItemsRef.clearFiltering();
      }

      if (args.item.id === 'restore') {
        let grid = this.$refs.quoteRequestItemsRef;
        let gridName = 'grid' + grid.$el.id;
        grid.ej2Instances.enablePersistence = false;
        window.localStorage.setItem(gridName, "");
        grid.ej2Instances.destroy();
        location.reload();
      }

      if (args.item.id === 'excelexport') {
        this.$refs.quoteRequestItemsRef.excelExport({
          fileName: `Zamówienia.xlsx`
        });
      }
    },

    getContextMenu() {
      return [
        {text: 'Utwórz zamówienie', target: '.e-content', id: 'create_order'},
      ];
    },

    clickContextMenuHandler(args) {
      if (args.item.id === 'create_order') {
        this.selectedRecordsToCreateOrder = this.$refs.quoteRequestItemsRef.getSelectedRecords();
        this.$nextTick(() => {
          this.$refs.addOrderModalRef.show();
        });
      }
    },

    rowSelecting(args) {
      if (args.data.deleted_at) {
        args.cancel = true; // blokuj zaznaczenie
        Toast.fire({
          icon: 'info',
          title: 'Nie można zaznaczyć usuniętego rekordu.'
        });
      }
    },

    rowDataBound(args) {
      if (args.data.deleted_at) {
        args.row.classList.add('text-muted', 'deleted-row');
      }
    },

    actionBeginHandler(args) {
      //
      // WYSZUKIWARKA- wyszukiwanie wielu wartości z separatorem (przecinek
      //
      if (args.requestType === 'searching') {
        const keys = args.searchString.split(',');
        let flag = true;
        let predicate;

        if (this.$refs.quoteRequestItemsRef.ej2Instances.searchSettings.key !== '') {
          this.values = args.searchString;
          keys.forEach((key) => {
            this.$refs.quoteRequestItemsRef.ej2Instances.getColumns().forEach((col) => {
              if (flag) {
                predicate = new Predicate(col.field, 'contains', key, true);
                flag = false;
              } else {
                let predic = new Predicate(col.field, 'contains', key, true);
                predicate = predicate.or(predic);
              }
            });
          });

          this.$refs.quoteRequestItemsRef.ej2Instances.query = new Query().where(predicate);
          this.$refs.quoteRequestItemsRef.ej2Instances.searchSettings.key = '';
          this.refresh = true;
          this.valueAssign = true;
          this.removeQuery = true;
          this.$refs.quoteRequestItemsRef.ej2Instances.refresh();
        } else {
          this.$refs.quoteRequestItemsRef.ej2Instances.searchSettings.key = '';
          this.refresh = true;
          this.valueAssign = true;
          this.removeQuery = true;
          this.$refs.quoteRequestItemsRef.ej2Instances.refresh();
        }
      }
    },

    actionCompleteHandler(args) {
      //
      // WYSZUKIWARKA - wyszukiwanie wielu wartości z separatorem (przecinek
      //
      if (args.requestType === "refresh" && this.valueAssign) {
        document.getElementById(
            this.$refs.quoteRequestItemsRef.ej2Instances.element.id + "_searchbar"
        ).value = this.values;
        this.valueAssign = false;
      } else if (
          document.getElementById(
              this.$refs.quoteRequestItemsRef.ej2Instances.element.id + "_searchbar"
          ).value === "" &&
          args.requestType === "refresh" &&
          this.removeQuery
      ) {
        this.$refs.quoteRequestItemsRef.ej2Instances.query = new Query();
        this.removeQuery = false;
        this.$refs.quoteRequestItemsRef.ej2Instances.refresh();
      }

      if (args.requestType === "batchsave") {  // Tylko dla operacji batchSave (zapis)
        if (args.changes && args.changes.changedRecords) {
          // Sprawdzamy, czy `args.batchChanges` istnieje i czy są zmienione rekordy
          this.successMsg("Dane zostały pomyślnie zapisane!");

        } else if (args.error) {
          // Jeśli wystąpił błąd, wywołaj `errorMsg` z wiadomością błędu
          this.errorMsg("Wystąpił błąd podczas zapisu danych: " + args.error);
        }
      }
    },

    onKeyDown: function (args) {
      let keyCode = args.which || args.keyCode;
      let isCtrlKey = (args.ctrlKey || args.metaKey) ? true : ((keyCode === 17));

      // code 83 to 'S'
      if (isCtrlKey && keyCode === 83) {
        args.preventDefault();

        this.updateGrid();
      }
    },

    updateGrid() {
      // aktualizuj
      let toolbar = document.getElementsByClassName('e-toolbar-left')[1];
      let buttons = toolbar.querySelectorAll('.e-toolbar-item');
      let updateButton = buttons[1].querySelector('button');


      if (updateButton) {
        updateButton.click();
      }
    },

    successMsg(resp) {
      Toast.fire({
        position: 'top-end',
        toast: true,
        icon: 'success',
        title: 'Sukces!',
        html: resp,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true
      });
    },

    errorMsg(resp) {
      Toast.fire({
        position: 'top-end',
        toast: true,
        icon: 'warning',
        title: 'Błąd!',
        html: resp,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true
      });
    },
  }
}
</script>


<style>
#quoteRequestItemsId .e-headercell, #quoteRequestItemsId .e-rowcell {
  font-size: 12px;
}
</style>