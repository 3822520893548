<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="sendOrdersModal" ref="modalEle" tabindex="-1" role="dialog"
         aria-labelledby="modelTitleId"
         aria-hidden="true">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Wyślij zamówienia</h5>
            <button type="button" class="close ms-auto" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" v-if="selectedItems">

            <div class="mb-3">Wiadomość e-mail będzie zawierała wszystkie pozycje z danego zamówienia, niezależnie od
              tego, czy wszystkie zostały zaznaczone. <br>
              Oznacza to, że zostaną wysłane maile ze wszystkimi pozycjami z poniższego zamówienia.
            </div>

            <div class="table-responsive container-fluid">

              <table class="table table-striped">
                <thead>
                <tr>
                  <th>Nr Zamówienia</th>
                  <th>Projekt</th>
                  <th>Zespół</th>
                  <th>Wybrany dostawca</th>
                  <th>Maile</th>
                  <th>Maile DW</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(order, k) in orders">
                  <td>{{ order.order_number }}</td>
                  <td>{{ order.project }}</td>
                  <td>{{ order.team_name }}</td>
                  <td>{{ order.supplier }}</td>
                  <td>{{ order.send_to_mails }}</td>
                  <td>{{ order.send_to_mails_dw }}</td>
                </tr>
                </tbody>
              </table>
            </div>

          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              <i class="bi bi-x-square me-2"></i>Zamknij
            </button>
            <form @submit.prevent="sendOrders">
              <button type="submit" class="btn btn-primary"
                      :disabled="isSending">
                <i class="bi bi-save me-2"></i>Wyślij powyższe zamówienia
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {Modal, Tooltip} from "bootstrap";
import {DatePickerComponent} from "@syncfusion/ej2-vue-calendars";

export default {
  components: {'ejs-datepicker': DatePickerComponent},
  props: {
    project: Number | String,
    team: String,
    selectedItems: Object,
    checkWarehouses: true
  },

  data() {
    return {
      orders: null,
      errors: {},
      isSending: false,
    }
  },

  watch: {
    selectedItems: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.orders = newValue.filter(
              (item, index, self) => index === self.findIndex((t) => t.order_lists_id === item.order_lists_id)
          );
        }
      }
    }
  },

  async mounted() {
    this.modalObj = new Modal(this.$refs.modalEle);
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    })
  },

  methods: {
    async sendOrders() {
      this.isSending = true;

      await axios.post(route('orders.send'), {'orders': this.orders})
          .then(response => {
            this.$emit('added-success', response.data);
            this.$emit('refresh', true);
          }).catch(error => {
            console.log(error);
            this.$emit('added-error', error.response.data);
          });

      this.isSending = false;
      this.hide();
    },

    show() {
      if (this.modalObj) {
        this.modalObj.show();
      }
    },
    hide() {
      if (this.modalObj) {
        this.modalObj.hide();
      }
    },
  }
}
</script>