<template>
  <div class="widget-content">
    <div class="card">

      <div class="card-header text-black py-3">
        <div>
          Użyte w zapytaniu ofertowym: {{ usedInQuoteRequest.join(', ') }}
        </div>
        <div>
          Użyte w zamówieniu: {{ usedInOrder.join(', ') }}
        </div>
      </div>

      <div class="card-body">
        <table class="table table-bordered">
          <tbody>
          <tr>
            <th>Nr pozycji:</th>
            <td>{{ selectedRecord.id }}</td>

            <th>Zespół:</th>
            <td>{{ selectedRecord.team_name }}</td>

            <th>Podzespół nr rysunku</th>
            <td>{{ selectedRecord.subteam_drawing_no }}</td>
          </tr>
          <tr>
            <th>Nr katalogowy</th>
            <td>{{ selectedRecord.article?.catalog_number || '' }}</td>

            <th>Model</th>
            <td>{{ selectedRecord.article?.model || '' }}</td>

            <th>Opis</th>
            <td>{{ selectedRecord.article?.description || '' }}</td>
          </tr>
          <tr>
            <th>link</th>
            <td>{{ selectedRecord.link }}</td>

            <th>J.m.</th>
            <td>{{ selectedRecord.article_unit?.name }}</td>

            <th>Producent</th>
            <td>{{ selectedRecord.producer?.id }}</td>
          </tr>
          <tr>
            <th>Kategoria</th>
            <td>{{ selectedRecord.customer_category?.id }}</td>

            <th>Ilość wymagana</th>
            <td>{{ selectedRecord.quantity_required }}</td>

            <th>Dostawcy</th>
            <td>{{ selectedRecord.suppliers_short_names }}</td>
          </tr>
          <tr>
            <th>Dodany przez</th>
            <td>{{ selectedRecord.created_by?.name }}</td>

            <th>Data dodania</th>
            <td>{{ formatDate(selectedRecord.created_at) }}</td>

            <th>Status</th>
            <td>{{ selectedRecord.status }}</td>
          </tr>
          <tr>
            <th>Data dostawy</th>
            <td>{{ formatDate(selectedRecord.delivery_date) }}</td>

            <th>Data zwolnienia</th>
            <td>{{ formatDate(selectedRecord.release_date) }}</td>

            <th>Data zlec. zamówienia</th>
            <td>{{ formatDate(selectedRecord.order_submission_date) }}</td>
          </tr>
          <tr>
            <th>Data Wydr. zamówienia</th>
            <td>{{ formatDate(selectedRecord.order_status_confirmation_date) }}</td>

            <th>Status magazynowy</th>
            <td>{{ selectedRecord.inventory_status }}</td>

            <th>Data produkcji</th>
            <td>{{ formatDate(selectedRecord.production_due_date) }}</td>
          </tr>
          <tr>
            <th>Czas dowstawy</th>
            <td>{{ formatDate(selectedRecord.lead_time) }}</td>

            <th>Uwagi</th>
            <td>{{ selectedRecord.comments }}</td>

            <th>Ostatnio zmienił</th>
            <td>{{ selectedRecord.updated_by?.name }}</td>
          </tr>
          <tr>
            <th>Ilość magazynowa</th>
            <td>{{ selectedRecord.article_main_warehouse?.quantity }}</td>

            <th>Magazyn</th>
            <td>{{ selectedRecord.warehouse_received }}</td>

            <th>Nr Patentu</th>
            <td>{{ selectedRecord.patent_number }}</td>
          </tr>
          <tr>
            <th>Ilość do zamówienia</th>
            <td>{{ (selectedRecord.numbering?.quantity || 0) * (selectedRecord.quantity_required || 0) }}</td>
          </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</template>

<script>
import {Modal} from "bootstrap";
import {DatePickerComponent} from "@syncfusion/ej2-vue-calendars";

let dropInstanceFilterUnits, delivererElem, multiSelectObj;

export default {
  components: {
    "ejs-datepicker": DatePickerComponent,
  },

  props: {
    selectedRecord: Object,
    statuses: Object
  },

  watch: {
    selectedRecord: {
      handler(newVal) {
        if (newVal && newVal.id) {
          this.getUsedInQuoteRequest();
          this.getUsedInOrder();
        }
      },
      deep: true,
      immediate: true
    }
  },

  data() {
    return {
      usedInQuoteRequest: [],
      usedInOrder: [],
    }
  },

  methods: {

    formatDate(dateString) {
      if (!dateString)
        return '';

      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Miesiące są od 0 do 11, więc dodajemy 1
      const year = date.getFullYear();

      return `${day}.${month}.${year}`;
    },

    async getUsedInQuoteRequest() {
      if (!this.selectedRecord?.id) return;

      try {
        this.usedInQuoteRequest = [];

        let quoteRequests = await axios.get(route('requiredProjectItems.get-use-quote-requests', {
          requiredProjectItem: this.selectedRecord.id
        }));
        quoteRequests = quoteRequests.data.map((item) => {
          this.usedInQuoteRequest.push(item?.quote_request?.request_number);
        });

      } catch (error) {
        console.error("Błąd podczas pobierania zapytań ofertowych:", error);
      }
    },

    async getUsedInOrder() {
      if (!this.selectedRecord?.id) return;

      try {
        this.usedInOrder = [];

        let orders = await axios.get(route('requiredProjectItems.get-use-orders', {
          requiredProjectItem: this.selectedRecord.id
        }));
        orders = orders.data.map((item) => {
          this.usedInOrder.push(item?.order_list?.order_number);
        });

        // console.log(this.usedInOrder);
      } catch (error) {
        console.error("Błąd podczas pobierania zamówień:", error);
      }
    }
  }
}

</script>

<style scoped>
th {
  max-width: 50px;
}
</style>