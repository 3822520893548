<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="addQuoteRequestModal" ref="modalEle" tabindex="-1" role="dialog"
         aria-labelledby="modelTitleId"
         aria-hidden="true">
      <div class="modal-dialog modal-full-width" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Dodaj pozycję do zamówienia</h5>
            <button type="button" class="close ms-auto" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" v-if="selectedOrderId">

            <div v-if="selectedArticles.length > 0" class="table-responsive"
                 style="border-bottom:1px solid #ddd; padding-bottom:20px">
              <h5>Lista wybranych artykułów</h5>
              <table class="table table-striped">
                <thead>
                <tr>
                  <th></th>
                  <th>#</th>
                  <th>Nr Katalogowy</th>
                  <th>Model</th>
                  <th>J.m.</th>
                  <th>Nazwa/Opis</th>
                  <th>Ilość/Stany magazynowe</th>
                  <th>Ostateczny termin dostawy</th>
                  <th>Ilość wymagana</th>
                  <th>Uwagi</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="article in selectedArticles">
                  <td>
                    <button @click="removeFromSelectedArticles(article.id)" class="btn btn-outline-danger btn-xs">
                      Usuń
                    </button>
                  </td>
                  <td>{{ article.id }}</td>
                  <td>{{ article.catalog_number }}</td>
                  <td>{{ article.model }}</td>
                  <td>{{ article.article_unit.name }}</td>
                  <td>{{ article.description }}</td>
                  <td>{{ article.available_items?.quantity ?? 0 }}</td>
                  <td class="cell-editable">
                    <input type="date" v-model="article.delivery_date_deadline"
                           class="form-control"/>
                  </td>
                  <td class="cell-editable">
                    <input type="number" step="0.01" min="1" v-model="article.quantity_required"
                           class="form-control" @input="checkQuantity(article)" required/>
                  </td>
                  <td class="cell-editable">
                    <input type="text" v-model="article.comments" class="form-control"/>
                  </td>
                </tr>
                </tbody>
              </table>

              <button type="button" class="btn btn-primary" @click="saveSelectedPositions" :disabled="isSubmitting">
                Dodaj wybrane pozycje
              </button>
            </div>
            <div v-else>
              <span class="text-warning">Wybierz artykuły, które chcesz dodać z listy poniżej</span>
            </div>

            <h5 class="pt-4 pb-2">Lista dostępnych artykułów</h5>
            <ejs-grid ref="addOrderItemsGridRef" id="addOrderItemsGrid" height="500px"
                      :dataSource="articlesDataManager"
                      :allowTextWrap='false'
                      :allowExcelExport='false'
                      :allowPaging="true"
                      :allowSorting='true'
                      :allowFiltering='true'
                      :allowReordering='true'
                      :allowResizing='true'
                      :showColumnChooser='true'
                      :allowSelection='true'
                      :enablePersistence='false'
                      :pageSettings="pageSettings"
                      :editSettings="editSettings"
                      :filterSettings="filterOptions"
                      :toolbar="toolbar"
                      :selectionSettings="selectionOptions"
                      :toolbarClick="clickToolbarHandler">
              <e-columns>
                <e-column headerText="Dodaj" width="80" :template="'addTemplate'" textAlign="Center"></e-column>
                <e-column field="id" headerText="ID" width="100" :isPrimaryKey="true" :visible="false"
                          :allowEditing="false"/>
                <e-column field="photos" headerText="Zdjęcia" width="150" :template="'imageTemplate'"
                          :allowEditing="false" :allowFiltering="false"></e-column>
                <e-column field="catalog_number" headerText="Nr katalogowy" width="150" :filter="filterDefault"
                          :allowEditing="false"/>
                <e-column field="model" headerText="Model" width="150" :filter="filterDefault"
                          :allowEditing="false"/>
                <e-column field="article_unit.id" headerText="J.m." width="150" editType="dropdownedit"
                          :dataSource="units" foreignKeyValue='name' foreignKeyField='id'
                          :filter='filterUnits' :allowEditing="false"/>
                <e-column field="description" headerText="Nazwa/Opis" :defaultValue="null" width="200"
                          :filter="filterDefault" :allowEditing="false"/>
                <e-column field="link" headerText="Link" :defaultValue="null" :filter="filterDefault"
                          :template="'linkTemplate'" :allowEditing="false"/>
                <e-column field="available_items.quantity" headerText="Ilość/Stany magazynowe" width="150"
                          format="N2" :filter="filterDefault" :allowEditing="false"/>
              </e-columns>

              <template v-slot:addTemplate="{ data }">
                <template v-if="!isArticleSelected(data.id)">
                  <button @click="addToNewItems(data)"
                          class="btn btn-xs btn-outline-primary">Dodaj
                  </button>
                </template>
                <template v-else><span class="text-success">Wybrano</span></template>
              </template>

              <template v-slot:linkTemplate="{data}">
                <a class="link link-primary" target="_blank" rel="noopener noreferrer" :href=data.link>{{
                    data.link
                  }}</a>
              </template>

              <template v-slot:imageTemplate="{ data }">
                <div v-if="data.photos && data.photos.length > 0">
                  <img :src="`/${data.photos[0].photo_path}`" alt="Zdjęcie"
                       style="max-height: 100px; max-width: 150px"/>
                </div>
              </template>

            </ejs-grid>

          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              <i class="bi bi-x-square me-2"></i>Zamknij
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {Modal, Tooltip} from "bootstrap";
import {DatePickerComponent} from "@syncfusion/ej2-vue-calendars";
import {
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  ContextMenu,
  Edit,
  ExcelExport,
  Filter, ForeignKey,
  GridComponent,
  Page,
  Reorder,
  Resize,
  Sort,
  Toolbar,
  RowDD,
  VirtualScroll
} from '@syncfusion/ej2-vue-grids';
import {createElement} from "@syncfusion/ej2-base";
import {DropDownList} from "@syncfusion/ej2-dropdowns";
import {MultiSelect} from "@syncfusion/ej2-vue-dropdowns";
import {DataManager, WebMethodAdaptor} from "@syncfusion/ej2-data";
import {usePage} from "@inertiajs/vue3";
import {ref} from "vue";

let dropInstanceFilterUnits, delivererElem = ref(null), multiSelectObj = ref(null);
export default {
  components: {
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    'e-column': ColumnDirective,
    'ejs-datepicker': DatePickerComponent
  },
  props: {
    selectedOrderId: Number,
    suppliers: Object,
    units: Object
  },

  provide: {
    grid: [Page, Edit, Toolbar, ContextMenu, Sort, Filter, Reorder, Resize, ColumnChooser, ExcelExport, ForeignKey, RowDD, VirtualScroll]
  },


  data() {
    return {
      errors: {},
      isSubmitting: false,
      selectedArticles: [],
      articlesDataManager: [],
      pageSettings: {pageSize: 50, pageCount: 5},
      filterOptions: {type: "Menu", operator: 'contains'},
      filterDefault: {operator: 'contains', params: {minLength: 2}},
      toolbar: this.getToolbar(),
      editSettings: this.getEditSettings(),
      selectionOptions: {checkboxOnly: true, persistSelection: true},
      filterUnits: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.units,
              fields: {text: 'name', value: 'id'},
              placeholder: 'Szukaj jednostki',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },
    }
  },

  async mounted() {
    this.modalObj = new Modal(this.$refs.modalEle);
  },

  beforeMount() {
    this.getArticles();
  },

  methods: {

    async getArticles() {
      this.articlesDataManager = new DataManager({
        url: route('articles.get', {
          project: this.selectedProjectId,
          team: this.selectedTeamName,
          without_deleted: true
        }),
        adaptor: new WebMethodAdaptor(),
        batchUrl: route('requiredProjectItems.saveChanges'),
        updateUrl: route('requiredProjectItems.updateChanges'),
        crossDomain: true,
        headers: [
          {'X-CSRF-TOKEN': usePage().props.csrf_token}
        ],
      });
    },

    getToolbar() {
      return [
        {text: "Resetuj filtry", prefixIcon: 'e-reset', id: 'reset_filters'},
        {text: "Lista artykułów", prefixIcon: 'e-table-of-content', id: 'articles_list'},
        {text: "Search", prefixIcon: 'e-search', id: "search", align: 'center'},
        {text: "Odśwież", prefixIcon: 'e-refresh', id: "refresh", align: 'right'},
      ]
    },

    getEditSettings() {
      return {
        allowEditing: true,
        allowAdding: false,
        allowDeleting: false,
        showConfirmDialog: false,
        showDeleteConfirmDialog: false,
        mode: 'Batch',
        newRowPosition: 'Top',
      };
    },

    removeFromSelectedArticles(articleId) {
      this.selectedArticles = this.selectedArticles.filter(item => item.id !== articleId);
    },

    checkQuantity(article) {
      if (article.quantity_required < 1 || isNaN(article.quantity_required)) {
        article.quantity_required = 1;
      }
    },

    isArticleSelected(articleId) {
      return this.selectedArticles.some(item => item.id === articleId);
    },

    addToNewItems(rowData) {
      if (!this.selectedArticles.find(item => {
        return item.id === rowData.id
      })) {
        this.selectedArticles.push({
          ...rowData,
        });
      }
    },

    clickToolbarHandler: async function (args) {
      /**
       * Akcja przy kliknięciu resetowania filtrów
       */
      if (args.item.id === 'reset_filters') {
        this.$refs.addOrderItemsGridRef.clearFiltering();
      }

      /**
       * Akcja przy kliknięciu listy artykułów
       */
      if (args.item.id === 'articles_list') {
        window.open(route('articles.index'), '_blank');
      }

      /**
       * Akcja przy kliknięciu odświeżania tabeli
       */
      if (args.item.id === 'refresh') {
        this.$refs.addOrderItemsGridRef.ej2Instances.refresh();
      }
    },

    async saveSelectedPositions() {
      this.isSending = true;

      let data = this.selectedArticles.map(article => ({
        orderId: this.selectedOrderId,
        article_id: article.id,
        delivery_date_deadline: article.delivery_date_deadline || null, // Jeśli brak, to null
        quantity_required: article.quantity_required && article.quantity_required > 0 ? article.quantity_required : 1, // Jeśli brak lub <1, ustaw na 1
        comments: article.comments || '' // Jeśli brak, to pusty string
      }))

      await axios.post(route('orderListsItems.saveChanges'), {'added': data})
          .then(response => {
            this.$emit('added-success', response.data.message);
            this.$emit('item-added', true);
          }).catch(error => {
            console.log(error);
            this.$emit('added-error', error.response.data);
          });

      this.isSending = false;
      this.hide();
    },

    show() {
      if (this.modalObj) {
        this.modalObj.show();
      }
    },
    hide() {
      if (this.modalObj) {
        this.modalObj.hide();
      }
    },
  }
}
</script>