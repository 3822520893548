<template>
  <div>

    <table class="table table-bordered">
      <tbody>
      <tr>
        <th>ID</th>
        <td>{{ selectedArticle.article.id }}</td>

        <th>Nr katalogowy:</th>
        <td>{{ selectedArticle.article.catalog_number }}</td>

        <th>Model</th>
        <td>{{ selectedArticle.article.model }}</td>
      </tr>
      <tr>
        <th>J.m.</th>
        <td>{{ selectedArticle.article.article_unit?.name }}</td>

        <th>Opis</th>
        <td>{{ selectedArticle.article.description }}</td>

        <th>Szczegóły</th>
        <td>{{ selectedArticle.article.details }}</td>
      </tr>
      <tr>
        <th>link</th>
        <td>{{ selectedArticle.article.link }}</td>

        <th>Utworzono</th>
        <td>{{ formatDate(selectedArticle.article.created_at) }}</td>

        <th>Utworzył</th>
        <td>{{ selectedArticle.article.created_by?.name }}</td>
      </tr>
      <tr>
        <th>Producent</th>
        <td>{{ selectedArticle.article.producer?.id }}</td>

        <th>Kategoria</th>
        <td>{{ selectedArticle.article.customer_category }}</td>

        <th>Dostawcy</th>
        <td>{{ selectedArticle.article.suppliers_short_names }}</td>
      </tr>

      <template v-if="hasPermission('canViewArticlesPricesAndHistory')">
        <tr>
          <th>VAT</th>
          <td>{{ selectedArticle.article.vat }}</td>

          <th>Cena netto zł</th>
          <td>{{ selectedArticle.article.price_netto_zl }}</td>

          <th>Cena brutto zł</th>
          <td>{{ selectedArticle.article.price_brutto_zl }}</td>
        </tr>
        <tr>
          <th>Cena netto eur</th>
          <td>{{ selectedArticle.article.price_netto_eur }}</td>

          <th>Cena brutto eur</th>
          <td>{{ selectedArticle.article.price_brutto_eur }}</td>
        </tr>
      </template>

      <tr>
        <th>Lokacja (magazyn)</th>
        <td>{{ selectedArticle.article_warehouse?.short_name }}</td>

        <th>Ilość/Stany magazynowe</th>
        <td>{{ selectedArticle.article.stock_quantity }}</td>

        <th>Przychód</th>
        <td>{{ selectedArticle.article.income }}</td>
      </tr>

      <tr>
        <th>Rozchód</th>
        <td>{{ selectedArticle.article.outflow }}</td>

        <template v-if="hasPermission('canViewArticlesPricesAndHistory')">
          <th>Wartość netto</th>
          <td>{{ selectedArticle.article.value_net }}</td>

          <th>Wartość brutto</th>
          <td>{{ selectedArticle.article.value_gross }}</td>
        </template>
      </tr>
      <tr>
        <th>Zdjęcia</th>
        <td colspan="6">{{ selectedArticle.article.photos }}</td>
      </tr>

      </tbody>
    </table>

  </div>
</template>

<script>
import {Modal} from "bootstrap";
import {DatePickerComponent} from "@syncfusion/ej2-vue-calendars";
import {usePermission} from "../../composables/resources/js/composables/permissions.js";

let dropInstanceFilterUnits, delivererElem, multiSelectObj;


const {hasPermission} = usePermission();

export default {
  components: {
    "ejs-datepicker": DatePickerComponent,
    hasPermission
  },

  props: {
    selectedArticle: Object,
    statuses: Object
  },

  data() {
    return {
      hasPermission
    }
  },

  methods: {

    formatDate(dateString) {
      if (!dateString)
        return '';

      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Miesiące są od 0 do 11, więc dodajemy 1
      const year = date.getFullYear();

      return `${day}.${month}.${year}`;
    },
  }
}

</script>

<style scoped>
th {
  max-width: 50px;
}
</style>