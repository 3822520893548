<template>
  <AppLayout>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-six">
        <div class="widget-heading d-block">
          <div class="row">
            <div class="col-xl-12 col-md-12 col-sm-12 col-12 d-flex align-items-center">
              <h4 class="me-3 my-0">Formy płatności dla zamówień</h4>

              <Link class="btn btn-sm btn-outline-primary ms-auto" :href="route('orderLists.index')">Zamówienia
              </Link>

            </div>
          </div>

          <div v-if="$page.props.errors">
            <ul style="list-style-type: none; padding: 0" v-for="error in $page.props.errors">
              <li class="alert alert-danger">{{ error }}</li>
            </ul>
          </div>

        </div>

        <div class="widget-content">

          <table class="table table-bordered table-striped">
            <thead class="table-light">
            <tr>
              <th>Nazwa</th>
              <th>Skrót</th>
              <th>Aktywna</th>
              <th>Akcje</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="method in methods" :key="method.id">

              <td v-if="editingId === method.id">
                <input v-model="method.name" type="text" class="form-control form-control-sm"/>
              </td>
              <td v-else>
                {{ method.name }}
              </td>

              <td v-if="editingId === method.id">
                <input v-model="method.short_name" type="text" class="form-control form-control-sm"/>
              </td>
              <td v-else>
                {{ method.short_name }}
              </td>

              <td class="text-center">
                <template v-if="editingId === method.id">
                  <input v-model="method.active" type="checkbox"/>
                </template>
                <template v-else>
                  <i :class="method.active ? 'text-success' : 'text-muted'">
                    {{ method.active ? '✓' : '✗' }}
                  </i>
                </template>
              </td>

              <td class="text-end">
                <template v-if="editingId === method.id">
                  <button @click="update(method)" class="btn btn-sm btn-success me-2">Zapisz</button>
                  <button @click="cancelEdit" class="btn btn-sm btn-secondary">Anuluj</button>
                </template>
                <template v-else>
                  <button @click="edit(method)" class="btn btn-sm btn-primary me-2">Zmień</button>
                  <button @click="destroy(method.id)" class="btn btn-sm btn-danger">Usuń</button>
                </template>
              </td>
            </tr>

            </tbody>
          </table>

          <!-- Formularz dodawania nowej metody -->
          <form @submit.prevent="store" class="row g-3 align-items-end mb-4">
            <div class="col-md-3">
              <label for="name" class="form-label">Nazwa</label>
              <input v-model="form.name" type="text" id="name" class="form-control form-control-sm" required/>
            </div>

            <div class="col-md-2">
              <label for="short_name" class="form-label">Skrót</label>
              <input v-model="form.short_name" type="text" id="short_name" class="form-control form-control-sm"/>
            </div>

            <div class="col-md-1">
              <div class="form-check mt-4">
                <input class="form-check-input" type="checkbox" role="switch" id="active"
                       style="height:1.5em; width:3em;"
                       v-model="form.active">
                <label class="form-check-label" for="active" style="margin: 3px 0 0 10px;">Aktywna</label>
              </div>
            </div>

            <div class="col-md-2">
              <button type="submit" class="btn btn-primary w-100">Dodaj</button>
            </div>
          </form>

        </div>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from "../../../Layouts/App.vue";
import {useForm} from "@inertiajs/vue3";

export default {
  components: {AppLayout},

  props: {
    methods: Array
  },
  data() {
    return {
      editingId: null,
      form: useForm({
        name: '',
        short_name: '',
        active: true
      })
    }
  },
  methods: {
    edit(method) {
      this.editingId = method.id;
    },

    cancelEdit() {
      this.editingId = null;
    },

    store() {
      this.form.post(route('orderPaymentMethods.store'), {
        onSuccess: (resp) => {
          this.form.reset();
          this.successMsg(resp.success)
        },
        onError: (error) => {
          console.log(error);
          this.errorMsg('Coś poszło nie tak', error);
        }
      });
    },
    update(method) {
      const updateForm = useForm({
        name: method.name,
        short_name: method.short_name,
        active: method.active
      });

      updateForm.put(route('orderPaymentMethods.update', method.id), {
        onSuccess: (resp) => {
          this.successMsg(resp.success);
          this.editingId = null;
        },
        onError: (error) => {
          this.errorMsg(error);
        }
      });
    },
    destroy(id) {
      Toast.fire({
        toast: false,
        title: 'Czy na pewno chcesz usunąć tę formę płatności?',
        position: 'center',
        icon: 'warning',
        timer: false,
        showConfirmButton: true,
        confirmButtonText: 'Tak, usuń',
        confirmButtonColor: 'success',
        showCancelButton: true,
        cancelButtonText: 'Nie',
      }).then(result => {
        if (result.isConfirmed) {
          this.form.delete(route('orderPaymentMethods.destroy', id), {
            onSuccess: (resp) => {
              this.form.reset();
              this.successMsg(resp)
            },
            onError: (error) => {
              console.log(error);
              this.errorMsg(error);
            }
          });
        }
      })
    },

    successMsg(resp) {
      Toast.fire({
        position: 'top-end',
        toast: true,
        icon: 'success',
        title: 'Sukces!',
        html: resp,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true
      });
    },

    errorMsg(resp) {
      Toast.fire({
        position: 'top-end',
        toast: true,
        icon: 'warning',
        title: 'Błąd!',
        html: resp,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true
      });
    },
  }
};
</script>
