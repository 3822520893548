<script setup>
import {usePermission} from "../../../composables/resources/js/composables/permissions.js";

const {hasRole, hasPermission} = usePermission();

</script>
<template>
  <AppLayout>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-six">

        <projectMenu :project="project"></projectMenu>

        <div class="widget-content">
          <div v-if="$page.props.errors">
            <ul style="list-style-type: none; padding: 0" v-for="error in $page.props.errors">
              <li class="alert alert-danger">{{ error }}</li>
            </ul>
          </div>

          <div class="card-body mb-5">
            <div class="row mt-5 pt-2">
              <div class="col-md-4 col-sm-12 d-table">
                <div class="d-table-row">
                  <h4>FAT</h4>
                  <div class="fatListFile">
                    <ul class="ps-0 mt-4 mb-4">
                      <li style="list-style-type: none" v-if="isLoadingFiles">
                        <div class="spinner-border spinner-border-reverse align-self-center loader-sm text-secondary">
                          Ładowanie...
                        </div>
                      </li>
                      <li class="row mb-2 align-items-center" v-else v-for="file in fatAttachments">
                        <div class="col-5">{{ file.file_name }}</div>
                        <div class="col-4"><i>{{ file.created_at }}</i></div>
                        <div class="col-3">
                          <div class="d-inline-flex">
                            <a class="btn btn-xs btn-outline-info px-2 me-1"
                               :href="route('projects.download-attachment-file', [project.id, 'FAT', file.file_name])"
                               download>
                              <i class="bi bi-download" style="font-size: 12px; margin-right: 5px;"></i>
                              <span>Pobierz</span></a>
                            <button v-if="file.created_by.id === $page.props.user.id || hasRole('ADMIN')"
                                    class="btn btn-xs btn-outline-danger px-2" @click="deleteFile(file)" type="button">
                              <i class="bi bi-trash" style="font-size: 12px; margin-right: 5px;"></i>
                              <span>Usuń</span>
                            </button>
                          </div>
                        </div>
                      </li>
                      <li class="row" v-if="fatAttachments.length <= 0 && !isLoadingFiles">
                        <div class="col-4"><i>Brak plików</i></div>
                      </li>
                    </ul>
                  </div>
                </div>
                <form class="d-table-cell align-bottom" @submit.prevent="saveFatForm()" enctype="multipart/form-data">
                  <input type="file" class="form-control" v-on:change="onChange">
                  <button class="mt-3 btn btn-primary btn-block" :disabled="isProcessingFat || !file">Zapisz</button>
                </form>
              </div>
              <div class="col-md-4 col-sm-12 d-table">
                <div class="d-table-row">
                  <h4>SAT</h4>
                  <div class="satListFile">
                    <ul class="ps-0 mt-4 mb-4">
                      <li style="list-style-type: none" v-if="isLoadingFiles">
                        <div class="spinner-border spinner-border-reverse align-self-center loader-sm text-secondary">
                          Ładowanie...
                        </div>
                      </li>
                      <li class="row mb-2 align-items-center" v-else v-for="file in satAttachments">
                        <div class="col-5">{{ file.file_name }}</div>
                        <div class="col-4"><i>{{ file.created_at }}</i></div>
                        <div class="col-3">
                          <div class="d-inline-flex">
                            <a class="btn btn-xs btn-outline-info px-2 me-1"
                               :href="route('projects.download-attachment-file', [project.id, 'SAT', file.file_name])"
                               download>
                              <i class="bi bi-download" style="font-size: 12px; margin-right: 5px;"></i>
                              <span>Pobierz</span></a>
                            <button v-if="file.created_by.id === $page.props.user.id || hasRole('ADMIN')"
                                    class="btn btn-xs btn-outline-danger px-2" @click="deleteFile(file)" type="button">
                              <i class="bi bi-trash" style="font-size: 12px; margin-right: 5px;"></i>
                              <span>Usuń</span>
                            </button>
                          </div>
                        </div>
                      </li>
                      <li class="row" v-if="fatAttachments.length <= 0 && !isLoadingFiles">
                        <div class="col-4"><i>Brak plików</i></div>
                      </li>
                    </ul>
                  </div>
                </div>
                <form class="d-table-cell align-bottom" @submit.prevent="saveSatForm()" enctype="multipart/form-data">
                  <input type="file" class="form-control" v-on:change="onChange">
                  <button class="mt-3 btn btn-primary btn-block" :disabled="isProcessingSat || !file">Zapisz</button>
                </form>
              </div>

              <div class="col-md-4 col-sm-12 d-table">
                <div class="d-table-row">
                  <h4>INNE</h4>
                  <div class="otherListFile">
                    <ul class="ps-0 mt-4 mb-4">
                      <li style="list-style-type: none" v-if="isLoadingFiles">
                        <div class="spinner-border spinner-border-reverse align-self-center loader-sm text-secondary">
                          Ładowanie...
                        </div>
                      </li>
                      <li class="row mb-2 align-items-center" v-else v-for="file in otherAttachments">
                        <div class="col-5">{{ file.file_name }}</div>
                        <div class="col-4"><i>{{ file.created_at }}</i></div>
                        <div class="col-3">
                          <div class="d-inline-flex">
                            <a class="btn btn-xs btn-outline-info px-2 me-1"
                               :href="route('projects.download-attachment-file', [project.id, 'OTHER', file.file_name])"
                               download>
                              <i class="bi bi-download" style="font-size: 12px; margin-right: 5px;"></i>
                              <span>Pobierz</span></a>
                            <button v-if="file.created_by.id === $page.props.user.id || hasRole('ADMIN')"
                                    class="btn btn-xs btn-outline-danger px-2" @click="deleteFile(file)" type="button">
                              <i class="bi bi-trash" style="font-size: 12px; margin-right: 5px;"></i>
                              <span>Usuń</span>
                            </button>
                          </div>
                        </div>
                      </li>
                      <li class="row" v-if="otherAttachments.length <= 0 && !isLoadingFiles">
                        <div class="col-4"><i>Brak plików</i></div>
                      </li>
                    </ul>
                  </div>
                </div>
                <form class="d-table-cell align-bottom" @submit.prevent="saveOtherForm()" enctype="multipart/form-data">
                  <input type="file" class="form-control" v-on:change="onChange">
                  <button class="mt-3 btn btn-primary btn-block" :disabled="isProcessingOther || !file">Zapisz</button>
                </form>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from "@/Layouts/App.vue";
import route from "../../../../../vendor/tightenco/ziggy/src/js/index.js";
import projectMenu from "@/Pages/Projects/parts/projectMenu.vue";
import {usePermission} from "../../../composables/resources/js/composables/permissions.js";


export default {
  components: {AppLayout, projectMenu},
  props: {
    project: Object
  },
  data() {
    return {
      initAttachments: [],
      name: '',
      file: '',
      success: '',
      isLoadingFiles: false,
      isProcessingFat: false,
      isProcessingSat: false,
      isProcessingOther: false,
      fatAttachments: [],
      satAttachments: [],
      otherAttachments: [],
    };
  },
  async mounted() {
    this.isLoadingFiles = true;
    this.initAttachments = await this.getAttachments(); // Poczekaj na zakończenie żądania

    // Po zakończeniu montowania dopasuj załączniki do odpowiednich sekcji
    this.matchFiles();

    this.isLoadingFiles = false;

  },
  methods: {
    route,
    isRouteActive(routeName) {
      return route().current() === routeName;
    },
    onChange(e) {
      this.file = e.target.files[0];
    },
    async getAttachments() {
      try {
        const response = await axios.post(route('projects.get.attachments', {project: this.project.id}));
        return response.data.attachments ?? [];
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    matchFiles() {
      try {
        if (this.initAttachments.length > 0) {
          this.initAttachments.forEach(function (attachment) {
            if (attachment.file_section === 'FAT') {
              this.fatAttachments.push(attachment);
            } else if (attachment.file_section === 'SAT') {
              this.satAttachments.push(attachment);
            } else {
              this.otherAttachments.push(attachment);
            }
          }, this);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingFiles = false;
      }
    },
    async refreshAttachments() {
      this.isLoadingFiles = true;
      this.fatAttachments = [];
      this.satAttachments = [];
      this.otherAttachments = [];

      try {
        const attachments = await this.getAttachments();
        this.initAttachments = attachments;

        // Po pobraniu załączników dopasuj je do odpowiednich sekcji
        this.matchFiles();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingFiles = false;
      }
    },
    saveFatForm() {
      this.saveFile('FAT');
    },
    saveSatForm() {
      this.saveFile('SAT');
    },
    saveOtherForm() {
      this.saveFile('OTHER');
    },

    async saveFile(section) {
      this.isProcessingFat = true;
      this.isProcessingSat = true;
      this.isProcessingOther = true;

      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
      let data = new FormData();
      data.append('file', this.file);

      try {
        const response = await axios.post(route('projects.upload-and-store-attachment', {
          project: this.project.id,
          section: section,
          files: data
        }), data, config);

        this.showToast(response.data.icon, response.data.title, response.data.message);
        if (section === 'FAT')
          this.fatAttachments.unshift(response.data.uploadedFile);
        else if (section === 'SAT')
          this.satAttachments.unshift(response.data.uploadedFile);
        else
          this.otherAttachments.unshift(response.data.uploadedFile);

        // Resetowanie wartości obiektu file po udanym uploadzie
        this.file = null;
      } catch (error) {
        this.showToast(error.data.icon, error.data.title, error.data.message);
      } finally {
        this.isProcessingFat = false;
        this.isProcessingSat = false;
        this.isProcessingOther = false;
      }
    },

    deleteFile(projectAttachment) {
      let self = this;
      Toast.fire({
        toast: false,
        icon: 'warning',
        title: 'Usuwanie pliku',
        html: 'Czy na pewno chcesz usunąć ten plik?',
        showDenyButton: true,
        timer: false,
        position: 'center',
        showConfirmButton: true,
        confirmButtonText: 'Tak',
        denyButtonText: 'Nie',
      }).then((result) => {
        if (result.isConfirmed) {
          axios.delete(route('projects.attachment.file.delete', {
            project: self.project.id,
            attachment: projectAttachment.id
          })).then(response => {
            this.showToast(response.data.icon, response.data.title, response.data.message);

            // Po usunięciu załącznika odśwież listę załączników
            self.refreshAttachments();
          });
        }
      });
    },
    showToast(icon, title, message) {
      Toast.fire({
        position: 'top-end',
        toast: true,
        icon: icon,
        title: title,
        html: message,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true
      });
    }
  }
}
</script>
