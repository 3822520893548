<template>
  <div>
    <div class="modal fade" id="articleSelectionModal" ref="modalEle" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-full-width" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Wybierz artykuł</h5>
            <button type="button" class="close ms-auto" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-if="articleToChange">
              <ejs-grid ref="articlesGrid" id="articlesGrid" height="500px"
                        :dataSource="dataManager"
                        :allowTextWrap='false'
                        :allowExcelExport='false'
                        :allowPaging="true"
                        :allowSorting='true'
                        :allowFiltering='true'
                        :allowReordering='false'
                        :allowResizing='true'
                        :showColumnChooser='true'
                        :allowSelection='true'
                        :enablePersistence='false'
                        :actionBegin="actionBegin"
                        :actionComplete="actionComplete"
                        :pageSettings="pageSettings"
                        :filterSettings="filterOptions"
                        :toolbar="toolbar"
                        :rowDataBound="onRowDataBound"
                        :rowSelected="rowSelected"
                        :toolbarClick="toolbarClick">
                <e-columns>
                  <e-column field="id" headerText="ID" width="92" :isPrimaryKey="true" />
                  <e-column field="catalog_number" headerText="Nr katalogowy" width="150"
                            :filter="filterDefault" />
                  <e-column field="model" headerText="Model" width="150" :filter="filterDefault" />
                  <e-column field="article_unit.id" headerText="J.m." width="150" editType="dropdownedit"
                            :dataSource="units" foreignKeyValue='name' foreignKeyField='id'
                            :filter='filterUnits' />
                  <e-column field="description" headerText="Nazwa/Opis" :defaultValue="null" width="200"
                            :filter="filterDefault" />
                  <e-column field="details" headerText="Sczegóły" type="text" :defaultValue="null" width="200"
                            :filter="filterDefault" />
                  <e-column field="link" headerText="Link" :defaultValue="null" :filter="filterDefault"
                            :template="'linkTemplate'" />
                  <e-column field="created_at" headerText="Utworzono" width="150" type="date"
                            format="dd.MM.yyyy HH:mm:ss" />
                  <e-column field="producer.id" headerText="Producent" width="150" editType="dropdownedit"
                            :dataSource="producers" foreignKeyValue='short_name' foreignKeyField='id'
                            :filter='filterProducers' />
                  <e-column field="customer_category.id" headerText="Kategoria" width="250" editType="dropdownedit"
                            :dataSource="categories" foreignKeyValue='name' foreignKeyField='id'
                            :filter='filterCategories' />
                  <e-column field="photos" headerText="Zdjęcia" width="200" :template="'imageTemplate'"
                            :allowFiltering="false" />
                  <e-column field="location.id" headerText="Lokalizacja (magazyn)" width="150" editType="dropdownedit"
                            :dataSource="locations" foreignKeyValue='short_name' foreignKeyField='id'
                            :filter='filterLocations' />
                  <e-column field="available_items.quantity" headerText="Ilość/Stany magazynowe" width="150"
                            format="N2" :filter="filterDefault" />
                  <e-column field="suppliers_short_names" headerText="Dostawca" width="150" editType="string"
                            :filter='filterSuppliers' />
                </e-columns>

                <template v-slot:linkTemplate="{data}">
                  <a class="link link-primary" target="_blank" rel="noopener noreferrer" :href=data.link>{{
                      data.link
                    }}</a>
                </template>

              </ejs-grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Modal} from "bootstrap";
import {DataManager, Predicate, Query, WebMethodAdaptor} from "@syncfusion/ej2-data";
import {usePage} from "@inertiajs/vue3";
import {TextBox} from '@syncfusion/ej2-inputs';
import {
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  ContextMenu,
  ExcelExport,
  Filter,
  ForeignKey,
  GridComponent,
  Page,
  Reorder,
  Resize,
  Sort,
  Toolbar
} from '@syncfusion/ej2-vue-grids';
import {AutoComplete} from "@syncfusion/ej2-vue-dropdowns";
import {createElement} from "@syncfusion/ej2-base";
import {DropDownList} from "@syncfusion/ej2-dropdowns";

let dropInstanceFilterUnits;

export default {
  components: {
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    'e-column': ColumnDirective
  },

  provide: {
    grid: [Page, ContextMenu, Toolbar, Sort, Filter, Reorder, Resize, ColumnChooser, ExcelExport, ForeignKey]
  },


  props: {
    articleToChange: Object,
    units: Object,
    suppliers: Object,
    producers: Object,
    categories: Object,
    locations: Object
  },


  mounted() {
    this.modalObj = new Modal(this.$refs.modalEle);
  },

  data() {
    return {
      articles: [],
      modalObj: null,
      selectedArticle: null,
      toolbar: [
        {text: "Search", prefixIcon: 'e-search', id: "search", align: 'center'},
          {text: "Zapisz artykuł", prefixIcon: 'e-redo', id: 'change_article_btn', disabled: true}
      ],
      dataManager: new DataManager({
        url: route('articles.get'),
        adaptor: new WebMethodAdaptor(),
        crossDomain: true,
        headers: [
          {'X-CSRF-TOKEN': usePage().props.csrf_token}
        ]
      }),
      pageSettings: {pageSize: 50, pageCount: 5, pageSizes: [10, 20, 50, 100, 200, 500, 1000]},
      filterOptions: {type: "Menu"},
      filterDefault: {operator: 'contains', params: {minLength: 2}},
      filterUnits: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.units,
              fields: {text: 'name', value: 'id'},
              placeholder: 'Szukaj jednostki',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterCategories: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.categories,
              fields: {text: 'name', value: 'id'},
              placeholder: 'Szukaj kategorii',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },
      filterLocations: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.locations,
              fields: {text: 'short_name', value: 'id'},
              placeholder: 'Szukaj magazynu',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },
      filterProducers: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.producers,
              fields: {text: 'short_name', value: 'id'},
              placeholder: 'Szukaj Producenta',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },
      filterSuppliers: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.suppliers,
              fields: {text: 'short_name', value: 'short_name'},
              placeholder: 'Szukaj dostawców',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },
    };
  },

  methods: {
    onRowDataBound(args) {
      if (args.data.deleted_at) {  // Sprawdź, czy rekord został "miękko" usunięty
        args.row.classList.add('deleted-row');
      }
    },

    rowSelected(args) {
      this.selectedArticle = args.data;
      if (this.selectedArticle)
        this.$refs.articlesGrid.ej2Instances.toolbarModule.enableItems(['change_article_btn'], true);
    },

    actionBegin(args) {
      //
      // WYSZUKIWARKA- wyszukiwanie wielu wartości z separatorem (przecinek
      //
      if (args.requestType === 'searching') {
        const keys = args.searchString.split(',');
        let flag = true;
        let predicate;

        if (this.$refs.articlesGrid.ej2Instances.searchSettings.key !== '') {
          this.values = args.searchString;
          keys.forEach((key) => {
            this.$refs.articlesGrid.ej2Instances.getColumns().forEach((col) => {
              if (flag) {
                predicate = new Predicate(col.field, 'contains', key, true);
                flag = false;
              } else {
                let predic = new Predicate(col.field, 'contains', key, true);
                predicate = predicate.or(predic);
              }
            });
          });

          this.$refs.articlesGrid.ej2Instances.query = new Query().where(predicate);
          this.$refs.articlesGrid.ej2Instances.searchSettings.key = '';
          this.refresh = true;
          this.valueAssign = true;
          this.removeQuery = true;
          this.$refs.articlesGrid.ej2Instances.refresh();
        } else {
          this.$refs.articlesGrid.ej2Instances.searchSettings.key = '';
          this.refresh = true;
          this.valueAssign = true;
          this.removeQuery = true;
          this.$refs.articlesGrid.ej2Instances.refresh();
        }
      }
    },

    actionComplete(args) {
      //
      // WYSZUKIWARKA - wyszukiwanie wielu wartości z separatorem (przecinek
      //
      if (args.requestType === "refresh" && this.valueAssign) {
        document.getElementById(
            this.$refs.articlesGrid.ej2Instances.element.id + "_searchbar"
        ).value = this.values;
        this.valueAssign = false;
      } else if (
          document.getElementById(
              this.$refs.articlesGrid.ej2Instances.element.id + "_searchbar"
          ).value === "" &&
          args.requestType === "refresh" &&
          this.removeQuery
      ) {
        this.$refs.articlesGrid.ej2Instances.query = new Query();
        this.removeQuery = false;
        this.$refs.articlesGrid.ej2Instances.refresh();
      }
    },

    toolbarClick(args) {
      if (args.item.id === 'change_article_btn') {
        this.$emit("article-selected", this.selectedArticle);
        this.hide();
      }
    },

    show() {
      if (this.modalObj) {
        this.modalObj.show();
      }
    },
    hide() {
      if (this.modalObj) {
        this.modalObj.hide();
      }
    },

  },
};
</script>
