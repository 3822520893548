<template>
  <form @submit.prevent="checkForm(event, form)">
    <div class="row">
      <div class="col-ms-12">
        <div id="introductionContainer">
          <label class="form-label me-1" for="introduction">Wstęp:</label>
          <button type="button" class="btn btn-xs btn-outline-primary mb-1" @click="toggleIntroductionVis()">
            Pokaż/ukryj
          </button>
          <div v-if="errors.offer_introduction" class="invalid-feedback">{{ errors.introduction }}</div>
          <div id="editorIntroductionContainer" :class="{ 'border-red': errors.introduction }"
               v-bind:class="{'d-none':hideIntroductionEditor}">
            <textarea id="editorIntroduction" ref="editorIntroduction">{{ form.offer_introduction }}</textarea>
          </div>
        </div>

        <div id="descriptionContainer">
          <label class="form-label me-1" for="description">Opis:</label>
          <button type="button" class="btn btn-xs btn-outline-primary mb-1" @click="toggleDescriptionVis()">
            Pokaż/ukryj
          </button>
          <div v-if="errors.offer_description" class="invalid-feedback">{{ errors.description }}</div>
          <div id="editorContainer" ref="editorContainer" :class="{ 'border-red': errors.description }"
               v-bind:class="{'d-none':hideDescriptionEditor}">
            <textarea id="editorDescription" ref="editorDescription">
              {{ form.offer_description }}
            </textarea>
          </div>
        </div>

        <div id="priceDetailsContainer">
          <label class="form-label me-1" for="offer_price_details">Robicie cenowe:</label>
          <button type="button" class="btn btn-xs btn-outline-primary mb-1" @click="togglePriceDetailsVis()">
            Pokaż/ukryj
          </button>
          <div v-if="errors.offer_price_details" class="invalid-feedback">{{ errors.offer_price_details }}</div>
          <div id="editorContainer" ref="editorContainer" :class="{ 'border-red': errors.offer_price_details }"
               v-bind:class="{'d-none':hidePriceDetailsEditor}">
            <textarea id="editorPriceDetails" ref="editorPriceDetails">
              {{ form.offer_price_details }}
            </textarea>
          </div>
        </div>

        <div id="epilogueContainer">
          <label class="form-label me-1" for="epilogue">Zakończenie:</label>
          <button type="button" class="btn btn-xs btn-outline-primary mb-1" @click="toggleEpilogueVis()">Pokaż/ukryj
          </button>
          <div v-if="errors.offer_epilogue" class="invalid-feedback">{{ errors.epilogue }}</div>
          <div id="editorEpilogueContainer" ref="editorEpilogueContainer" :class="{ 'border-red': errors.epilogue }"
               v-bind:class="{'d-none':hideEpilogueEditor}">
              <textarea id="editorEpilogue" ref="editorEpilogue">
                {{ form.offer_epilogue }}
              </textarea>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-6">
        <label class="form-label" for="warranty">Gwarancja (w miesiącach):</label>
        <div v-if="errors.warranty" class="invalid-feedback">{{ errors.warranty }}</div>
        <input class="form-control form-control-sm" type="number" id="warranty" v-model="form.offer_warranty"
               :class="{ 'is-invalid': errors.warranty }"/>

        <label class="form-label" for="warranty_description">Opis gwarancji:</label>
        <div v-if="errors.warranty_description" class="invalid-feedback">{{ errors.warrantyDescription }}</div>
        <textarea class="form-control form-control-sm" id="warranty_description"
                  v-model="form.offer_warranty_description"
                  :class="{ 'is-invalid': errors.warranty_description }"></textarea>

        <label class="form-label" for="service_response_hours">Czas reakcji serwisowej(w godzinach):</label>
        <div v-if="errors.service_response_hours" class="invalid-feedback">{{ errors.service_response_hours }}</div>
        <input class="form-control form-control-sm" type="number" id="service_response_hours"
               v-model="form.offer_service_response_hours" :class="{ 'is-invalid': errors.service_response_hours }"/>

        <label class="form-label" for="service_points">Punkty serwisowe:</label>
        <div v-if="errors.service_points" class="invalid-feedback">{{ errors.service_points }}</div>
        <textarea class="form-control form-control-sm" id="service_points" v-model="form.offer_service_points"
                  :class="{ 'is-invalid': errors.service_points }"/>

        <label class="form-label" for="technical_changes">Zmiany techniczne:</label>
        <div v-if="errors.technical_changes" class="invalid-feedback">{{ errors.technical_changes }}</div>
        <textarea class="form-control form-control-sm" id="technical_changes" v-model="form.offer_technical_changes"
                  :class="{ 'is-invalid': errors.technical_changes }"/>

        <label class="form-label" for="conceptual_changes">Zmiany koncepcji:</label>
        <div v-if="errors.conceptual_changes" class="invalid-feedback">{{ errors.conceptual_changes }}</div>
        <textarea class="form-control form-control-sm" id="conceptual_changes" v-model="form.offer_conceptual_changes"
                  :class="{ 'is-invalid': errors.conceptual_changes }"/>

        <label class="form-label" for="initial_FAT_acceptance">Odbiór wstępny FAT</label>
        <div v-if="errors.initial_FAT_acceptance" class="invalid-feedback">{{ errors.initial_FAT_acceptance }}</div>
        <textarea class="form-control form-control-sm" type="text" id="initial_FAT_acceptance"
                  v-model="form.offer_initial_FAT_acceptance"
                  :class="{ 'is-invalid': errors.initial_FAT_acceptance }"></textarea>

        <label class="form-label" for="preparation_for_shipping_machine">Przygotowanie maszyny do wysyłki:</label>
        <div v-if="errors.preparation_for_shipping_machine" class="invalid-feedback">
          {{ errors.preparation_for_shipping_machine }}
        </div>
        <textarea class="form-control form-control-sm" type="text" id="preparation_for_shipping_machine"
                  v-model="form.offer_preparation_for_shipping_machine"
                  :class="{ 'is-invalid': errors.preparation_for_shipping_machine }"></textarea>

        <label class="form-label" for="delivery_desc">Dostawa:</label>
        <div v-if="errors.delivery_desc" class="invalid-feedback">{{ errors.delivery_desc }}</div>
        <textarea class="form-control form-control-sm" type="text" id="delivery_desc" v-model="form.offer_delivery_desc"
                  :class="{ 'is-invalid': errors.delivery_desc }"></textarea>

      </div>


      <div class="col-sm-12 col-md-6">

        <label class="form-label" for="assembly">Montaż</label>
        <div v-if="errors.assembly" class="invalid-feedback">{{ errors.assembly }}</div>
        <textarea class="form-control form-control-sm" type="text" id="assembly" v-model="form.offer_assembly"
                  :class="{ 'is-invalid': errors.assembly }"></textarea>

        <label class="form-label" for="completion_time_comment">Termin realizacji - komentarz:</label>
        <div v-if="errors.completion_time_comment" class="invalid-feedback">{{ errors.completion_time_comment }}</div>
        <textarea class="form-control form-control-sm" type="text" id="completion_time_comment" v-model="form.offer_completion_time_comment"
                  :class="{ 'is-invalid': errors.completion_time_comment }"></textarea>

        <label class="form-label" for="launch">Uruchomienie</label>
        <div v-if="errors.launch" class="invalid-feedback">{{ errors.launch }}</div>
        <textarea class="form-control form-control-sm" type="text" id="launch" v-model="form.offer_launch"
                  :class="{ 'is-invalid': errors.launch }"></textarea>

        <label class="form-label" for="support">Szkolenie / Wsparcie:</label>
        <div v-if="errors.support" class="invalid-feedback">{{ errors.support }}</div>
        <textarea class="form-control form-control-sm" type="text" id="support" v-model="form.offer_support"
                  :class="{ 'is-invalid': errors.support }"></textarea>

        <label class="form-label" for="final_SAT_acceptance">Odbiór końcowy SAT:</label>
        <div v-if="errors.final_SAT_acceptance" class="invalid-feedback">{{ errors.final_SAT_acceptance }}</div>
        <textarea class="form-control form-control-sm" type="text" id="final_SAT_acceptance"
                  v-model="form.offer_final_SAT_acceptance"
                  :class="{ 'is-invalid': errors.final_SAT_acceptance }"></textarea>

        <label class="form-label" for="documentation">Dokumentacja:</label>
        <div v-if="errors.documentation" class="invalid-feedback">{{ errors.documentation }}</div>
        <textarea class="form-control form-control-sm" type="text" id="documentation" v-model="form.offer_documentation"
                  :class="{ 'is-invalid': errors.documentation }"></textarea>

        <label class="form-label" for="ce_certification">Oznakowanie CE:</label>
        <div v-if="errors.ce_certification" class="invalid-feedback">{{ errors.ce_certification }}</div>
        <textarea class="form-control form-control-sm" type="text" id="ce_certification"
                  v-model="form.offer_ce_certification" :class="{ 'is-invalid': errors.ce_certification }"></textarea>

        <label class="form-label" for="force_majeure">Siła wyższa:</label>
        <div v-if="errors.force_majeure" class="invalid-feedback">{{ errors.force_majeure }}</div>
        <textarea class="form-control form-control-sm" id="force_majeure" v-model="form.offer_force_majeure"
                  :class="{ 'is-invalid': errors.force_majeure }"/>

        <label class="form-label" for="term_of_payment">Warunki płatności:</label>
        <div v-if="errors.term_of_payment" class="invalid-feedback">{{ errors.term_of_payment }}</div>
        <textarea class="form-control form-control-sm" id="term_of_payment" v-model="form.offer_term_of_payment"
                  :class="{ 'is-invalid': errors.term_of_payment }"></textarea>


        <label class="form-label" for="currency">Waluta:</label>
        <div v-if="errors.currency" class="invalid-feedback">{{ errors.currency }}</div>
        <select class="form-select form-select-sm" id="currency" v-model="form.offer_currency"
                :class="{ 'is-invalid': errors.currency }">
          <option value="PLN">PLN</option>
          <option value="EUR">EUR</option>
          <option value="USD">USD</option>
        </select>

        <label class="form-label" for="term_of_payment">Uwagi:</label>
        <div v-if="errors.offer_comment" class="invalid-feedback">{{ errors.offer_comment }}</div>
        <input class="form-control form-control-sm" id="offer_comment" v-model="form.offer_comment"
               :class="{ 'is-invalid': errors.offer_comment }"/>

      </div>
    </div>

    <div class="row">
      <div class="col mt-4 text-end">
        <button id="saveTemplate" class="btn btn-primary mt-3" type="submit" :disabled="form.processing">
          <span v-if="form.processing"
                class="spinner-border text-white me-2 align-self-center loader-sm">Zapisuję</span>
          <span v-else>Zapisz</span>
        </button>
      </div>
    </div>

  </form>
</template>

<script>
import route from "../../../../../vendor/tightenco/ziggy/src/js/index.js";

import TinyMCEHelper from "/resources/library/js/tinymce/tinyMCEHelper.js";
import tinymce from "tinymce";

export default {
  props: {
    form: Object,
    offersTemplate: Object,
    errors: Object
  },
  data() {
    return {
      templateLang: 'pl',
      loading: false,
      hideIntroductionEditor: false,
      hideDescriptionEditor: false,
      hidePriceDetailsEditor: false,
      hideEpilogueEditor: false,
    };
  },


  beforeUnmount() {
    // usuń instancje, bo inaczej się nie załaduje ponownie
    tinymce.get('editorIntroduction').destroy();
    tinymce.get('editorDescription').destroy();
    tinymce.get('editorPriceDetails').destroy();
    tinymce.get('editorEpilogue').destroy();

    window.removeEventListener('keydown', this.modifySaveListener);
  },
  mounted() {
    this.initTinyMCE();
    this.$nextTick(() => {
      const self = this;
      const textarea = $('textarea');
      textarea.each(function (idx, event) {
        self.autoResize(event);
      })
    })

    window.addEventListener('keydown', this.modifySaveListener);
  },

  methods: {
    initTinyMCE() {
      TinyMCEHelper.initEditor('#editorIntroduction');
      TinyMCEHelper.initEditor('#editorDescription');
      TinyMCEHelper.initEditor('#editorPriceDetails');
      TinyMCEHelper.initEditor('#editorEpilogue');
    },
    checkForm(event, form) {
      form.offer_introduction = tinymce.get('editorIntroduction').getContent();
      form.offer_description = tinymce.get('editorDescription').getContent();
      form.offer_price_details = tinymce.get('editorPriceDetails').getContent();
      form.offer_epilogue = tinymce.get('editorEpilogue').getContent();

      form.post(route('offers.templates.update', {'lang': 'de'}))
    },
    toggleIntroductionVis() {
      this.hideIntroductionEditor = !this.hideIntroductionEditor;
    },
    toggleDescriptionVis() {
      this.hideDescriptionEditor = !this.hideDescriptionEditor;
    },
    togglePriceDetailsVis() {
      this.hidePriceDetailsEditor = !this.hidePriceDetailsEditor;
    },
    toggleEpilogueVis() {
      this.hideEpilogueEditor = !this.hideEpilogueEditor;
    },
    autoResize(textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = (textarea.scrollHeight) + 10 + 'px';
    },
    modifySaveListener(event) {
      if (event.ctrlKey && event.key === 's') {
        event.preventDefault();
        document.getElementById('saveTemplate').click();
      }
    },
  }
}
</script>
