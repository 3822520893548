<template>
  <AppLayout>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-six offer-card">
        <div class="widget-heading">
          <div class="row">
            <div class="col-xl-12 col-md-12 col-sm-12 col-12 d-inline-flex align-items-center">
            </div>
          </div>
        </div>

        <div class="widget-content">

          <div v-if="$page.props.errors">
            <ul style="list-style-type: none; padding: 0" v-for="error in $page.props.errors">
              <li class="alert alert-danger">{{ error }}</li>
            </ul>
          </div>

          <div class="float-end">
            <small>Wybierz szablon</small>
            <select class="form-select form-select-sm" id="selectLang" v-model="currentLanguage"
                    @change="changeLanguage(currentLanguage)">
              <option value="pl">PL</option>
              <option value="en">EN</option>
              <option value="de">DE</option>
            </select>
          </div>
          <h4 class="me-3 my-0 mb-4">Tworzenie nowej oferty</h4>

          <form @submit.prevent="submitOffer" enctype="multipart/form-data">

            <div class="row mb-3">
              <div class="col-ms-12 col-md-6 ps-0">
                <label class="form-label" for="title">Tytuł:</label>
                <div v-if="errors.title" class="invalid-feedback">{{ errors.title }}</div>
                <input class="form-control form-control-sm" id="title" v-model="form.title" @change="updateTitle"
                       :class="{ 'is-invalid': errors.title }"/>
              </div>
              <div class="col-ms-12 col-md-6">
                <label class="form-label" for="contact_persons">Osoby kontaktowe: </label>
                <Vueform>
                  <TagsElement id="contact_persons"
                               name="contact_persons"
                               autocomplete="new-password"
                               :object="true"
                               :search="true"
                               :native="false"
                               :items="users"
                               v-model="contact_persons"
                               :default="contact_persons"
                               @change="changeContactPersons"
                  ></TagsElement>
                </Vueform>
              </div>
            </div>

            <div class="row">
              <div class="col-ms-12 col-md-6">
                <label class="form-label" for="customer_id">Klient: </label>
                <template v-if="loading">
                  <div>
                    <div class="spinner-grow text-danger me-2 align-self-center loader-sm "></div>
                  </div>
                </template>
                <template v-else>
                  <Vueform>
                    <SelectElement id="customer_id" :object="true" :native="false" :search="true"
                                   @change="fetchCustomerContacts" label-prop="name" value-prop="id"
                                   name="customer_id" v-model="nform.customer_id"
                                   autocomplete="off" :items="customers">
                    </SelectElement>
                  </Vueform>

                  <OfferCustomerContactSelectedAvailable
                      v-if="loadedCustomerContacts && customers"
                      :customerContacts="loadedCustomerContacts"
                      :offerCustomerContacts="selectedCustomers"
                      :key="loadedCustomerContacts" @updateOfferCustomerContacts="handleUpdateOfferCustomerContacts">
                  </OfferCustomerContactSelectedAvailable>

                  <CreateEditCustomer v-if="Object.keys(customers).length > 0" :customers="customers"
                                      :chosenCustomer="chosenCustomer"
                                      @refreshCustomerList="handleRefreshCustomerList"></CreateEditCustomer>
                </template>
              </div>
              <div class="col-ms-12 col-md-6"></div>
            </div>

            <RichTextEditorComponent class="mt-2"
                                     id="editorIntroduction"
                                     label="Wstęp"
                                     modelName="introduction"
                                     :modelText="form.introduction"
                                     :hidden="true"
                                     :language="currentLanguage"
                                     @updateModel="updateModel">
            </RichTextEditorComponent>

            <RichTextEditorComponent class="mt-2"
                                     id="editorDescription"
                                     label="Opis"
                                     modelName="description"
                                     :modelText="form.description"
                                     :hidden="false"
                                     :language="currentLanguage"
                                     @updateModel="updateModel">
            </RichTextEditorComponent>

            <RichTextEditorComponent class="mt-2"
                                     id="editorTechnicalData"
                                     label="Dane techniczne"
                                     modelName="technical_data"
                                     :modelText="form.technical_data"
                                     :hidden="false"
                                     :language="currentLanguage"
                                     @updateModel="updateModel">
            </RichTextEditorComponent>

            <RichTextEditorComponent class="mt-2"
                                     id="editorPriceDetails"
                                     label="Rozbicie cenowe"
                                     modelName="price_details"
                                     :modelText="form.price_details"
                                     :hidden="false"
                                     :language="currentLanguage"
                                     @updateModel="updateModel">
            </RichTextEditorComponent>

            <RichTextEditorComponent class="mt-2"
                                     id="editorEpilogue"
                                     label="Zakończenie"
                                     modelName="epilogue"
                                     :modelText="form.epilogue"
                                     :hidden="true"
                                     :language="currentLanguage"
                                     @updateModel="updateModel">
            </RichTextEditorComponent>

            <div class="row">
              <div class="col-sm-12 col-md-6">

                <InputComponent class="mt-2"
                                type="number"
                                :attributes="{}"
                                label="Gwarancja (w miesiącach)"
                                modelName="warranty"
                                :modelText="form.warranty"
                                :language="currentLanguage"
                                :hidden="false"
                                @updateModel="updateModel">
                </InputComponent>

                <InputComponent class="mt-2"
                                type="number"
                                :attributes="{}"
                                label="Gwarancja+"
                                modelName="warranty_plus"
                                :modelText="form.warranty_plus"
                                :language="currentLanguage"
                                :hidden="false"
                                @updateModel="updateModel">
                </InputComponent>

                <TextAreaComponent class="mt-2"
                                   :attributes="{}"
                                   label="Opis gwarancji"
                                   modelName="warranty_description"
                                   :modelText="form.warranty_description"
                                   :language="currentLanguage"
                                   :hidden="false"
                                   :info="['Tag [Gwarancja] - wartość pola \'Gwarancja\' przepisuje się do \'Opisu Gwarancji\''
                                 ,'Tag [GwarancjaPlus] - j.w.']"
                                   @updateModel="updateModel">
                </TextAreaComponent>

                <InputComponent class="mt-2"
                                type="number"
                                :attributes="{}"
                                label="Czas reakcji serwisowej(w godzinach)"
                                modelName="service_response_hours"
                                :modelText="form.service_response_hours"
                                :language="currentLanguage"
                                :hidden="true"
                                @updateModel="updateModel">
                </InputComponent>

                <TextAreaComponent class="mt-2"
                                   :attributes="{}"
                                   label="Punkty serwisowe"
                                   modelName="service_points"
                                   :modelText="form.service_points"
                                   :language="currentLanguage"
                                   :hidden="true"
                                   @updateModel="updateModel">
                </TextAreaComponent>

                <TextAreaComponent class="mt-2"
                                   :attributes="{}"
                                   label="Zmiany techniczne"
                                   modelName="technical_changes"
                                   :modelText="form.technical_changes"
                                   :language="currentLanguage"
                                   :hidden="true"
                                   @updateModel="updateModel">
                </TextAreaComponent>

                <TextAreaComponent class="mt-2"
                                   :attributes="{}"
                                   label="Zmiany koncepcji"
                                   modelName="conceptual_changes"
                                   :modelText="form.conceptual_changes"
                                   :language="currentLanguage"
                                   :hidden="true"
                                   @updateModel="updateModel">
                </TextAreaComponent>

                <TextAreaComponent class="mt-2"
                                   :attributes="{}"
                                   label="Siła wyższa"
                                   modelName="force_majeure"
                                   :modelText="form.force_majeure"
                                   :language="currentLanguage"
                                   :hidden="true"
                                   @updateModel="updateModel">
                </TextAreaComponent>

                <TextAreaComponent class="mt-2"
                                   :attributes="{}"
                                   label="Odbiór wstępny FAT"
                                   modelName="initial_FAT_acceptance"
                                   :modelText="form.initial_FAT_acceptance"
                                   :language="currentLanguage"
                                   :hidden="true"
                                   @updateModel="updateModel">
                </TextAreaComponent>

                <TextAreaComponent class="mt-2"
                                   :attributes="{}"
                                   label="Przygotowanie maszyny do wysyłki"
                                   modelName="preparation_for_shipping_machine"
                                   :modelText="form.preparation_for_shipping_machine"
                                   :language="currentLanguage"
                                   :hidden="true"
                                   @updateModel="updateModel">
                </TextAreaComponent>

                <TextAreaComponent class="mt-2"
                                   :attributes="{}"
                                   label="Dostawa"
                                   modelName="delivery_desc"
                                   :modelText="form.delivery_desc"
                                   :language="currentLanguage"
                                   :hidden="true"
                                   @updateModel="updateModel">
                </TextAreaComponent>

                <TextAreaComponent class="mt-2"
                                   :attributes="{}"
                                   label="Montaż"
                                   modelName="assembly"
                                   :modelText="form.assembly"
                                   :language="currentLanguage"
                                   :hidden="true"
                                   @updateModel="updateModel">
                </TextAreaComponent>

                <label class="form-label">Termin realizacji:</label>
                <div class="d-block pt-2">
                  <label class="w-100 switch s-icons s-outline s-outline-success"
                         style="padding-left: 56px; padding-top: 2px;" for="completTimStarFroContSub">
                    <input type="checkbox" id="completTimStarFroContSub" autocomplete="off"
                           name="completion_time_start_from_contract_submission"
                           v-model="form.completion_time_start_from_contract_submission"/>
                    <span class="slider round"></span> Liczony od przesłania umowy </label>
                </div>

                <div class="d-inline-flex" v-if="!form.completion_time_start_from_contract_submission">
                  <div class="btn-group" role="group" aria-label="Termin realizacji">
                    <input type="radio" class="btn-check" name="completion_time_format" value="cw" id="ct_cw"
                           autocomplete="off" @change="changeCompletionTimeFormat"
                           v-model="form.completion_time_format"
                           :checked="form.completion_time_format === 'cw'">
                    <label class="btn btn-outline-primary" for="ct_cw">Tydzień roku</label>

                    <input type="radio" class="btn-check" name="completion_time_format" value="" id="ct_date"
                           autocomplete="off" @change="changeCompletionTimeFormat"
                           v-model="form.completion_time_format"
                           :checked="form.completion_time_format === null">
                    <label class="btn btn-outline-primary" for="ct_date">Data</label>
                  </div>
                </div>

                <div class="d-inline-flex ms-2" v-if="!form.completion_time_start_from_contract_submission">
                  <div class="input-group" v-if="form.completion_time_format === 'cw'">
                    <div class="input-group-text" id="btnGroupAddon">CW</div>
                    <input type="text" class="form-control form-control-sm" style="min-width: 150px; max-width: 200px"
                           placeholder="Tydzień roku" aria-label="Tydzień roku" aria-describedby="btnGroupAddon"
                           v-model="form.completion_time">
                  </div>
                  <div class="input-group" v-else>
                    <input type="date" class="form-control form-control-sm" style="min-width: 150px;max-width: 200px"
                           aria-label="Data" aria-describedby="btnGroupAddon" v-model="form.completion_time">
                  </div>
                </div>

                <div class="d-inline-flex ms-2" v-if="form.completion_time_start_from_contract_submission">

                  <div class="btn-group" role="group" aria-label="Termin realizacji">
                    <input type="radio" class="btn-check" name="completion_time_format" value="months"
                           @updateModel="updateModel" id="completion_time_unit_months" autocomplete="off"
                           v-model="form.completion_time_unit"
                           :checked="form.completion_time_unit === 'months'">
                    <label class="btn btn-outline-primary" for="completion_time_unit_months">Miesiące</label>

                    <input type="radio" class="btn-check" name="completion_time_format" value="weeks"
                           @updateModel="updateModel" id="completion_time_unit_weeks" autocomplete="off"
                           v-model="form.completion_time_unit"
                           :checked="form.completion_time_unit === 'weeks'">
                    <label class="btn btn-outline-primary" for="completion_time_unit_weeks">Tygodnie</label>
                  </div>

                  <input type="number" step="1" min="1" max="999" class="ms-2 form-control form-control-sm"
                         style="min-width: 250px; height: 40px" placeholder="od przesłania oferty"
                         v-model="form.completion_time" aria-label="Tydzień roku"
                         aria-describedby="btnGroupAddon">
                </div>

                <TextAreaComponent class="mt-2"
                                   :attributes="{}"
                                   label="Komentarz"
                                   modelName="completion_time_comment"
                                   :modelText="form.completion_time_comment"
                                   :language="currentLanguage"
                                   :hidden="false"
                                   @updateModel="updateModel">
                </TextAreaComponent>
              </div>

              <div class="col-sm-12 col-md-6">
                <TextAreaComponent class="mt-2"
                                   :attributes="{}"
                                   label="Uruchomienie"
                                   modelName="launch"
                                   :modelText="form.launch"
                                   :language="currentLanguage"
                                   :hidden="true"
                                   @updateModel="updateModel">
                </TextAreaComponent>

                <TextAreaComponent class="mt-2"
                                   :attributes="{}"
                                   label="Szkolenie / Wsparcie"
                                   modelName="support"
                                   :modelText="form.support"
                                   :language="currentLanguage"
                                   :hidden="true"
                                   @updateModel="updateModel">
                </TextAreaComponent>

                <TextAreaComponent class="mt-2"
                                   :attributes="{}"
                                   label="Odbiór końcowy SAT"
                                   modelName="final_SAT_acceptance"
                                   :modelText="form.final_SAT_acceptance"
                                   :language="currentLanguage"
                                   :hidden="true"
                                   @updateModel="updateModel">
                </TextAreaComponent>

                <TextAreaComponent class="mt-2"
                                   :attributes="{}"
                                   label="Dokumentacja"
                                   modelName="documentation"
                                   :modelText="form.documentation"
                                   :language="currentLanguage"
                                   :hidden="true"
                                   @updateModel="updateModel">
                </TextAreaComponent>

                <TextAreaComponent class="mt-2"
                                   :attributes="{}"
                                   label="Oznakowanie CE"
                                   modelName="ce_certification"
                                   :modelText="form.ce_certification"
                                   :language="currentLanguage"
                                   :hidden="true"
                                   @updateModel="updateModel">
                </TextAreaComponent>

                <TextAreaComponent class="mt-2"
                                   :attributes="{}"
                                   label="Dostawa"
                                   modelName="delivery"
                                   :modelText="form.delivery"
                                   :language="currentLanguage"
                                   :hidden="false"
                                   @updateModel="updateModel">
                </TextAreaComponent>

                <TextAreaComponent class="mt-2"
                                   :attributes="{}"
                                   label="Warunki płatności"
                                   modelName="term_of_payment"
                                   :modelText="form.term_of_payment"
                                   :language="currentLanguage"
                                   :hidden="false"
                                   @updateModel="updateModel">
                </TextAreaComponent>

                <InputComponent class="mt-2"
                                type="text"
                                :attributes="{}"
                                label="Kod pocztowy dostawy"
                                modelName="zip_code_delivery"
                                :modelText="form.zip_code_delivery"
                                :language="currentLanguage"
                                :hidden="false"
                                @updateModel="updateModel">
                </InputComponent>

                <InputComponent class="mt-2"
                                type="text"
                                :attributes="{}"
                                label="Miasto dostawy"
                                modelName="city_delivery"
                                :modelText="form.city_delivery"
                                :language="currentLanguage"
                                :hidden="false"
                                @updateModel="updateModel">
                </InputComponent>

                <InputComponent class="mt-2"
                                type="text"
                                :attributes="{}"
                                label="Adres dostawy (ulica i numer budynku)"
                                modelName="street_delivery"
                                :modelText="form.street_delivery"
                                :language="currentLanguage"
                                :hidden="false"
                                @updateModel="updateModel">
                </InputComponent>

                <InputComponent class="mt-2"
                                type="text"
                                :attributes="{}"
                                label="Numer lokalu dostawy"
                                modelName="apartment_delivery"
                                :modelText="form.apartment_delivery"
                                :language="currentLanguage"
                                :hidden="false"
                                @updateModel="updateModel">
                </InputComponent>

                <InputComponent class="mt-2"
                                type="number"
                                :attributes="{min:0, step:0.01}"
                                label="Cena (netto)"
                                modelName="price"
                                :modelText="form.price"
                                :language="currentLanguage"
                                :hidden="false"
                                @updateModel="updateModel">
                </InputComponent>

                <SelectComponent class="mt-2"
                                 :attributes="{}"
                                 label="Waluta"
                                 modelName="currency"
                                 :modelText="form.currency"
                                 :language="currentLanguage"
                                 :hidden="false"
                                 :options="[{'PLN': 'PLN'}, {'EUR': 'EUR'}, {'USD': 'USD'}]"
                                 @updateModel="updateModel">
                </SelectComponent>

                <TextAreaComponent class="mt-2"
                                   :attributes="{}"
                                   label="Uwagi"
                                   modelName="comments"
                                   :modelText="form.comments"
                                   :language="currentLanguage"
                                   :hidden="true"
                                   @updateModel="updateModel">
                </TextAreaComponent>

                <InputDateComponent class="mt-2"
                                type="date"
                                :attributes="{}"
                                label="Data ważności oferty"
                                modelName="expiration_date"
                                :modelText="form.expiration_date"
                                :language="currentLanguage"
                                :hidden="false"
                                @updateModel="updateModel">
                </InputDateComponent>

                <SelectComponent class="mt-2"
                                 :attributes="{}"
                                 label="Status"
                                 modelName="status_id"
                                 :modelText="statuses.status_id"
                                 :language="currentLanguage"
                                 :hidden="false"
                                 :options="computedStatuses"
                                 @updateModel="updateModel">
                </SelectComponent>

                <div class="row mt-4 align-items-center">
                  <div class="col-auto">
                    <label class="form-label" for="status">Aktywna wersja:</label>
                  </div>
                  <div class="col-auto">
                    <label class="switch s-icons s-outline s-outline-success mb-3 h-100">
                      <input type="checkbox" v-model="form.is_active">
                      <span class="slider round"></span> </label>
                  </div>
                </div>

              </div>
            </div>


            <div class="mt-5 pt-3 mb-4" style="border-top: 1px solid #d0d0d0;">
              <h4>Pliki oferty</h4>
            </div>

            <div class="row">
              <div class="col-md-4 col-lg col-xl pb-3">
                <label class="form-label" for="file_materials_for_offer">Materiały do oferty</label>
                <Vueform>
                  <MultifileElement :drop="true"
                                    ref="file_materials_for_offer"
                                    :sort="false"
                                    :default="offerVersionFiles.file_materials_for_offer"
                                    :upload-temp-endpoint="uploadTempFile"
                                    :remove-temp-endpoint="removeFile"
                                    :submit="false"
                                    name="files[file_materials_for_offer]"
                  />
                </Vueform>
              </div>

              <div class="col-md-4 col-lg col-xl pb-3">
                <label class="form-label" for="file_client_request">Zapytanie klienta</label>
                <Vueform>
                  <MultifileElement :drop="true"
                                    ref="file_client_request"
                                    :sort="false"
                                    :default="offerVersionFiles.file_client_request"
                                    :upload-temp-endpoint="uploadTempFile"
                                    :remove-temp-endpoint="removeFile"
                                    :submit="false"
                                    name="files[file_client_request]"
                  />
                </Vueform>
              </div>

              <div class="col-md-4 col-lg col-xl pb-3">
                <label class="form-label" for="file_estimate">Kosztorys</label>
                <Vueform>
                  <MultifileElement :drop="true"
                                    ref="file_estimate"
                                    :sort="false"
                                    :default="offerVersionFiles.file_estimate"
                                    :upload-temp-endpoint="uploadTempFile"
                                    :remove-temp-endpoint="removeFile"
                                    :submit="false"
                                    name="files[file_estimate]"
                  />
                </Vueform>
              </div>

              <div class="col-md-4 col-lg col-xl pb-3">
                <label class="form-label" for="file_contract">Umowa</label>
                <Vueform>
                  <MultifileElement :drop="true"
                                    ref="file_contract"
                                    :sort="false"
                                    :default="offerVersionFiles.file_contract"
                                    :upload-temp-endpoint="uploadTempFile"
                                    :remove-temp-endpoint="removeFile"
                                    :submit="false"
                                    name="files[file_contract]"
                  />
                </Vueform>
              </div>

              <div class="col-md-6 col-lg col-xl pb-3">
                <label class="form-label" for="file_others">Inne</label>
                <Vueform>
                  <MultifileElement :drop="true"
                                    ref="file_others"
                                    :sort="false"
                                    :default="offerVersionFiles.file_others"
                                    :upload-temp-endpoint="uploadTempFile"
                                    :remove-temp-endpoint="removeFile"
                                    :submit="false"
                                    name="files[file_others]"
                  />
                </Vueform>
              </div>
            </div>

            <hr>

            <div class="row">
              <div class="col mt-4">
                <offerPdfPreviewGenerator class="me-3" :formData="form" :contact_persons="contact_persons"
                                          aria-action="offerPdf.preview"/>
              </div>
              <div class="col mt-4 text-end">
                <button id="saveOfferBtn" class="btn btn-primary mt-3 show-span margin-right-btn" type="submit"
                        :class="{ 'floating-button': floatingButtonVisible }"
                        :disabled="submitingForm || loading">
                  <span v-if="submitingForm"
                        class="spinner-border text-white me-2 align-self-center loader-sm">Zapisuję</span>
                  <span v-else>Zapisz</span>
                </button>
                </div>
            </div>

          </form>


        </div>
      </div>
    </div>
  </AppLayout>
</template>


<script>

import {Link, useForm, usePage} from "@inertiajs/vue3";
import {onMounted, reactive, ref} from "vue";
import AppLayout from "@/Layouts/App.vue";

import CreateEditCustomer from "@/Pages/Modals/CreateEditCustomer.vue"

import offerPdfPreviewGenerator from "@/Components/OfferPdfPreviewGenerator.vue";
import offerPdfDownloadGenerator from "@/Components/OfferPdfDownloadGenerator.vue";
import OfferCustomerContactSelectedAvailable from "@/Pages/Offers/offerCustomerContactSelectedAvailable.vue";

import 'select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.min.css';
import 'select2/dist/css/select2.min.css';
import RichTextEditorComponent from "@/Pages/Offers/form-elements/RichTextEditorComponent.vue";
import InputComponent from "@/Pages/Offers/form-elements/InputComponent.vue";
import TextAreaComponent from "@/Pages/Offers/form-elements/TextAreaComponent.vue";
import SelectComponent from "@/Pages/Offers/form-elements/SelectComponent.vue";
import tinymce from "tinymce";
import InputDateComponent from "./form-elements/InputDateComponent.vue";

export default {
  components: {
    InputDateComponent,
    CreateEditCustomer,
    SelectComponent,
    TextAreaComponent,
    InputComponent,
    RichTextEditorComponent,
    AppLayout,
    Link,
    offerPdfPreviewGenerator,
    offerPdfDownloadGenerator,
    OfferCustomerContactSelectedAvailable
  },

  props: {
    files: Object,
    statuses: Object,
    offersTemplatePL: Object,
    offersTemplateEN: Object,
    offersTemplateDE: Object,
    users: Object,
    authUser: Object,
    errors: Object
  },

  created() {
    this.initialValues = {
      pl: {
        introduction: this.offersTemplatePL.offer_introduction,
        description: this.offersTemplatePL.offer_description,
        epilogue: this.offersTemplatePL.offer_epilogue,
        price_details: this.offersTemplatePL.offer_price_details,
        technical_data: this.offersTemplatePL.offer_technical_data,
        warranty: this.offersTemplatePL.offer_warranty,
        warranty_plus: this.offersTemplatePL.offer_warranty_plus,
        warranty_description: this.offersTemplatePL.offer_warranty_description,
        service_response_hours: this.offersTemplatePL.offer_service_response_hours,
        term_of_payment: this.offersTemplatePL.offer_term_of_payment,
        service_points: this.offersTemplatePL.offer_service_points,
        technical_changes: this.offersTemplatePL.offer_technical_changes,
        conceptual_changes: this.offersTemplatePL.offer_conceptual_changes,
        force_majeure: this.offersTemplatePL.offer_force_majeure,
        comments: this.offersTemplatePL.offer_comment,
        initial_FAT_acceptance: this.offersTemplatePL.offer_initial_FAT_acceptance,
        preparation_for_shipping_machine: this.offersTemplatePL.offer_preparation_for_shipping_machine,
        assembly: this.offersTemplatePL.offer_assembly,
        completion_time_comment: this.offersTemplatePL.completion_time_comment,
        launch: this.offersTemplatePL.offer_launch,
        support: this.offersTemplatePL.offer_support,
        final_SAT_acceptance: this.offersTemplatePL.offer_final_SAT_acceptance,
        documentation: this.offersTemplatePL.offer_documentation,
        ce_certification: this.offersTemplatePL.offer_ce_certification,
      },
      en: {
        introduction: this.offersTemplateEN.offer_introduction,
        description: this.offersTemplateEN.offer_description,
        epilogue: this.offersTemplateEN.offer_epilogue,
        price_details: this.offersTemplateEN.offer_price_details,
        technical_data: this.offersTemplateEN.offer_technical_data,
        warranty: this.offersTemplateEN.offer_warranty,
        warranty_plus: this.offersTemplateEN.offer_warranty_plus,
        warranty_description: this.offersTemplateEN.offer_warranty_description,
        service_response_hours: this.offersTemplateEN.offer_service_response_hours,
        term_of_payment: this.offersTemplateEN.offer_term_of_payment,
        service_points: this.offersTemplateEN.offer_service_points,
        technical_changes: this.offersTemplateEN.offer_technical_changes,
        conceptual_changes: this.offersTemplateEN.offer_conceptual_changes,
        force_majeure: this.offersTemplateEN.offer_force_majeure,
        comments: this.offersTemplateEN.offer_comment,
        initial_FAT_acceptance: this.offersTemplateEN.offer_initial_FAT_acceptance,
        preparation_for_shipping_machine: this.offersTemplateEN.offer_preparation_for_shipping_machine,
        assembly: this.offersTemplateEN.offer_assembly,
        completion_time_comment: this.offersTemplateEN.completion_time_comment,
        launch: this.offersTemplateEN.offer_launch,
        support: this.offersTemplateEN.offer_support,
        final_SAT_acceptance: this.offersTemplateEN.offer_final_SAT_acceptance,
        documentation: this.offersTemplateEN.offer_documentation,
        ce_certification: this.offersTemplateEN.offer_ce_certification,
      },
      de: {
        introduction: this.offersTemplateDE.offer_introduction,
        description: this.offersTemplateDE.offer_description,
        epilogue: this.offersTemplateDE.offer_epilogue,
        price_details: this.offersTemplateDE.offer_price_details,
        technical_data: this.offersTemplateDE.offer_technical_data,
        warranty: this.offersTemplateDE.offer_warranty,
        warranty_plus: this.offersTemplateDE.offer_warranty_plus,
        warranty_description: this.offersTemplateDE.offer_warranty_description,
        service_response_hours: this.offersTemplateDE.offer_service_response_hours,
        term_of_payment: this.offersTemplateDE.offer_term_of_payment,
        service_points: this.offersTemplateDE.offer_service_points,
        technical_changes: this.offersTemplateDE.offer_technical_changes,
        conceptual_changes: this.offersTemplateDE.offer_conceptual_changes,
        force_majeure: this.offersTemplateDE.offer_force_majeure,
        comments: this.offersTemplateDE.offer_comment,
        initial_FAT_acceptance: this.offersTemplateDE.offer_initial_FAT_acceptance,
        preparation_for_shipping_machine: this.offersTemplateDE.offer_preparation_for_shipping_machine,
        assembly: this.offersTemplateDE.offer_assembly,
        completion_time_comment: this.offersTemplateDE.completion_time_comment,
        launch: this.offersTemplateDE.offer_launch,
        support: this.offersTemplateDE.offer_support,
        final_SAT_acceptance: this.offersTemplateDE.offer_final_SAT_acceptance,
        documentation: this.offersTemplateDE.offer_documentation,
        ce_certification: this.offersTemplateDE.offer_ce_certification,
      }
    }

    // Ustawienie formularza po zainicjowaniu initialValues
    this.form = this.createFormForLanguage(this.currentLanguage);
  },

  data() {
    // ustaw pliki do offer
    let offerVersionFiles = {};
    if (this.files && Object.keys(this.files).length > 0) {
      offerVersionFiles = this.files;
    }

    let currentLanguage = 'pl';


    return {
      isProcessingLang: false,
      floatingButtonVisible: true,
      loading: false,
      saveButtonType: false,
      offerVersionFiles,
      initialValues: null,
      currentLanguage,
      customerContacts: ref(),
      form: null,
      nform: {
        customer_id: null,
        customer_contacts: [],
      },
      loadedCustomerContacts: [],
      selectedCustomers: [],
      submitingForm: false,
      customers: [],
      chosenCustomer: null,
      contact_persons: []
    }
  },

  mounted() {
    this.chooseLangTemplate();
    this.fetchCustomers();
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('keydown', this.saveOfferListener);
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('keydown', this.saveOfferListener);
  },

  computed: {
    computedStatuses() {
      let statuses = [];
      this.statuses.forEach(function (status, idx) {
        statuses.push({[status.id]: status.status});
      })
      return statuses;
    },
  },

  methods: {
    createFormForLanguage(language) {
      const self = this;

      return useForm({
        status_id: 1,
        expiration_date: this.addDaysToDate(),
        customer_contacts: [],
        title: document.querySelector('#title') ? document.querySelector('#title').value : null,
        price: document.querySelector('#price') ? document.querySelector('#price').value : null,
        currency: document.querySelector('#currency') ? document.querySelector('#currency').value : null,
        zip_code_delivery: document.querySelector('#zip_code_delivery') ? document.querySelector('#zip_code_delivery').value : null,
        city_delivery: document.querySelector('#city_delivery') ? document.querySelector('#city_delivery').value : null,
        street_delivery: document.querySelector('#street_delivery') ? document.querySelector('#street_delivery').value : null,
        apartment_delivery: document.querySelector('#apartment_delivery') ? document.querySelector('#apartment_delivery').value : null,
        completion_time: null,
        completion_time_format: 'cw',
        completion_time_start_from_contract_submission: false,
        ...self.initialValues[language],
      });
    },

    changeLanguage(language) {
      // zmień język, żeby podmienić początkowe wartości z templatki,
      // zaktualizuj wszystkie pola formularza
      this.form = this.createFormForLanguage(language);

      // zaktualizuj TinyMCE
      setTimeout(() => {
        if (this.form.introduction)
          tinymce.get('textarea_introduction').setContent(this.form.introduction);
        if (this.form.epilogue)
          tinymce.get('textarea_epilogue').setContent(this.form.epilogue);
        if (this.form.price_details)
          tinymce.get('textarea_price_details').setContent(this.form.price_details);
        if (this.form.description)
          tinymce.get('textarea_description').setContent(this.form.description);
        if (this.form.technical_data)
          tinymce.get('textarea_technical_data').setContent(this.form.technical_data);
      }, 100);

    },

    chooseLangTemplate() {
      const self = this;
      // zmień całą ofertę na nowy język
      Toast.fire({
        toast: false,
        position: 'center',
        icon: 'question',
        title: 'Wybierz język oferty',
        html: '',
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: false,
        showConfirmButton: true,
        confirmButtonText: 'Polski',
        confirmButtonColor: 'success',
        showDenyButton: true,
        denyButtonText: 'English',
        denyButtonColor: '#1abc9c',
        showCancelButton: true,
        cancelButtonText: 'Deutsch',
      }).then((result) => {
        if (result.isConfirmed) {
          this.changeLanguage(self.currentLanguage = 'pl')
        } else if (result.isDenied) {
          this.changeLanguage(self.currentLanguage = 'en')
        } else {
          this.changeLanguage(self.currentLanguage = 'de')
        }
      })
    },

    changeCompletionTimeFormat() {
      this.form.completion_time_format = event.target.value;
    },

    async fetchCustomers() {
      this.loading = true;
      const self = this;

      axios.get(route('get-customers-json')).then(response => {
        this.customers = response.data;
        this.loading = false;
        this.$nextTick(() => {
          self.fetchCustomerContacts(this.nform.customer);
        });
      }).catch(error => {
        console.log(error);
      })
    },

    async fetchCustomerContacts(customer) {
      if (customer) {
        this.nform.customer = customer;
        this.nform.customer_id = customer.id;
        this.chosenCustomer = customer;
      }

      this.nform.customer_contacts = [];
      this.selectedCustomers = [];

      if (this.nform.customer_id) {
        // Wybrano klienta, pobierz jego osoby kontaktowe
        let selectedCustomer = this.customers.find(customer => customer.id === this.nform.customer_id);
        this.customer_contacts = selectedCustomer.customers_contacts ?? [];
        this.loadedCustomerContacts = this.customer_contacts;

      } else {
        // Nie wybrano klienta, wyczyść opcje
        this.customer_contacts = [];
      }
    },

    async uploadTempFile(item, formData) {
      this.loading = true
      const self = this;
      let resp;

      await axios.post(route('upload-temp-file'), {file: item, path: formData.path},
          {headers: {'Content-Type': 'multipart/form-data',}}
      ).then(response => {
        const subFolder = response.data.subFolder;

        if (!self.offerVersionFiles[subFolder]) {
          this.$set(self.offerVersionFiles, subFolder, []);
        }

        self.offerVersionFiles[subFolder].push({
          path: response.data.path,
          subFolder: response.data.subFolder,
          tmp: response.data.tmp,
          originalName: response.data.originalName,
          isTemporary: response.data.isTemporary
        });

        resp = response.data;
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.loading = false;
      });

      return resp;
    },

    removeFile(item) {
      if (!this.offerVersionFiles[item.subFolder]) {
        return;
      }

      if (!item.subFolder) {
        return;
      }

      // usuń item listy plików w ofercie
      this.offerVersionFiles[item.subFolder] = this.offerVersionFiles[item.subFolder].filter((file) => file.tmp !== item.tmp);
    },

    async submitOffer() {
      this.loading = true;
      let self = this;

      let form = {...this.form, ...this.nform};

      // dodanie plików do formularza
      form.offerVersionFiles = this.offerVersionFiles;

      // przypisanie tinyMCE do zmiennych
      form.introduction = tinymce.get('textarea_introduction').getContent();
      form.description = tinymce.get('textarea_description').getContent();
      form.epilogue = tinymce.get('textarea_epilogue').getContent();
      form.price_details = tinymce.get('textarea_price_details').getContent();
      form.technical_data = tinymce.get('textarea_technical_data').getContent();
      form.language = $('#selectLang').val();

      form.contact_persons = this.contact_persons;

      // submit form
      axios.post(route('offers.store'), form).then(response => {
        this.$nextTick(() => {
          Toast.fire({
            position: 'top-end',
            toast: true,
            icon: response.data.icon,
            title: response.data.title,
            html: response.data.message,
            showClass: {popup: 'animate__animated animate__fadeInDown'},
            hideClass: {popup: 'animate__animated animate__fadeOutUp'},
            timer: 2500,
            timerProgressBar: true,
            showConfirmButton: false,
            showCloseButton: true
          });
        });

        setTimeout(() => {
          this.$makeClean();
          if (response.data.icon === 'success') {
            // takie dziwne przekierowanie, bo this.$inertia.visit nie chce działać...
            let offerId = response.data.offerId;
            window.location.href = '/offers/' + offerId;
          }
        }, 300)
      }).catch(function (error) {
        console.log(error);
        self.submitingForm = false;
        Toast.fire({
          position: 'top-end',
          toast: true,
          icon: error.response.data.icon,
          title: error.response.data.title,
          html: error.response.data.message,
          showClass: {popup: 'animate__animated animate__fadeInDown'},
          hideClass: {popup: 'animate__animated animate__fadeOutUp'},
          timer: 2500,
          timerProgressBar: true,
          showConfirmButton: false,
          showCloseButton: true
        });
      });
    },

    async ShouldActiveNewVersion() {
      return new Promise((resolve, reject) => {
        Toast.fire({
          toast: false,
          icon: 'warning',
          title: 'Czy aktywować nową wersję?',
          html: 'Nowa wersja będzie ustawiona jako aktywna.',
          showDenyButton: true,
          showClass: {popup: 'animate__animated animate__fadeInDown'},
          hideClass: {popup: 'animate__animated animate__fadeOutUp'},
          timer: false,
          position: 'center',
          showConfirmButton: true,
          confirmButtonText: 'Tak',
          denyButtonText: 'Nie',
        }).then((result) => {
          if (result.isConfirmed) {
            resolve(1);
          } else if (result.isDenied) {
            resolve(0);
          }
        })
      })
    },

    handleUpdateOfferCustomerContacts(updatedOfferCustomerContacts) {
      this.nform.customer_contacts = updatedOfferCustomerContacts;
    },

    updateModel({modelName, value}) {
      this.form[modelName] = value;
      this.$makeDirty();
    },

    updateTitle() {
      this.$makeDirty();
    },

    setClickedSubmitButton(buttonName) {
      this.saveButtonType = buttonName;
    },

    handleScroll() {
      // Sprawdź, czy strona została przewinięta do końca card
      this.offerCard = $('.offer-card').height();
      const scrollTop = $(window).scrollTop() + $(window).height();
      this.floatingButtonVisible = this.offerCard > scrollTop;
    },

    saveOfferListener(event) {
      if (event.ctrlKey && event.key === 's' && !this.submitingForm) {
        event.preventDefault();
        this.submitingForm = true;
        document.getElementById('saveOfferBtn').click();
      }
    },

    handleRefreshCustomerList() {
      this.fetchCustomers();
    },

    addDaysToDate(days = 30) {
      const today = new Date();
      today.setDate(today.getDate() + days);
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    },

    changeContactPersons(newValue) {
      this.contact_persons = newValue;
      this.$makeDirty();
    },

  },
}

</script>

<style scoped>
.show-span span {
  display: block;
}

.margin-right-btn {
  right: 25px;
  transition: none !important;
}
</style>