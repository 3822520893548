<template>
  <div v-show="article">

    <!-- Modal -->
    <div class="modal fade" id="editArticleModal" ref="editArticleModalEle" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
         aria-hidden="true">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title mb-3">Edytuj artykuł</h5>
            <button type="button" class="close ms-auto" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>

            <div class="position-absolute mt-5" v-if="exchangeRates">
              Kurs € na dzień <b>{{ exchangeRates.date }}</b>: <b class="text-primary">{{ exchangeRates.rate }}</b>
            </div>
          </div>
          <form @submit.prevent="submitUpdateArticle">
            <div class="modal-body">

              <div class="container">
                <div class="row">
                  <!-- Form Fields -->
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="catalog_number">Nr Katalogowy</label>
                      <input id="catalog_number" class="form-control form-control-sm" type="text"
                             v-model="form.catalog_number">
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="model">Model</label>
                      <input id="model" class="form-control form-control-sm" type="text" v-model="form.model">
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="unit_id">Jednostka</label>
                      <select id="unit_id" class="form-select form-select-sm" v-model="form.unit_id">
                        <option v-for="unit in units" :key="unit.id" :value="unit.id">{{ unit.name }}</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="description">Opis</label>
                      <textarea id="description" class="form-control form-control-sm"
                                v-model="form.description"></textarea>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="description">Szczegóły</label>
                      <textarea id="description" class="form-control form-control-sm"
                                v-model="form.details"></textarea>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="link">Link</label>
                      <input id="link" class="form-control form-control-sm" type="text" v-model="form.link">
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="producent">Producent</label>
                      <select id="producent" class="form-select form-select-sm" v-model="form.producer_id">
                        <option v-for="producer in producers" :key="producer.id" :value="producer.id">{{
                            producer.name
                          }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="category">Kategoria</label>
                      <select id="category" class="form-select form-select-sm" v-model="form.category_id">
                        <option v-for="category in categories" :key="category.id" :value="category.id">{{
                            category.name
                          }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="vat">VAT</label>
                      <input id="vat" class="form-control form-control-sm" type="number" v-model="form.vat">
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="price_netto_zl">Cena Netto (PLN)</label>
                      <input id="price_netto_zl" class="form-control form-control-sm" type="number" step="0.01"
                             v-model="form.price_netto_zl">
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="price_brutto_zl">Cena Brutto (PLN)</label>
                      <input id="price_brutto_zl" class="form-control form-control-sm" type="number" step="0.01"
                             :value="priceBruttoZL" @input="updateBruttoZL">
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="price_netto_eur">Cena Netto (EUR)</label>
                      <input id="price_netto_eur" class="form-control form-control-sm" type="number" step="0.01"
                             :value="priceNettoEUR" @input="updateNettoEUR">
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="price_brutto_eur">Cena Brutto (EUR)</label>
                      <input id="price_brutto_eur" class="form-control form-control-sm" type="number" step="0.01"
                             :value="priceBruttoEUR" @input="updateBruttoEUR">
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="warehouse_id">Lokalizacja</label>
                      <select id="warehouse_id" class="form-select form-select-sm" v-model="form.warehouse_id">
                        <option v-for="warehouse in warehouses" :key="warehouse.id" :value="warehouse.id">
                          {{ warehouse.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <!-- Wartość netto (automatyczna) -->
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="value_net">Wartość Netto</label>
                      <input id="value_net" class="form-control form-control-sm" type="number" step="0.01"
                             :value="valueNet" disabled>
                    </div>
                  </div>

                  <!-- Wartość brutto (automatyczna) -->
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="value_gross">Wartość Brutto</label>
                      <input id="value_gross" class="form-control form-control-sm" type="number" step="0.01"
                             :value="valueGross" disabled>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="value_gross">Dostawcy</label>
                      <input id="value_gross" class="form-control form-control-sm" type="text" v-model="form.suppliers">
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                <i class="bi bi-x-square me-2"></i>Zamknij
              </button>
              <button v-if="form.isDirty" type="submit" class="btn btn-primary">
                <i class="bi bi-save me-2"></i> zapisz
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {Modal} from "bootstrap";
import {useForm} from "@inertiajs/vue3";
import {ref} from "vue";

export default {
  props: {
    article: Object,
    units: Object,
    categories: Object,
    producers: Object,
    warehouses: Object,
    exchangeRates: Object,
  },

  data() {
    return {
      modalObj:null,
      form: useForm({
        id: this.article?.id || null,
        catalog_number: this.article?.catalog_number || '',
        model: this.article?.model || '',
        unit_id: this.article?.unit_id || null,
        description: this.article?.description || '',
        details: this.article?.details || '',
        link: this.article?.link || '',
        producer_id: this.article?.producer_id || null,
        category_id: this.article?.category_id || null,
        vat: this.article?.vat || 0,
        price_netto_zl: this.article?.price_netto_zl || 0,
        warehouse_id: this.article?.warehouse_id || null,
        income: this.article?.income || 0,
        outflow: this.article?.outflow || 0,
        suppliers: this.article?.suppliers || '',
      }),
    };
  },

  mounted() {
    this.modalObj = new Modal(this.$refs.editArticleModalEle);
  },

  watch: {
    article: {
      immediate: true,
      handler(newVal) {
        if (newVal && this.modalObj) {
          // Przypisanie wszystkich właściwości article do form
          Object.assign(this.form, {
            ...newVal
          });
        }
      }
    }
  },

  computed: {
    // Cena brutto w PLN
    priceBruttoZL() {
      return (this.form.price_netto_zl * (1 + this.form.vat / 100)).toFixed(2);
    },
    // Przeliczona cena netto w EUR na podstawie kursu wymiany
    priceNettoEUR() {
      return this.form.price_netto_zl && this.exchangeRates?.rate
          ? (this.form.price_netto_zl / this.exchangeRates.rate).toFixed(2)
          : 0;
    },
    // Cena brutto w EUR (obliczana na podstawie przelicznika i VAT)
    priceBruttoEUR() {
      return (this.priceNettoEUR * (1 + this.form.vat / 100)).toFixed(2);
    },
    // Wartość netto na magazynie w PLN
    valueNet() {
      return (this.form.price_netto_zl * this.form.stock_quantity).toFixed(2);
    },
    // Wartość brutto na magazynie w PLN
    valueGross() {
      return (this.priceBruttoZL * this.form.stock_quantity).toFixed(2);
    },
  },

  methods: {
    updateBruttoZL(event) {
      this.form.price_brutto_zl = parseFloat(event.target.value).toFixed(2);
    },
    updateNettoEUR(event) {
      this.form.price_netto_eur = parseFloat(event.target.value).toFixed(2);
    },
    updateBruttoEUR(event) {
      this.form.price_brutto_eur = parseFloat(event.target.value).toFixed(2);
    },
    submitUpdateArticle() {
      this.$inertia.post(
          route("articles.saveChanges"),
          {updated: [this.form]},
          {
            preserveState: true,
            onSuccess: () => {
              this.$emit("add-article", this.form);
              this.$emit("add-success", "Artykuł został zaktualizowany poprawnie.");
              this.hide();
            },
            onError: (resp) => {
              console.log("Błąd podczas zapisywania danych:", Object.keys(resp)[0] ?? resp);
              this.$emit("update-error", Object.keys(resp)[0] ?? resp);
            },
          }
      );
    },

    show() {
      if (this.modalObj) {
        this.modalObj.show();
      }
    },
    hide() {
      if (this.modalObj) {
        this.modalObj.hide();
      }
    },
  },
};
</script>
