<template>
  <AppLayout>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-six">
        <div class="widget-heading d-block">
          <div class="row">
            <div class="col-xl-12 col-md-12 col-sm-12 col-12 d-flex align-items-center">
              <h4 class="me-3 my-0">Zamówienia</h4>
              <div class="ms-auto">
                <div class="text-end">
                  <Link class="btn btn-sm btn-outline-primary" :href="route('orderPaymentMethods.index')">Formy
                    płatności
                  </Link>
                </div>
                <div class="form-check form-switch form-switch-success">
                  <input class="form-check-input" type="checkbox" role="switch" id="showArchivedProjects"
                         style="height:1.5em; width:3em;"
                         v-model="archivedShown" @change="changeShowArchived()">
                  <label class="form-check-label" for="showArchivedProjects" style="margin: 3px 0 0 10px;">Pokaż
                    zarchiwizowane</label>
                </div>
              </div>
            </div>

            <div v-if="$page.props.errors">
              <ul style="list-style-type: none; padding: 0" v-for="error in $page.props.errors">
                <li class="alert alert-danger">{{ error }}</li>
              </ul>
            </div>

            <div class="mt-3 row">

              <div class="col-md-12 col-lg-6">
                <label for="project-select">Wybierz projekt:</label>
                <select id="project-select" class="form-select form-select-sm" @change="updateSelectedProject"
                        v-model="selectedProjectId">
                  <option value="0" selected>Wszystkie projekty</option>
                  <option v-for="project in projects" :key="project.id" :value="project.id">
                    {{ project.label }}
                  </option>
                </select>
              </div>

              <div class="col-md-12 col-lg-6">
                <label for="team-select">Wybierz zespół:</label>
                <select id="team-select" class="form-select form-select-sm" @change="updateSelectedTeam"
                        v-model="selectedTeamName" :disabled="!selectedProjectId || (teams && teams.length === 0)">
                  <option value="">Wszystkie zespoły</option>
                  <option v-for="team in teams" :key="team.name" :value="team.name">
                    {{ team.name }}
                  </option>
                </select>
              </div>

            </div>
          </div>
        </div>

        <div class="widget-content">
          <div>
            <ejs-grid ref="orderListGrid" id="orderListGridId" height="400px"
                      :dataSource="orderListDataManager"
                      :allowTextWrap='false'
                      :allowExcelExport='true'
                      :allowPaging="true"
                      :allowSorting='true'
                      :allowFiltering='true'
                      :allowReordering='true'
                      :allowResizing='true'
                      :showColumnChooser='true'
                      :allowSelection='true'
                      :enablePersistence='true'
                      :pageSettings="pageSettings"
                      :editSettings="editSettings"
                      :filterSettings="filterOptions"
                      :toolbar="toolbar"
                      :contextMenuItems="contextMenuItems"
                      :selectionSettings="selectionOptions"
                      :toolbarClick="clickToolbarHandler"
                      :contextMenuClick="clickContextMenuHandler"
                      :actionBegin="actionBeginHandler"
                      :actionComplete="actionCompleteHandler"
                      :rowSelected="onRowSelected"
                      @contextMenuOpen="onContextMenuOpen"
                      :rowDataBound="rowDataBound"
                      :keyPressed="onKeyDown">
              <e-columns>
                <e-column field="id" headerText="ID" :allowEditing="false" isPrimaryKey="true"
                          :visible="false" :filter="filterDefault"/>
                <e-column field="order_number" headerText="Numer zamówienia" :allowEditing="false"
                          :filter="filterDefault" width="200"/>
                <e-column field="quote_requests" headerText="Zapytanie ofertowe" :allowEditing="false"
                          :filter="filterDefault"/>
                <e-column field="project" headerText="Projekt" :allowEditing="false" :visible="true"/>
                <e-column field="team_name" headerText="Zespół" :allowEditing="false" :visible="true"/>
                <e-column field="supplier" headerText="Wybrany dostawca" :allowEditing="false" :filter="filterDefault"/>
                <e-column field="send_to_mails" headerText="Maile" :allowEditing="false" :filter="filterDefault"/>
                <e-column field="send_to_mails_dw" headerText="Maile DW" :allowEditing="false" :filter="filterDefault"/>
                <e-column field="delivery_date_deadline" headerText="Ostateczny termin dostawy" :allowEditing="true"
                          :filter="filterDefault" format="dd.MM.yyyy" type="date" editType="datepickeredit"/>
                <e-column field="quantity_required" headerText="Wymagana ilość" :allowEditing="false" format="N2"
                          :filter="filterNumeric" :edit="editNumbersPlusParams"/>
                <e-column field="delivered_quantity" headerText="Dostarczona ilość" :allowEditing="false"
                          :filter="filterNumeric" format="N2" :edit="editNumbersPlusParams"/>
                <e-column field="dispatched_quantity" headerText="Wydana ilość" :allowEditing="false" format="N2"
                          :filter="filterNumeric"/>
                <e-column field="price_netto" headerText="Cena netto" :allowEditing="false"
                          :filter="filterNumeric" editType="numericedit" format="N2"/>
                <e-column field="vat" headerText="VAT" :allowEditing="false"
                          :filter="filterNumeric" editType="numericedit" format="N0"/>
                <e-column field="price_brutto" headerText="Cena brutto" :allowEditing="false"
                          :filter="filterNumeric" editType="numericedit" format="N2"/>
                <e-column field="value_netto" headerText="Wartość netto" :allowEditing="false"
                          :filter="filterNumeric" editType="numericedit" format="N2"/>
                <e-column field="value_brutto" headerText="Wartość brutto" :allowEditing="false"
                          :filter="filterNumeric" editType="numericedit" format="N2"/>
                <e-column field="currency" headerText="Waluta" :allowEditing="false" :filter="filterDefault"/>
                <e-column field="euro_rate" headerText="Kurs Euro" :allowEditing="false" format="N4"
                          :filter="filterNumeric"/>
                <e-column field="advance" headerText="Zaliczka" :allowEditing="true" editType="numericedit" format="N2"
                          :filter="filterNumeric"/>
                <e-column field="status" headerText="Status" :allowEditing="true" editType="dropdownedit"
                          :dataSource="order_list_statuses" :edit="orderItemStatusesParams" :filter="filterStatuses"/>
                <e-column field="comment" headerText="Komentarz do zamówienia" :allowEditing="true"
                          :allowFiltering="false" :allowSorting="false"/>
                <e-column field="created_at" headerText="Data utworzenia" :allowEditing="false" type="date"
                          format="dd.MM.yyyy" :filter="filterDefault"/>
                <e-column field="created_by_name" headerText="Utworzone przez" :allowEditing="false"
                          :filter="filterDefault"/>

                <template v-slot:deliverersShortNamesTemplate="{data}">
                  {{ formatDeliverers(data) }}
                </template>

              </e-columns>

              <e-aggregates>
                <e-aggregate>
                  <e-columns>
                    <e-column colspan="2" columnName="quantity_required" type="Custom"
                              :customAggregate="diffAggregateFn"
                              footerTemplate='<div class="colspan-footer">${Custom}</div>'></e-column>

                    <e-column field="price_netto" type="Sum" format="N2" footerTemplate="${Sum}"/>
                    <e-column field="price_brutto" type="Sum" format="N2" footerTemplate="${Sum}"/>
                    <e-column field="value_netto" type="Sum" format="N2" footerTemplate="${Sum}"/>
                    <e-column field="value_brutto" type="Sum" format="N2" footerTemplate="${Sum}"/>
                  </e-columns>
                </e-aggregate>
              </e-aggregates>

            </ejs-grid>
          </div>

          <div class="mt-2">
            <div class="row">
              <div class="col-12" v-if="selectedOrder">
                <span class="me-2">Zamówienie <b>{{ selectedOrder.order_number }}</b>:</span>
                <button type="button" class="btn btn-sm btn-outline-secondary me-2">Szczegóły</button>
                <button type="button" class="btn btn-sm btn-outline-secondary">Historia (not working yet)</button>

                <SelectedOrderItems :orderListId="orderListId" :order_list_statuses="order_list_statuses"
                                    :order_payment_methods="order_payment_methods"
                                    @refresh="this.$refs.orderListGrid.refresh()" :suppliers="suppliers" :units="units"
                                    :selectedProjectId="selectedProjectId" :selectedTeamName="selectedTeamName"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <SendOrdersModal ref="sendOrderModal" :selectedItems="selectedRecordsToMove"
                     @refresh="this.$refs.orderListGrid.refresh()"
                     @added-success="successMsg" @added-error="errorMsg"/>

  </AppLayout>
</template>

<script>
import AppLayout from "../../Layouts/App.vue";
import {usePage} from "@inertiajs/vue3";
import {MultiSelect} from '@syncfusion/ej2-vue-dropdowns';
import {
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  ContextMenu,
  Edit,
  ExcelExport,
  Filter, ForeignKey,
  GridComponent,
  AggregatesDirective,
  AggregateDirective,
  Page,
  Reorder,
  Aggregate,
  Resize,
  Sort,
  Toolbar,
} from '@syncfusion/ej2-vue-grids';
import {createElement} from "@syncfusion/ej2-base";
import {DataManager, WebMethodAdaptor, Query, Predicate} from '@syncfusion/ej2-data';
import {ComboBox, DropDownList} from "@syncfusion/ej2-dropdowns";
import moment from "moment";
import {ref} from "vue";
import SendOrdersModal from "./SendOrdersModal.vue";
import SelectedOrderItems from "./SelectedOrderItems.vue";
import MoveItemToWarehouseModal from "./MoveItemToWarehouseModal.vue";


let dropInstanceFilterUnits, statusElem, statusObj, statusFilter;
export default {

  components: {
    MoveItemToWarehouseModal,
    SelectedOrderItems,
    SendOrdersModal,
    AppLayout,
    MultiSelect,
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    'e-column': ColumnDirective,
    "e-aggregates": AggregatesDirective,
    "e-aggregate": AggregateDirective
  },

  provide: {
    grid: [Page, Edit, Toolbar, ContextMenu, Sort, Filter, Reorder, Resize, ColumnChooser, ExcelExport, ForeignKey, Aggregate]
  },

  props: {
    projects: Object,
    order_list_statuses: Object,
    order_payment_methods: Object,
    units: Object,
    categories: Object,
    producers: Object,
    suppliers: Object,
    locations: Object,
    warehouses: Object,

  },

  beforeMount() {
    // Odczytanie danych z sesji
    const storedProjectId = sessionStorage.getItem('selectedProjectId');
    const storedTeam = sessionStorage.getItem('selectedTeam');
    this.archivedShown = sessionStorage.getItem('archivedShown');

    if (storedProjectId) {
      this.selectedProjectId = (storedProjectId === 'null' || storedProjectId === null) ? 0 : storedProjectId;
      this.updateTeams();
    }

    this.selectedTeamName = storedTeam;
    this.getRequiredProjectItemsForProject();
  },

  data() {

    return {
      shouldCollapseByInitial: true,
      teams: [],
      archivedShown: false,
      orderListDataManager: [],
      fetchedOrders: [],
      selectedProjectId: 0,
      selectedTeamName: null,
      selectedRecordsToMove: null,
      selectedOrder: null,
      orderListId: null,
      addRequiredProjectItemsModalRef: ref(null),
      editArticleModalRef: ref(null),
      showEditArticleModal: false,
      selectedArticleToEdit: null,
      selectedRecordsToSendOrder: null,
      pageSettings: {pageSize: 50, pageCount: 5},
      filterOptions: {type: "Menu", operator: 'contains'},
      filterDefault: {operator: 'contains', params: {minLength: 2}},
      filterNumeric: {operator: 'equal'},
      isRestoring: false,
      toolbar: this.getToolbar(),
      editSettings: this.getEditSettings(),
      selectionOptions: {cellSelectionMode: 'Box'},
      contextMenuItems: this.getContextMenu(),
      editNumbersPlusParams: {
        params: {
          min: 0,
          validateDecimalOnType: true
        }
      },

      filterUnits: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.units,
              fields: {text: 'name', value: 'id'},
              placeholder: 'Szukaj jednostki',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterCategories: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.categories,
              fields: {text: 'name', value: 'id'},
              placeholder: 'Szukaj kategorii',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterProducers: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.producers,
              fields: {text: 'short_name', value: 'id'},
              placeholder: 'Szukaj producentów',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterSuppliers: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.suppliers,
              fields: {text: 'short_name', value: 'short_name'},
              placeholder: 'Szukaj dostawców',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterWarehouses: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.warehouses,
              fields: {text: 'short_name', value: 'id'},
              placeholder: 'Szukaj magazynu',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterStatuses: {
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            statusFilter = new DropDownList({
              dataSource: this.order_list_statuses,
              fields: {text: 'name', value: 'id'},
              placeholder: 'Szukaj Statusu',
              popupHeight: '200px',
              allowFiltering: true
            });
            statusFilter.appendTo(flValInput);
          },
          write: (args) => {
            statusFilter.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, statusFilter.text);
          }
        }
      },

      orderItemStatusesParams: {
        create: () => {
          statusElem = createElement('input');
          return statusElem;
        },
        read: () => {
          return statusObj.value;
        },
        destroy: () => {
          statusObj.destroy();
        },
        write: (args) => {
          statusObj = new DropDownList({
            dataSource: this.order_list_statuses,
            fields: {value: 'name', text: 'name'},
            enabled: true,
            placeholder: 'Wybierz status',
            floatLabelType: 'Never',
            value: args.rowData.status?.id,
            change: async (event) => {
              statusObj.value = await this.onChangeStatus(event, args.rowData);
            }
          });
          statusObj.appendTo(statusElem);
        }
      },

      inventoryStatusesParams: {
        create: () => {
          statusElem = document.createElement('input');
          return statusElem;
        },
        read: () => {
          return statusObj.text;
        },
        destroy: () => {
          statusObj.destroy();
        },
        write: () => {
          statusObj = new DropDownList({
            dataSource: this.inventory_statuses,
            fields: {value: 'name', text: 'name'},
            enabled: true,
            placeholder: 'Wybierz status',
            floatLabelType: 'Never'
          });
          statusObj.appendTo(statusElem);
        }
      },
    }
  },

  methods: {
    getEditSettings() {
      return {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        showConfirmDialog: false,
        showDeleteConfirmDialog: false,
        mode: 'Batch',
        newRowPosition: 'Top',
      };
    },

    diffAggregateFn: function (data) {
      if(!data.result)
        return;

      let totalRequired = data.result
          .map(item => item.quantity_required || 0)
          .reduce((acc, value) => acc + parseFloat(value), 0);

      let totalDelivered = data.result
          .map(item => item.delivered_quantity || 0)
          .reduce((acc, value) => acc + parseFloat(value), 0);

      let difference = totalRequired - totalDelivered;

      // Zapisz wynik, aby można było go użyć do dynamicznego stylowania
      this.differenceValue = difference;

      return 'Brakuje ' + difference.toFixed(2) + ' jdn.';
    },

    getToolbar() {
      return [
        "Update",
        "Cancel",
        {text: "Resetuj filtry", prefixIcon: 'e-reset', id: 'reset_filters'},
        {text: "Search", prefixIcon: 'e-search', id: "search", align: 'center'},
        {text: "Przywróć domyślny", prefixIcon: 'e-undo', id: "restore", align: 'right'},
        {text: "Export do Excela", prefixIcon: 'e-excelexport', id: "excelexport", align: 'right'},
        "ColumnChooser",
      ];
    },

    clickToolbarHandler: async function (args) {
      /**
       * Akcja przy usuwaniu artykułu.
       */
      if (args.item.text === 'Usuń') {
        const selectedRecords = this.$refs.orderListGrid.getSelectedRecords();
        if (selectedRecords.length > 0) {
          selectedRecords.forEach((record) => {
            this.$refs.orderListGrid.deleteRecord("id", record);
          });
        }
      }

      /**
       * Akcja przy kliknięciu resetowania filtrów
       */
      if (args.item.id === 'reset_filters') {
        this.$refs.orderListGrid.clearFiltering();
      }

      if (args.item.id === 'restore') {
        let grid = this.$refs.orderListGrid;
        let gridName = 'grid' + grid.$el.id;
        grid.ej2Instances.enablePersistence = false;
        window.localStorage.setItem(gridName, "");
        grid.ej2Instances.destroy();
        location.reload();
      }

      if (args.item.id === 'excelexport') {
        this.$refs.orderListGrid.excelExport({
          fileName: `Zamówienia.xlsx`
        });
      }
    },

    async onRowSelected(args) {
      this.orderListId = args.data.id;
      this.selectedOrder = args.data;
    },

    getContextMenu() {
      return [
        {text: 'Wyślij zamówienie', target: '.e-content', id: 'send_order', enabled: false},
      ];
    },

    onContextMenuOpen(args) {
      // Pobierz dane wiersza, w którym otwarto menu
      const rowData = this.$refs.orderListGrid.getRowInfo(args.event.target)?.rowData;
      const contextMenuInstance = args.element.ej2_instances[0];

      // Włączy/wyłącz opcje wysłania zamówienia
      if (rowData?.status?.name === "W zatwierdzeniu") {
        contextMenuInstance.enableItems(["Wyślij zamówienie"], false); // Wyłącz opcję
      } else {
        contextMenuInstance.enableItems(["Wyślij zamówienie"], true); // Włącz opcję
      }
    },

    clickContextMenuHandler(args) {
      // Wyślij zamówienie
      if (args.item.id === 'send_order') {
        this.selectedRecordsToSendOrder = this.$refs.orderListGrid.getSelectedRecords();
        this.$nextTick(() => {
          this.selectedRecordsToMove = this.$refs.orderListGrid.getSelectedRecords();
          this.$refs.sendOrderModal.show();
        });
      }
    },

    updateSelectedProject(event) {
      this.selectedProjectId = Number(event.target.value);
      this.teams = null;
      this.selectedTeamName = null;
      if (this.selectedProjectId) {
        this.updateTeams()
      }

      this.getRequiredProjectItemsForProject();
    },

    updateTeams() {
      if (this.selectedProjectId) {
        const project = this.projects.find(project => project.id === Number(this.selectedProjectId));
        if (project) {
          this.teams = project.teams;
          this.selectedTeamName = null;
        }
      } else {
        this.teams = [];
        this.selectedTeamName = null;
      }
    },

    updateSelectedTeam(event) {
      this.selectedTeamName = event.target.value;
      this.getRequiredProjectItemsForProject();
    },

    getRequiredProjectItemsForProject() {
      sessionStorage.setItem('selectedProjectId', this.selectedProjectId);
      sessionStorage.setItem('selectedTeam', this.selectedTeamName);
      sessionStorage.setItem('archivedShown', this.archivedShown);

      this.orderListDataManager = new DataManager({
        url: route('orderLists.getList', {project: this.selectedProjectId, team: this.selectedTeamName}),
        adaptor: new WebMethodAdaptor(),
        batchUrl: route('orderLists.saveChanges'),
        updateUrl: route('orderLists.updateChanges'),
        crossDomain: true,
        headers: [
          {'X-CSRF-TOKEN': usePage().props.csrf_token},
          {'archivedShown': sessionStorage.getItem('archivedShown') || false}
        ],
      });
    },

    rowDataBound(args) {
      if (args.data.deleted_at) {
        args.row.classList.add('text-muted', 'deleted-row');
      }
    },

    changeShowArchived() {
      sessionStorage.setItem('archivedShown', this.archivedShown ?? false);
      if (this.$refs.orderListGrid) {
        document.getElementById('orderListGridId').ej2_instances[0].query = new Query().addParams('archivedShown', this.archivedShown);
      }
    },

    onKeyDown: function (args) {
      let keyCode = args.which || args.keyCode;
      let isCtrlKey = (args.ctrlKey || args.metaKey) ? true : ((keyCode === 17));

      // code 83 to 'S'
      if (isCtrlKey && keyCode === 83) {
        args.preventDefault();

        this.updateGrid();
      }
    },

    actionBeginHandler(args) {
      //
      // WYSZUKIWARKA- wyszukiwanie wielu wartości z separatorem (przecinek
      //
      if (args.requestType === 'searching') {
        const keys = args.searchString.split(',');
        let flag = true;
        let predicate;

        if (this.$refs.orderListGrid.ej2Instances.searchSettings.key !== '') {
          this.values = args.searchString;
          keys.forEach((key) => {
            this.$refs.orderListGrid.ej2Instances.getColumns().forEach((col) => {
              if (flag) {
                predicate = new Predicate(col.field, 'contains', key, true);
                flag = false;
              } else {
                let predic = new Predicate(col.field, 'contains', key, true);
                predicate = predicate.or(predic);
              }
            });
          });

          this.$refs.orderListGrid.ej2Instances.query = new Query().where(predicate);
          this.$refs.orderListGrid.ej2Instances.searchSettings.key = '';
          this.refresh = true;
          this.valueAssign = true;
          this.removeQuery = true;
          this.$refs.orderListGrid.ej2Instances.refresh();
        } else {
          this.$refs.orderListGrid.ej2Instances.searchSettings.key = '';
          this.refresh = true;
          this.valueAssign = true;
          this.removeQuery = true;
          this.$refs.orderListGrid.ej2Instances.refresh();
        }
      }
    },

    actionCompleteHandler(args) {
      //
      // WYSZUKIWARKA - wyszukiwanie wielu wartości z separatorem (przecinek
      //
      if (args.requestType === "refresh" && this.valueAssign) {
        document.getElementById(
            this.$refs.orderListGrid.ej2Instances.element.id + "_searchbar"
        ).value = this.values;
        this.valueAssign = false;
      } else if (
          document.getElementById(
              this.$refs.orderListGrid.ej2Instances.element.id + "_searchbar"
          ).value === "" &&
          args.requestType === "refresh" &&
          this.removeQuery
      ) {
        this.$refs.orderListGrid.ej2Instances.query = new Query();
        this.removeQuery = false;
        this.$refs.orderListGrid.ej2Instances.refresh();
      }

      if (args.requestType === "batchsave") {  // Tylko dla operacji batchSave (zapis)
        if (args.changes && args.changes.changedRecords) {
          // Sprawdzamy, czy `args.batchChanges` istnieje i czy są zmienione rekordy
          this.successMsg("Dane zostały pomyślnie zapisane!");

        } else if (args.error) {
          // Jeśli wystąpił błąd, wywołaj `errorMsg` z wiadomością błędu
          this.errorMsg("Wystąpił błąd podczas zapisu danych: " + args.error);
        }
      }
    },

    async onChangeStatus(event, rowData) {
      // Jeżeli user zmienił status na Zrealizowane
      if (!event.itemData || event.itemData?.name !== 'Zrealizowane') {
        return;
      }


      // Sprawdź, czy dostarczona ilość === wydanej ilości. Jeśli NIE:
      if (parseFloat(rowData.delivered_quantity) !== parseFloat(rowData.dispatched_quantity)) {
        Toast.fire({
          toast: false,
          position: 'center',
          icon: 'warning',
          title: 'Zakończenie zamówienia',
          html: 'Aby dokończyć realizacje zamówienia, dostarczona ilość oraz wydana ilość musi być taka sama.',
          showClass: {popup: 'animate__animated animate__fadeInDown'},
          hideClass: {popup: 'animate__animated animate__fadeOutUp'},
          timer: false,
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok, rozumiem',
        });

        // cofnij do poprzedniego statusu
        return event.previousItemData.id;
      }

      const result = await Toast.fire({
        toast: false,
        position: 'center',
        icon: 'question',
        title: 'Zakończenie zamówienia',
        html: 'Czy na pewno chcesz zmienić status na zrealizowany? To zamówienie będzie zakończone.',
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: false,
        showConfirmButton: true,
        confirmButtonText: 'Tak',
        confirmButtonColor: 'success',
        showCancelButton: true,
        cancelButtonText: 'Nie',
      });

      if (result.isConfirmed) {
        let response = await axios.post(route('orderLists.order-completed', {orderListItem: rowData.id}))
        if (response.status === 200) {
          this.successMsg(response);
        }
        this.$refs.orderListGrid.refresh();
      } else {
        // cofnij do poprzedniego statusu
        return event.previousItemData.id;
      }

    },

    updateGrid() {
      // aktualizuj
      let toolbar = document.getElementsByClassName('e-toolbar-left')[0];
      let buttons = toolbar.querySelectorAll('.e-toolbar-item');
      let updateButton = buttons[1].querySelector('button');


      if (updateButton) {
        updateButton.click();
      }
    },

    successMsg(resp) {
      Toast.fire({
        position: 'top-end',
        toast: true,
        icon: 'success',
        title: 'Sukces!',
        html: resp,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true
      });
    },

    errorMsg(resp) {
      Toast.fire({
        position: 'top-end',
        toast: true,
        icon: 'warning',
        title: 'Błąd!',
        html: resp,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true
      });
    },

    formatDeliverers(data) {
      // Paruj JSON i formatuj jako ciąg znaków
      try {
        const names = JSON.parse(data.deliverers_short_names);
        return names.join(', ');
      } catch (e) {
        // Jeśli dane nie są poprawnym JSON-em, zwróć je bez zmian
        return data.deliverers_short_names;
      }
    },
  }
}
</script>

<style>
.not-editable {
  color: orangered;
}

#orderListGridId .colspan-footer {
  text-wrap: nowrap;
  align-items: center;
}

#orderListGridId .e-grid .e-rowcell {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  line-height: 12px !important;
}

#orderListGridId .e-headercell, #orderListGridId .e-rowcell {
  font-size: 12px;
}

</style>