<template>
  <AppLayout>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-six">
        <div class="widget-heading d-block">
          <div class="row">
            <div class="col-xl-12 col-md-12 col-sm-12 col-12 d-flex align-items-center">
              <h4 class="me-3 my-0">Ruchy magazynowe</h4>
            </div>
          </div>
        </div>

        <div class="widget-content">
          <div>
            <ejs-grid ref="warehouseMovementsGrid" id="warehouseMovementsGridId" height="500px"
                      :dataSource="warehouseMovementsDataManager"
                      :allowTextWrap='false'
                      :allowExcelExport='true'
                      :allowPaging="true"
                      :allowSorting='true'
                      :allowFiltering='true'
                      :allowReordering='true'
                      :allowResizing='true'
                      :showColumnChooser='true'
                      :allowSelection='true'
                      :enablePersistence='true'
                      :pageSettings="pageSettings"
                      :editSettings="editSettings"
                      :filterSettings="filterOptions"
                      :toolbar="toolbar"
                      :contextMenuItems="contextMenuItems"
                      :rowSelected="onRowSelected"
                      :toolbarClick="clickToolbarHandler"
                      :contextMenuClick="clickContextMenuHandler"
                      @contextMenuOpen="onContextMenuOpen">
              <e-columns>
                <e-column field="id" headerText="ID" :allowEditing="false" isPrimaryKey="true"
                          :visible="false"></e-column>
                <e-column field="document_number" headerText="Numer Dokumentu" :filter="filterDefault"></e-column>
                <e-column field="article_warehouse.short_name" headerText="Magazyn" :filter="filterWarehouses"/>
                <e-column field="quantity" headerText="Ilość" :filter="filterDefault"></e-column>
                <e-column field="comment" headerText="Komentarz" :allowFiltering="false" :allowSorting="false"/>
                <e-column field="created_by_name" headerText="Utworzony przez" :filter="filterDefault"/>
                <e-column field="created_at" headerText="Data utworzenia" format="dd.MM.yyyy" :filter="filterDefault"/>
                <e-column field="article.catalog_number" headerText="Nr artykułu"
                          :filter="filterDefault"></e-column>
                <e-column field="article.model" headerText="Model artykułu"
                          :filter="filterDefault"></e-column>
                <e-column field="article.description" headerText="Nazwa/Opis" width="200"
                          :filter="filterDefault"></e-column>
                <e-column field="article.details" headerText="Sczegóły" type="text" width="200"
                          :filter="filterDefault"></e-column>
              </e-columns>

            </ejs-grid>
          </div>

        </div>
      </div>
    </div>


  </AppLayout>
</template>

<script>
import AppLayout from "../../../Layouts/App.vue";
import {useForm, usePage} from "@inertiajs/vue3";
import {AutoComplete, MultiSelect} from '@syncfusion/ej2-vue-dropdowns';
import {
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  ContextMenu,
  Edit,
  ExcelExport,
  Filter,
  ForeignKey,
  GridComponent,
  Page,
  Reorder,
  Resize,
  Sort,
  Toolbar,
  Group,
} from '@syncfusion/ej2-vue-grids';
import {createElement} from "@syncfusion/ej2-base";
import {DataManager, WebMethodAdaptor} from '@syncfusion/ej2-data';
import {ComboBox, DropDownList} from "@syncfusion/ej2-dropdowns";
import moment from "moment";
import {ref} from "vue";

import {usePermission} from "@/composables/resources/js/composables/permissions.js";
import ViewArticleWarehouseMovementDetails from "../Articles/ViewArticleWarehouseMovementDetails.vue";
import ViewArticleWarehouseVerticalColumns from "../ViewArticleWarehouseVerticalColumns.vue";
import ViewArticleWarehouseDetails from "../ViewArticleWarehouseDetails.vue";

const {hasRole, hasPermission} = usePermission();


let dropInstanceFilterUnits, statusElem, statusObj;
export default {

  components: {
    hasPermission,
    ViewArticleWarehouseVerticalColumns,
    ViewArticleWarehouseMovementDetails,
    ViewArticleWarehouseDetails,
    AppLayout,
    MultiSelect,
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    'e-column': ColumnDirective
  },

  provide: {
    grid: [Page, Edit, Toolbar, ContextMenu, Sort, Filter, Reorder, Resize, ColumnChooser, ExcelExport, ForeignKey, Group]
  },

  props: {
    users: Object,
    warehouses: Object
  },

  beforeMount() {
    this.getMovements();
  },

  data() {

    return {
      hasPermission,
      warehouseMovementsDataManager: [],
      selectedArticle: null,
      pageSettings: {pageSize: 50, pageCount: 5},
      filterOptions: {
        type: "Menu",
        operator: 'contains',
      },
      filterDefault: {operator: 'contains', params: {minLength: 2}},
      toolbar: this.getToolbar(),
      editSettings: this.getEditSettings(),
      contextMenuItems: this.getContextMenu(),
      filterWarehouses: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.warehouses,
              fields: {text: 'short_name', value: 'id'},
              placeholder: 'Szukaj magazynu',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterLocations: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.locations,
              fields: {text: 'short_name', value: 'id'},
              placeholder: 'Szukaj magazynu',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterCreatedBy: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.users,
              fields: {text: 'name', value: 'id'},
              placeholder: 'Szukaj kto stworzył',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },


      orderItemWarehousesParams: {
        create: () => {
          statusElem = document.createElement('input');
          return statusElem;
        },
        read: () => {
          return statusObj.text;
        },
        destroy: () => {
          statusObj.destroy();
        },
        write: () => {
          statusObj = new DropDownList({
            dataSource: this.warehouses,
            fields: {value: 'id', text: 'short_name'},
            enabled: true,
            placeholder: 'Wybierz magazyn',
            floatLabelType: 'Never',
            change: async (event) => {
              statusObj.value = await this.onChangeWarehouse(event)
            }
          });
          statusObj.appendTo(statusElem);
        }
      },
    }
  },

  methods: {
    getEditSettings() {
      return {
        allowEditing: false,
        allowAdding: false,
        allowDeleting: false,
        showConfirmDialog: false,
        showDeleteConfirmDialog: false,
        mode: 'Batch',
        newRowPosition: 'Top',
      };
    },

    getToolbar() {
      return [
        {text: "Resetuj filtry", prefixIcon: 'e-reset', id: 'reset_filters'},
        {text: "Search", prefixIcon: 'e-search', id: "search", align: 'center'},
        {text: "Przywróć domyślny", prefixIcon: 'e-undo', id: "restore", align: 'right'},
        {text: "Export do Excela", prefixIcon: 'e-excelexport', id: "excelexport", align: 'right'},
        "ColumnChooser",
      ];
    },

    clickToolbarHandler: async function (args) {
      /**
       * Akcja przy kliknięciu resetowania filtrów
       */
      if (args.item.id === 'reset_filters') {
        this.$refs.warehouseMovementsGrid.clearFiltering();
      }

      if (args.item.id === 'restore') {
        let grid = this.$refs.warehouseMovementsGrid;
        let gridName = 'grid' + grid.$el.id;
        grid.ej2Instances.enablePersistence = false;
        window.localStorage.setItem(gridName, "");
        grid.ej2Instances.destroy();
        location.reload();
      }

      if (args.item.id === 'excelexport') {
        this.$refs.warehouseMovementsGrid.excelExport({
          fileName: `Stany magazynowe.xlsx`
        });
      }
    },

    getContextMenu() {
      return [
        {text: 'Generuj PDF - PW', target: '.e-content', id: 'pdf-pw'},
        {text: 'Generuj PDF - MM', target: '.e-content', id: 'pdf-mm'},
        {text: 'Generuj PDF - RW', target: '.e-content', id: 'pdf-rw'},
        {text: 'Generuj PDF - WZ', target: '.e-content', id: 'pdf-wz'},
        // {separator: true},
      ];
    },

    onContextMenuOpen(args) {
    },

    clickContextMenuHandler(args) {

    },

    onRowSelected(args) {
      this.selectedArticle = args.data ?? {};
    },


    getMovements() {

      this.warehouseMovementsDataManager = new DataManager({
        url: route('warehouse-movements.getList'),
        adaptor: new WebMethodAdaptor(),
        crossDomain: true,
        headers: [
          {'X-CSRF-TOKEN': usePage().props.csrf_token}
        ],
      });
    },

    dateFormatter(field, data) {
      const date = data.created_at ? data.created_at : null;
      if (date) {
        try {
          return moment(date).format('DD.MM.YYYY HH:mm:ss');
        } catch (error) {
          return data
        }
      }

      return '';
    },

    successMsg(resp) {
      Toast.fire({
        position: 'top-end',
        toast: true,
        icon: 'success',
        title: 'Sukces!',
        html: resp,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true
      });
    },

    errorMsg(resp) {
      Toast.fire({
        position: 'top-end',
        toast: true,
        icon: 'warning',
        title: 'Błąd!',
        html: resp,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true
      });
    },

    isAdmin() {
      return hasRole('ADMIN');
    },

  }
}
</script>

<style scoped>
.not-editable {
  color: orangered;
}
</style>