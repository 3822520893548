<template>
  <div>
    <label class="me-1 form-label">{{ label }}: </label>
    <button type="button" class="btn btn-xs btn-outline-primary mb-1" @click="toggleVisibility">
      <i class="bi" :class="{'bi-eye-fill': hiddenEditor, 'bi-eye-slash': !hiddenEditor}" style="font-size: 14px"></i>
    </button>

    <Vueform>
      <DateElement
          ref="textInput"
          :id="'input_' + modelName"
          :name="modelName"
          :default="localModelText"
          value-format="YYYY-MM-DD"
          load-format="YYYY-MM-DD"
          display-format="DD.MM.YYYY"
          @change="changeValue"
          :addons="{ after: { template: templateForDateElement() }}"
          :extend-options="{weekNumbers: true}"
      />
    </Vueform>
  </div>
</template>

<script>

import moment from "moment";

export default {
  props: {
    label: String,
    modelName: String,
    modelText: String | Number | Date,
    format: String || 'YYYY-MM-DD',
    type: String,
    language: String,
    hidden: Boolean,
    attributes: Object
  },

  data() {
    let localModelText =this.formatDate(this.modelText);
    return {
      localModelName: this.modelName,
      localModelText: localModelText,
      localLabel: this.label,
      hiddenEditor: this.hidden,
      initialLoad: true
    }
  },

  methods: {
    toggleVisibility() {
      this.hiddenEditor = !this.hiddenEditor;
      const inputElement = this.$refs.textInput.$el;

      if (this.hiddenEditor) {
        inputElement.classList.add('d-none');
      } else {
        inputElement.classList.remove('d-none');
      }
    },

    changeValue(newVal) {
      this.localModelText = newVal.replace(/\./g, '');
    },

    templateForDateElement() {
      return '<i class="bi bi-calendar" style="font-size 8px"></i>';
    },

    formatDate(date){
      if(!date) return null;
      const desiredFormat = this.format ? this.format : 'YYYY-MM-DD';

      // Spróbuj sparsować datę zgodnie z dynamicznie ustawionym formatem
      const parsedDate = moment(date, desiredFormat, true);

      // Jeśli data jest poprawna, zwróć ją w zadanym formacie
      if (parsedDate.isValid()) {
        return parsedDate.format(desiredFormat);
      }

      // Jeśli data jest w nieznanym formacie, spróbuj z ISO lub zwróć oryginalną wartość
      const isoDate = moment(date);
      return isoDate.isValid() ? isoDate.format(desiredFormat) : date;
    }
  },

  watch: {
    modelText(newVal) {
      this.localModelText = newVal;
    },

    localModelText(newVal) {
      // Sprawdzamy, czy to nie jest początkowe ustawienie dla price
      // trzeba tak zrobić, price zmienia się onload (przez maskę) i od razu jest emitowany
      if (this.initialLoad && this.type === 'price') {
        this.initialLoad = false;
      } else {
        this.$emit('updateModel', {
          modelName: this.modelName,
          value: newVal.replace(/\./g, ''),
        });
      }
    },
  }
}
</script>
