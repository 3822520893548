<template>
  <AppLayout>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-six">
        <div class="widget-heading d-block">
          <div class="row">
            <div class="col-xl-12 col-md-12 col-sm-12 col-12 d-flex align-items-center">
              <h4 class="me-3 my-0">Zapotrzebowanie projektowe</h4>
              <div class="ms-auto">
                <div class="form-check form-switch form-switch-success">
                  <input class="form-check-input" type="checkbox" role="switch" id="showArchivedProjects"
                         style="height:1.5em; width:3em;"
                         v-model="archivedShown" @change="changeShowArchived()">
                  <label class="form-check-label" for="showArchivedProjects" style="margin: 3px 0 0 10px;">Pokaż
                    zarchiwizowane</label>
                </div>

                <button class="btn btn-sm btn-outline-primary" @click="clickShowHistoryModal">Pokaż historię</button>
                <ShowHistoryModal :projects="projects" :teams="teams"
                                  :units="units"
                                  :suppliers="suppliers"
                                  :statuses="statuses"
                                  :categories="categories"
                                  :producers="producers"
                                  :warehouses="warehouses"
                                  ref="showHistoryModalRef"/>

              </div>
            </div>
            <div class="mt-3 row">

              <div class="col-md-12 col-lg-6">
                <label for="project-select">Wybierz projekt:</label>
                <select id="project-select" class="form-select form-select-sm" @change="updateSelectedProject"
                        v-model="selectedProjectId">
                  <option value="0" selected>Wszystkie projekty</option>
                  <option v-for="project in projects" :key="project.id" :value="project.id">
                    {{ project.label }}
                  </option>
                </select>
              </div>

              <div class="col-md-12 col-lg-6">
                <label for="team-select">Wybierz zespół:</label>
                <Link v-if="selectedProjectId" :href="route('projects.show.numbering',{project: selectedProjectId})"
                      class="ms-2 btn btn-xs btn-outline-primary">
                  Edytuj Zespoły
                </Link>
                <select id="team-select" class="form-select form-select-sm" @change="updateSelectedTeam"
                        v-model="selectedTeamName" :disabled="!selectedProjectId || (teams && teams.length === 0)">
                  <option value="">Wszystkie zespoły</option>
                  <option v-for="team in teams" :key="team.name" :value="team.name">
                    {{ team.name }}
                  </option>
                </select>
              </div>

            </div>
          </div>
        </div>

        <div class="widget-content">
          <div>
            <ejs-grid ref="requiredProjectItemsGrid" id="requiredProjectItemsGrid" height="500px"
                      :dataSource="requiredProjectItemsDataManager"
                      :allowTextWrap='false'
                      :allowExcelExport='true'
                      :allowPaging="true"
                      :allowSorting='true'
                      :allowFiltering='true'
                      :allowReordering='true'
                      :allowResizing='true'
                      :showColumnChooser='true'
                      :allowSelection='true'
                      :enablePersistence='true'
                      :pageSettings="pageSettings"
                      :editSettings="editSettings"
                      :filterSettings="filterOptions"
                      :toolbar="toolbar"
                      :contextMenuItems="contextMenuItems"
                      :selectionSettings="selectionOptions"
                      :headerCellInfo="headerCellInfoEvent "
                      :toolbarClick="clickToolbarHandler"
                      :contextMenuClick="clickContextMenuHandler"
                      :actionBegin="actionBeginHandler"
                      :actionComplete="actionCompleteHandler"
                      @queryCellInfo="queryCellInfo"
                      @recordClick="onRecordClick"
                      :keyPressed="onKeyDown">
              <e-columns>
                <e-column type="number" width="70" :template="'indexTemplate'"
                          :allowEditing="false" :allowSorting="false" :allowFiltering="false"/>
                <e-column type="checkbox" width="100"/>
                <e-column field="id" headerText="Nr poz." width="125" :isPrimaryKey="true" :allowEditing="false"/>
                <e-column field="project.number" headerTooltip="Project" headerText="Project" :allowEditing="false"
                          :filter="filterDefault"/>
                <e-column field="team_name" headerTooltip="Zespół" headerText="Zespół" :allowEditing="false"
                          :filter="filterDefault"/>
                <e-column field="subteam_drawing_no" headerTooltip="Podzespół nr rysunku"
                          headerText="Podzespół nr rysunku" :allowEditing="true" :filter="filterDefault"/>
                <e-column field="article.catalog_number" headerTooltip="Nr katalogowy" headerText="Nr katalogowy"
                          :allowEditing="false" :filter="filterDefault"/>
                <e-column field="article.model" headerTooltip="Model" headerText="Model" :allowEditing="false"
                          :filter="filterDefault"/>
                <e-column field="article.description" headerTooltip="Nazwa/Opis" headerText="Nazwa/Opis" width="200"
                          :allowEditing="false" :filter="filterDefault"/>
                <e-column field="article.link" headerTooltip="Link" headerText="Link" :filter="filterDefault"
                          :allowEditing="false" :template="'linkTemplate'" type="string"/>
                <e-column field="article.article_unit.name" headerTooltip="J.m." headerText="J.m." width="105"
                          :allowEditing="false" :dataSource="units" :filter="filterUnits"/>
                <e-column field="article.producer.id" headerTooltip="Producent" headerText="Producent"
                          foreignKeyValue='short_name' foreignKeyField='id' :dataSource="producers"
                          :filter='filterProducers' :allowEditing="false"/>
                <e-column field="article.customer_category.id" foreignKeyValue='name' foreignKeyField='id'
                          :dataSource="categories" :filter='filterCategories' headerTooltip="Kategoria"
                          headerText="Kategoria" :allowEditing="false"/>
                <e-column field="quantity_required" headerTooltip="Ilość wymagana" headerText="Ilość wymagana"
                          format="N2" type="number" :edit="editNumbersPlusParams" editType="numericedit"
                          :allowEditing="true"/>
                <e-column field="suppliers_short_names" headerTooltip="Dostawcy" headerText="Dostawcy"
                          :allowEditing="true" editType="string" :filter='filterSuppliers' :edit="suppliersParams"/>
                <e-column field="created_by.name" headerTooltip="Dodany przez" headerText="Dodany przez"
                          :allowEditing="false"/>
                <e-column field="created_at" headerTooltip="Data dodania" headerText="Data dodania"
                          :allowEditing="false" type="date" :filter="rangeDateFilter" format="dd.MM.yyyy H:mm:ss"/>
                <e-column field="status" headerTooltip="Status" headerText="Status" :allowEditing="true"
                          editType="dropdownedit" :edit="requiredProjectItemsStatusesParams"/>
                <e-column field="delivery_date" headerTooltip="Data dostawy" headerText="Data dostawy"
                          :allowEditing="true" type="date" format="dd.MM.yyyy" editType="datepickeredit"/>
                <e-column field="release_date" headerTooltip="Data zwolnienia" headerText="Data zwolnienia"
                          :allowEditing="true" type="date" format="dd.MM.yyyy" editType="datepickeredit"/>
                <e-column field="order_submission_date" headerTooltip="Data Zlec. Zam." headerText="Data Zlec. Zam."
                          :allowEditing="true" type="date" format="dd.MM.yyyy" editType="datepickeredit"/>
                <e-column field="order_status_confirmation_date" headerTooltip="Data Wydr. Zam."
                          headerText="Data Wydr. Zam." :allowEditing="true" type="date" format="dd.MM.yyyy"
                          editType="datepickeredit"/>
                <e-column field="inventory_status" headerTooltip="Status Mag." headerText="Status Mag."
                          :filter="filterStatusMag" :allowEditing="true" editType="dropdownedit" type="string"
                          :edit="inventoryStatusesParams"/>
                <e-column field="numbering.production_date" headerTooltip="Data Prod." headerText="Data Prod."
                          :allowEditing="false" type="date" format="dd.MM.yyyy"/>
                <e-column field="lead_time" headerTooltip="Czas dostawy" headerText="Czas dostawy" :allowEditing="true"
                          type="date" format="dd.MM.yyyy"/>
                <e-column field="comments" headerTooltip="Uwagi" headerText="Uwagi" :allowEditing="true" type="string"/>
                <e-column field="updated_by.name" headerTooltip="Ostatnio zmienił" headerText="Ostatnio zmienił"
                          :allowEditing="false" :allowSorting="false" :allowFitering="false" type="string"/>
                <e-column field="article_main_warehouse.quantity" headerTooltip="Ilość magazynowa"
                          headerText="Ilość magazynowa" format="N" type="number" :allowEditing="false"/>
                <e-column field="warehouse_received" headerTooltip="Magazyn" headerText="Magazyn"
                          :filter="filterDefault" type="string" />
                <e-column field="patent_number" headerTooltip="Nr Patentu" headerText="Nr Patentu"
                          :allowEditing="true" type="string"/>
                <e-column field="deliveryDeadlineExceeded" headerTooltip="Przekr. term. dost." type="string"
                          headerText="Przekr. term. dost." :template="'deliveryDeadlineExceeded'"
                          :allowEditing="false" :filter="filterDefault"/>
                <e-column field="quantityToOrder" :template="'quantityTemplate'" headerTooltip="Ilość do zam."
                          headerText="Ilość do zam." :allowEditing="false" type="number" :filter="filterNumeric"/>
                <e-column field="barcode" headerTooltip="Kody kreskowe" headerText="Kody kreskowe" type="string"
                          :allowEditing="true" :filter="filterDefault"/>
              </e-columns>

              <template v-slot:indexTemplate="{data}">
                <span style="color:#a6a6a6">{{ ++data.index }}</span>
              </template>

              <template v-slot:linkTemplate="{data}">
                <a class="link link-primary" target="_blank" rel="noopener noreferrer" :href=data.article?.link>
                  {{ data.article?.link }}</a>
              </template>

              <template v-slot:quantityTemplate="{ data }">
                {{ (data.numbering?.quantity || 0) * (data.quantity_required || 0) }}
              </template>

              <template v-slot:deliveryDeadlineExceeded="{ data }">
                <template v-if="data.numbering?.team_name_construction === data.team_name">
                  <template v-if="compareDeliveryDeadlineDates(data.numbering?.production_date, data.delivery_date)">
                    <div class="alert-danger p-2">
                      Przekroczony o
                      {{ compareDeliveryDeadlineDates(data.numbering?.production_date, data.delivery_date) }} dni!
                    </div>
                  </template>
                </template>
              </template>

            </ejs-grid>
          </div>

        </div>
      </div>
    </div>

    <AddRequiredProjectItemsModal :project="selectedProjectId" :team="selectedTeamName"
                                  ref="addRequiredProjectItemsModalRef" :suppliers="suppliers"
                                  :units="units" @added-article="this.$refs.requiredProjectItemsGrid.refresh()"
                                  @added-success="successMsg" @added-error="errorMsg"/>

    <EditArticleModal :article="selectedRecordToEdit" @updated-article="this.$refs.requiredProjectItemsGrid.refresh()"
                      ref="editArticleModalRef" :units="units" :categories="categories" :producers="producers"
                      :warehouses="warehouses" :exchangeRates="exchangeRates" @update-success="successMsg"
                      @update-error="errorMsg"/>

    <AddToQuoteRequestModal :project="selectedProjectId" :selectedItems="selectedRecords"
                            ref="addQuoteRequestModalRef"
                            @updated-article="this.$refs.requiredProjectItemsGrid.refresh()"
                            @added-success="successMsg" @added-error="errorMsg"/>

    <AddOrderModal ref="addOrderModalRef" :project="selectedProjectId" :team="selectedTeamName"
                   :selectedItems="selectedRecords" @added-success="successMsg" @added-error="errorMsg"/>

    <CloneRequestProjectItemModal :selectedProject="selectedProjectId" :selectedTeam="selectedTeamName"
                                  :selectedRecords="selectedRecords" ref="cloneRequestProjectItemModalRef"
                                  :teams="teams" :projects="projects" :producers="producers" :categories="categories"
                                  :location="warehouses" :suppliers="suppliers"
                                  @updated-article="this.$refs.requiredProjectItemsGrid.refresh()"
                                  @added-success="successMsg" @added-error="errorMsg"/>

    <MoveItemToWarehouseModal ref="moveItemToWarehouseModal" :project="selectedProjectId" :team="selectedTeamName"
                              :selectedItem="selectedRecord" :checkWarehouses="false"
                              @update-grid="successMsg" @added-success="successMsg" @added-error="errorMsg"
                              @added-article="this.$refs.requiredProjectItemsGrid.refresh()"/>

    <ChangeStatusModal :selectedRecords="selectedRecords" ref="changeStatusModalRef" :statuses="statuses"
                       @updated-article="this.$refs.requiredProjectItemsGrid.refresh()"
                       @added-success="successMsg" @added-error="errorMsg"/>

    <ChangeStatusMagModal :selectedRecords="selectedRecords" ref="changeStatusMagModalRef"
                          :statuses="inventory_statuses"
                          @updated-article="this.$refs.requiredProjectItemsGrid.refresh()"
                          @added-success="successMsg" @added-error="errorMsg"/>

    <ChangeDeliveryDateModal :selectedRecords="selectedRecords" ref="changeDeliveryDateModalRef"
                             @updated-article="this.$refs.requiredProjectItemsGrid.refresh()"
                             @added-success="successMsg" @added-error="errorMsg"/>

    <ChangeReleaseDateModal :selectedRecords="selectedRecords" ref="changeReleaseDateModalRef"
                            @updated-article="this.$refs.requiredProjectItemsGrid.refresh()"
                            @added-success="successMsg" @added-error="errorMsg"/>

    <ChangeOrderSubmissionDateModal :selectedRecords="selectedRecords" ref="changeOrderSubmissionDateModalRef"
                                    @updated-article="this.$refs.requiredProjectItemsGrid.refresh()"
                                    @added-success="successMsg" @added-error="errorMsg"/>

    <ChangeOrderStatusConfirmationDateModal :selectedRecords="selectedRecords"
                                            ref="changeOrderStatusConfirmationDateModalRef"
                                            @updated-article="this.$refs.requiredProjectItemsGrid.refresh()"
                                            @added-success="successMsg" @added-error="errorMsg"/>

    <ChangeMagazynModal :selectedRecords="selectedRecords"
                        ref="changeMagazynModalRef"
                        @updated-article="this.$refs.requiredProjectItemsGrid.refresh()"
                        @added-success="successMsg" @added-error="errorMsg"/>

    <ShowDetails v-if="selectedRecord" :selectedRecord="selectedRecord" ref="showDetailsRef"></ShowDetails>

  </AppLayout>
</template>

<script>
import AppLayout from "../../Layouts/App.vue";
import {usePage} from "@inertiajs/vue3";
import {MultiSelect} from '@syncfusion/ej2-vue-dropdowns';
import {Tooltip} from "@syncfusion/ej2-popups";
import {
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  ContextMenu,
  Edit,
  ExcelExport,
  Filter, ForeignKey,
  GridComponent,
  Page,
  Reorder,
  Resize,
  Sort,
  Toolbar,
  RowDD,
  VirtualScroll
} from '@syncfusion/ej2-vue-grids';
import {createElement} from "@syncfusion/ej2-base";
import {DataManager, Predicate, Query, WebMethodAdaptor} from '@syncfusion/ej2-data';
import {DateRangePicker} from "@syncfusion/ej2-calendars";
import {DropDownList} from "@syncfusion/ej2-dropdowns";
import moment from "moment";
import EditArticleModal from "./EditArticleModal.vue";
import {ref} from "vue";
import AddToQuoteRequestModal from "./AddToQuoteRequestModal.vue";
import AddRequiredProjectItemsModal from "./AddRequiredProjectItemsModal.vue";
import CloneRequestProjectItemModal from "./CloneRequestProjectItemModal.vue";
import ChangeStatusModal from "./ChangeStatusModal.vue";
import ChangeStatusMagModal from "./ChangeStatusMagModal.vue";
import ChangeDeliveryDateModal from "./ChangeDeliveryDateModal.vue";
import ChangeReleaseDateModal from "./ChangeReleaseDateModal.vue";
import ChangeOrderSubmissionDateModal from "./ChangeOrderSubmissionDateModal.vue";
import ChangeOrderStatusConfirmationDateModal from "./ChangeOrderStatusConfirmationDateModal.vue";
import ShowDetails from "./ShowDetails.vue";
import ShowHistoryModal from "./ShowHistoryModal.vue";
import MoveItemToWarehouseModal from "../OrderLists/MoveItemToWarehouseModal.vue";
import ChangeMagazynModal from "./ChangeMagazynModal.vue";
import AddOrderModal from "../QuoteRequests/AddOrderModal.vue";


let dropInstanceFilterUnits, dropInstanceFilterSuppliers, statusElem, statusObj, statusFilter, delivererElem,
    multiSelectObj;
export default {

  components: {
    AddOrderModal,
    ShowHistoryModal,
    MoveItemToWarehouseModal,
    ChangeStatusMagModal,
    ChangeDeliveryDateModal,
    ChangeReleaseDateModal,
    ChangeOrderSubmissionDateModal,
    CloneRequestProjectItemModal,
    ChangeOrderStatusConfirmationDateModal,
    ShowDetails,
    ChangeStatusModal,
    AddRequiredProjectItemsModal,
    AddToQuoteRequestModal,
    EditArticleModal,
    ChangeMagazynModal,
    AppLayout,
    MultiSelect,
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    'e-column': ColumnDirective
  },

  provide: {
    grid: [DateRangePicker, Page, Edit, Toolbar, ContextMenu, Sort, Filter, Reorder, Resize, ColumnChooser, ExcelExport, ForeignKey, RowDD, VirtualScroll]
  },

  props: {
    projects: Object,
    required_project_items_statuses: Object,
    inventory_statuses: Object,
    units: Object,
    suppliers: Object,
    statuses: Object,
    categories: Object,
    producers: Object,
    warehouses: Object,
    exchangeRates: Object
  },

  beforeMount() {
    // Odczytanie danych z sesji
    const storedProjectId = sessionStorage.getItem('selectedProjectId');
    const storedTeam = sessionStorage.getItem('selectedTeam');
    this.archivedShown = sessionStorage.getItem('archivedShown');

    if (storedProjectId) {
      this.selectedProjectId = (storedProjectId === 'null' || storedProjectId === null) ? 0 : storedProjectId;
      this.updateTeams();
    }

    this.selectedTeamName = storedTeam;
    this.getRequiredProjectItemsForProject();
  },

  data() {
    return {
      archivedShown: false,
      teams: [],
      requiredProjectItemsDataManager: [],
      fetchedOrders: [],
      selectedProjectId: null,
      selectedTeamName: null,
      addRequiredProjectItemsModalRef: ref(null),
      editArticleModalRef: ref(null),
      showEditArticleModal: false,
      selectedRecordToEdit: null,
      selectedRecords: null,
      selectedRecord: null,
      pageSettings: {pageSize: 50, pageCount: 5},
      filterOptions: {type: "Menu", operator: 'contains'},
      filterDefault: {operator: 'contains', params: {minLength: 2}},
      filterNumeric: {operator: 'equal'},
      toolbar: this.getToolbar(),
      editSettings: this.getEditSettings(),
      selectionOptions: {checkboxOnly: true},
      contextMenuItems: this.getContextMenu(),

      filterUnits: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.units,
              fields: {text: 'name', value: 'id'},
              placeholder: 'Szukaj jednostki',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterCategories: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.categories,
              fields: {text: 'name', value: 'id'},
              placeholder: 'Szukaj kategorii',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterProducers: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.producers,
              fields: {text: 'short_name', value: 'id'},
              placeholder: 'Szukaj producentów',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterWarehouses: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.warehouses,
              fields: {text: 'short_name', value: 'id'},
              placeholder: 'Szukaj magazynu',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterSuppliers: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.producers,
              fields: {text: 'short_name', value: 'id'},
              placeholder: 'Szukaj dostawców',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterStatusMag: {
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            statusFilter = new DropDownList({
              dataSource: this.inventory_statuses,
              fields: {text: 'name', value: 'name'},
              placeholder: 'Szukaj Statusu mag.',
              popupHeight: '200px',
              allowFiltering: true
            });
            statusFilter.appendTo(flValInput);
          },
          write: (args) => {
            statusFilter.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, statusFilter.text);
          }
        }
      },

      dateValue: null,
      rangeDateFilter: {
        ui: {
          create: (args) => {
            // Ukrywanie domyślnego selektora operatorów
            if (args.getOptrInstance && args.getOptrInstance.dropOptr) {
              let optrElement = args.getOptrInstance.dropOptr.element;
              if (optrElement && optrElement.parentElement) {
                optrElement.parentElement.parentElement.style.display = "none";
              }
            }

            // Tworzenie elementu dla DateRangePickera
            let flValInput = createElement("input", {className: "flm-input"});
            args.target.appendChild(flValInput);

            // Inicjalizacja DateRangePicker tylko raz
            //if (!this.dateRangePicker) {
            this.dateRangePicker = new DateRangePicker({
              placeholder: "Wybierz zakres dat",
              change: (e) => {
                if (e && e.value) {
                  this.dateValue = e.value;
                  this.applyDateFilter(args.column.field);
                } else {
                  this.clearDateFilter(args.column.field);
                }
              }
            });
            this.dateRangePicker.appendTo(flValInput);
            //}
          },
          write: () => {
            // Jeśli są wcześniejsze wartości, ustaw je w DateRangePickerze
            if (this.dateValue) {
              this.dateRangePicker.value = this.dateValue;
            }
          },
          read: (args) => {
          }
        }
      },

      editNumbersPlusParams: {
        params: {
          min: 0,
          validateDecimalOnType: true
        }
      },

      suppliersParams: {
        create: () => {
          delivererElem = document.createElement('input');
          return delivererElem;
        },
        read: () => {
          return multiSelectObj.value.join(",");
        },
        destroy: () => {
          multiSelectObj.destroy();
        },
        write: (args) => {
          const selectedValues = args.rowData.suppliers_short_names ? args.rowData.suppliers_short_names.split(",") : [];

          // Inicjalizacja MultiSelect
          multiSelectObj = new MultiSelect({
            value: selectedValues,
            dataSource: this.suppliers,
            fields: {text: "short_name", value: "short_name"},
            placeholder: 'Wybierz dostawców',
            mode: 'Box',
            allowFiltering: true,
            popupHeight: '200px',
            closePopupOnSelect: false,
          });

          multiSelectObj.appendTo(delivererElem);
        }
      },

      requiredProjectItemsStatusesParams: {
        create: () => {
          statusElem = document.createElement('input');
          return statusElem;
        },
        read: () => {
          return statusObj.text;
        },
        destroy: () => {
          statusObj.destroy();
        },
        write: () => {
          statusObj = new DropDownList({
            dataSource: this.required_project_items_statuses,
            fields: {value: 'name', text: 'name'},
            enabled: true,
            placeholder: 'Wybierz status',
            floatLabelType: 'Never'
          });
          statusObj.appendTo(statusElem);
        }
      },

      inventoryStatusesParams: {
        create: () => {
          statusElem = document.createElement('input');
          return statusElem;
        },
        read: () => {
          return statusObj?.text || '';
        },
        destroy: () => {
          statusObj?.destroy();
        },
        write: () => {
          statusObj = new DropDownList({
            dataSource: this.inventory_statuses,
            fields: {text: 'name', value: 'name'},
            enabled: true,
            placeholder: 'Wybierz status mag',
            floatLabelType: 'Never'
          });

          statusObj.appendTo(statusElem);
        }
      }

    }
  },

  methods: {
    getEditSettings() {
      return {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        showConfirmDialog: false,
        showDeleteConfirmDialog: false,
        mode: 'Batch',
        newRowPosition: 'Top',
      };
    },

    getToolbar() {
      return [
        "Add",
        {text: 'Usuń', prefixIcon: 'e-delete', id: 'delete_btn'},
        "Update",
        "Cancel",
        {text: "Resetuj filtry", prefixIcon: 'e-reset', id: 'reset_filters'},
        {text: "Search", prefixIcon: 'e-search', id: "search", align: 'center'},
        {text: "Przywróć domyślny", prefixIcon: 'e-undo', id: "restore", align: 'right'},
        {text: "Export do Excela", prefixIcon: 'e-excelexport', id: "excelexport", align: 'right'},
        "ColumnChooser",
      ];
    },

    queryCellInfo(args) {
      // Sprawdź, czy artykuł został usunięty
      if (args.column.field === "article.catalog_number" || args.column.field === "article.model") {
        if (args.data.article && args.data.article.deleted_at) {
          args.cell.setAttribute('title', 'Ten artykuł został usunięty!');
          args.cell.classList.add("deleted-article");
        }
      }
    },

    headerCellInfoEvent: function (args) {
      // pokazywanie tooltipów po najechanie na header
      new Tooltip(
          {
            content: args.cell.column.headerTooltip
          },
          args.node
      );
    },

    clickShowHistoryModal() {
      this.$refs.showHistoryModalRef.show();
    },

    clickToolbarHandler: async function (args) {
      /**
       * Niestandardowa akcja przy dodawaniu
       */
      if (args.item.text === 'Dodaj') {
        args.cancel = true;

        if ((this.selectedProjectId && this.selectedProjectId !== '0') && (this.selectedTeamName && this.selectedTeamName !== 'null')) {
          await this.$nextTick(() => {
            this.$refs.addRequiredProjectItemsModalRef.show();
          });
        } else
          this.errorMsg('Najpierw wybierz projekt i zespół.');
      }

      /**
       * Akcja przy usuwaniu artykułu.
       */
      if (args.item.text === 'Usuń') {
        const selectedRecords = this.$refs.requiredProjectItemsGrid.getSelectedRecords();
        if (selectedRecords.length > 0) {
          selectedRecords.forEach((record) => {
            this.$refs.requiredProjectItemsGrid.deleteRecord("id", record);
          });
        }
      }

      /**
       * Akcja przy kliknięciu resetowania filtrów
       */
      if (args.item.id === 'reset_filters') {
        this.$refs.requiredProjectItemsGrid.clearFiltering();
      }

      if (args.item.id === 'restore') {
        let grid = this.$refs.requiredProjectItemsGrid;
        let gridName = 'grid' + grid.$el.id;
        grid.ej2Instances.enablePersistence = false;
        window.localStorage.setItem(gridName, "");
        grid.ej2Instances.destroy();
        location.reload();
      }

      if (args.item.id === 'excelexport') {
        this.$refs.requiredProjectItemsGrid.excelExport({
          fileName: `zapotrzebowanie projektowe.xlsx`
        });
      }
    },

    getContextMenu() {
      return [
        {text: 'Edytuj artykuł', target: '.e-content', id: 'edit_article'},
        {separator: true},
        {text: 'Kopiuj do innego projektu/zespołu', target: '.e-content', id: 'clone_to_other_project'},
        {separator: true},
        {text: 'Utwórz zapytanie ofertowe', target: '.e-content', id: 'create_quote_request'},
        {text: 'Utwórz zamówienie', target: '.e-content', id: 'create_order'},
        {separator: true},
        {text: 'Zmień status', target: '.e-content', id: 'change_status'},
        {text: 'Zmień status magazynowy', target: '.e-content', id: 'change_status_mag'},
        {text: 'Zmień magazyn', target: '.e-content', id: 'change_magazyn'},
        {text: 'Zmień datę dostawy', target: '.e-content', id: 'change_delivery_date'},
        {text: 'Zmień datę zwolnienia', target: '.e-content', id: 'change_release_date'},
        {text: 'Zmień datę zlecenia zamówienia', target: '.e-content', id: 'change_order_submission_date'},
        {text: 'Zmień datę wydruku zamówienia', target: '.e-content', id: 'change_order_status_confirmation_date'},
        {separator: true},
        {text: 'Dyspozycja magazynowa', target: '.e-content', id: 'warehouse_disposition'},
      ];
    },

    clickContextMenuHandler(args) {
      if (args.item.id === 'edit_article') {
        this.selectedRecordToEdit = args.rowInfo.rowData.article;
        this.$nextTick(() => {
          this.$refs.editArticleModalRef.show();
        });
      }

      if (args.item.id === 'create_quote_request') {
        this.selectedRecords = this.$refs.requiredProjectItemsGrid.getSelectedRecords();
        this.$nextTick(() => {
          this.$refs.addQuoteRequestModalRef.show();
        });
      }

      if (args.item.id === 'create_order') {
        this.selectedRecords = this.$refs.requiredProjectItemsGrid.getSelectedRecords();
        this.$nextTick(() => {
          this.$refs.addOrderModalRef.show();
        });
      }

      if (args.item.id === 'clone_to_other_project') {
        this.selectedRecords = this.$refs.requiredProjectItemsGrid.getSelectedRecords();
        this.$nextTick(() => {
          this.$refs.cloneRequestProjectItemModalRef.show();
        })
      }

      if (args.item.id === 'warehouse_disposition') {
        this.selectedRecord = args.rowInfo.rowData;
        this.$refs.moveItemToWarehouseModal.show();
      }

      if (args.item.id === 'change_status') {
        this.selectedRecords = this.$refs.requiredProjectItemsGrid.getSelectedRecords();
        this.$nextTick(() => {
          this.$refs.changeStatusModalRef.show();
        })
      }

      if (args.item.id === 'change_status_mag') {
        this.selectedRecords = this.$refs.requiredProjectItemsGrid.getSelectedRecords();
        this.$nextTick(() => {
          this.$refs.changeStatusMagModalRef.show();
        })
      }

      if (args.item.id === 'change_magazyn') {
        this.selectedRecords = this.$refs.requiredProjectItemsGrid.getSelectedRecords();
        this.$nextTick(() => {
          this.$refs.changeMagazynModalRef.show();
        })
      }

      if (args.item.id === 'change_delivery_date') {
        this.selectedRecords = this.$refs.requiredProjectItemsGrid.getSelectedRecords();
        this.$nextTick(() => {
          this.$refs.changeDeliveryDateModalRef.show();
        })
      }

      if (args.item.id === 'change_release_date') {
        this.selectedRecords = this.$refs.requiredProjectItemsGrid.getSelectedRecords();
        this.$nextTick(() => {
          this.$refs.changeReleaseDateModalRef.show();
        })
      }

      if (args.item.id === 'change_order_submission_date') {
        this.selectedRecords = this.$refs.requiredProjectItemsGrid.getSelectedRecords();
        this.$nextTick(() => {
          this.$refs.changeOrderSubmissionDateModalRef.show();
        })
      }

      if (args.item.id === 'change_order_status_confirmation_date') {
        this.selectedRecords = this.$refs.requiredProjectItemsGrid.getSelectedRecords();
        this.$nextTick(() => {
          this.$refs.changeOrderStatusConfirmationDateModalRef.show();
        })
      }
      // if (args.item.id === 'check_quote_request') {
      //
      //   window.open(route('quoteRequests.index', {
      //     requiredProjectItems: args.rowInfo.rowData.id,
      //     project: this.selectedProjectId,
      //     team: this.selectedTeamName,
      //     article: args.rowInfo.rowData.article.id
      //   }), '_blank');
      // }
    },

    updateSelectedProject(event) {
      this.selectedProjectId = Number(event.target.value);
      this.teams = null;
      this.selectedTeamName = null;
      if (this.selectedProjectId) {
        this.updateTeams()
      }

      this.getRequiredProjectItemsForProject();
    },

    updateTeams() {
      if (this.selectedProjectId) {
        const project = this.projects.find(project => project.id === Number(this.selectedProjectId));
        if (project) {
          this.teams = project.teams;
          this.selectedTeamName = null;
        }
      } else {
        this.teams = [];
        this.selectedTeamName = null;
      }
    },

    updateSelectedTeam(event) {
      this.selectedTeamName = event.target.value;
      this.getRequiredProjectItemsForProject();
    },

    getRequiredProjectItemsForProject() {
      sessionStorage.setItem('selectedProjectId', this.selectedProjectId);
      sessionStorage.setItem('selectedTeam', this.selectedTeamName);
      sessionStorage.setItem('archivedShown', this.archivedShown);
      document.cookie = `archivedShown=${this.archivedShown || false}; path=/;`;

      this.requiredProjectItemsDataManager = new DataManager({
        url: route('requiredProjectItems.getList', {
          project: this.selectedProjectId,
          team: this.selectedTeamName
        }),
        adaptor: new WebMethodAdaptor(),
        batchUrl: route('requiredProjectItems.saveChanges'),
        updateUrl: route('requiredProjectItems.updateChanges'),
        crossDomain: true,
        headers: [
          {'X-CSRF-TOKEN': usePage().props.csrf_token},
          {'archivedShown': sessionStorage.getItem('archivedShown') || false}
        ],
      });
    },

    changeShowArchived() {
      sessionStorage.setItem('archivedShown', this.archivedShown || false);
      document.cookie = `archivedShown=${this.archivedShown || false}; path=/;`;
      if (this.$refs.requiredProjectItemsGrid) {
        const query = new Query().addParams('archivedShown', this.archivedShown);
        document.getElementById('requiredProjectItemsGrid').ej2_instances[0].query = query;
      }
    },

    dateFormatter(field, data) {
      const date = data.created_at ? data.created_at : null;
      if (date) {
        try {
          return moment(date).format('DD.MM.YYYY HH:mm:ss');
        } catch (error) {
          return data
        }
      }

      return '';
    },

    onRecordClick(args) {
      this.selectedRecord = args.rowData;

      // Usuń klasę z wszystkich wierszy
      this.clearRowSelection();

      // Dodaj klasę do klikniętego wiersza
      args.row.classList.add("selected-row");
    },

    clearRowSelection() {
      // Pobierz wszystkie wiersze i usuń klasę "selected-row"
      const rows = document.querySelectorAll(".selected-row");
      rows.forEach(row => row.classList.remove("selected-row"));
    },

    onKeyDown: function (args) {
      let keyCode = args.which || args.keyCode;
      let isCtrlKey = (args.ctrlKey || args.metaKey) ? true : ((keyCode === 17));

      // code 83 to 'S'
      if (isCtrlKey && keyCode === 83) {
        args.preventDefault();

        // aktualizuj
        let toolbar = document.getElementsByClassName('e-toolbar-left')[0];
        let buttons = toolbar.querySelectorAll('.e-toolbar-item');
        let updateButton = buttons[2].querySelector('button');
        if (updateButton) {
          updateButton.click();

        }
      }
    },

    actionBeginHandler(args) {
      //
      // WYSZUKIWARKA- wyszukiwanie wielu wartości z separatorem (przecinek
      //
      if (args.requestType === 'searching') {
        const keys = args.searchString.split(',');
        let flag = true;
        let predicate;

        if (this.$refs.requiredProjectItemsGrid.ej2Instances.searchSettings.key !== '') {
          this.values = args.searchString;
          keys.forEach((key) => {
            this.$refs.requiredProjectItemsGrid.ej2Instances.getColumns().forEach((col) => {
              if (flag) {
                predicate = new Predicate(col.field, 'contains', key, true);
                flag = false;
              } else {
                let predic = new Predicate(col.field, 'contains', key, true);
                predicate = predicate.or(predic);
              }
            });
          });

          this.$refs.requiredProjectItemsGrid.ej2Instances.query = new Query().where(predicate);
          this.$refs.requiredProjectItemsGrid.ej2Instances.searchSettings.key = '';
          this.refresh = true;
          this.valueAssign = true;
          this.removeQuery = true;
          this.$refs.requiredProjectItemsGrid.ej2Instances.refresh();
        } else {
          this.$refs.requiredProjectItemsGrid.ej2Instances.searchSettings.key = '';
          this.refresh = true;
          this.valueAssign = true;
          this.removeQuery = true;
          this.$refs.requiredProjectItemsGrid.ej2Instances.refresh();
        }
      }
    },

    actionCompleteHandler(args) {
      //
      // WYSZUKIWARKA - wyszukiwanie wielu wartości z separatorem (przecinek
      //
      if (args.requestType === "refresh") {
        const searchBar = document.getElementById(
            this.$refs.requiredProjectItemsGrid.ej2Instances.element.id + "_searchbar"
        );

        if (this.valueAssign) {
          searchBar.value = this.values; // Przywraca wartość tylko raz
          this.valueAssign = false;
        }

        // Jeśli pasek wyszukiwania jest pusty i `removeQuery` jest aktywny, to resetujemy tylko raz
        if (searchBar.value.trim() === "" && this.removeQuery) {
          this.$refs.requiredProjectItemsGrid.ej2Instances.query = new Query();
          this.removeQuery = false;
          this.$refs.requiredProjectItemsGrid.ej2Instances.refresh();
        }
      }

      if (args.requestType === "batchsave") {  // Tylko dla operacji batchSave (zapis)
        if (args.changes && args.changes.changedRecords) {
          // Sprawdzamy czy `args.batchChanges` istnieje i czy są zmienione rekordy
          this.successMsg("Dane zostały pomyślnie zapisane!");

        } else if (args.error) {
          // Jeśli wystąpił błąd, wywołaj `errorMsg` z wiadomością błędu
          this.errorMsg("Wystąpił błąd podczas zapisu danych: " + args.error);
        }
      }
    },

    compareDeliveryDeadlineDates(productionDate, deliveryDate) {
      if (!productionDate || !deliveryDate) {
        return null; // Jeśli brakuje dat, zwróć null
      }

      const prodDate = new Date(productionDate);
      const delivDate = new Date(deliveryDate);

      // Obliczenie różnicy w dniach
      const diffTime = prodDate - delivDate;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Konwersja milisekund na dni

      return diffDays < 0 ? Math.abs(diffDays) : 0; // Jeśli < 0, zwróć liczbę dni opóźnienia
    },

    applyDateFilter(field) {
      if (!this.$refs.requiredProjectItemsGrid) return;

      let gridInstance = this.$refs.requiredProjectItemsGrid.ej2Instances;

      // Upewniamy się, że dateValue istnieje i pobieramy daty
      let startDate = this.dateValue ? new Date(this.dateValue[0]).toISOString().split("T")[0] : null;
      let endDate = this.dateValue ? new Date(this.dateValue[1]) : null;

      // Ustawienie godziny 23:59:59 dla endDate
      if (endDate) {
        endDate.setHours(23, 59, 59, 999);
        endDate = endDate.toISOString().split("T")[0]; // yyyy-MM-dd
      }

      let filters = [];

      if (startDate) {
        filters.push({
          field: field,
          operator: "greaterthanorequal",
          value: startDate,
          predicate: "and"
        });
      }

      if (endDate) {
        filters.push({
          field: field,
          operator: "lessthanorequal",
          value: endDate,
          predicate: "and"
        });
      }

      // Zastosowanie filtrów w gridzie
      gridInstance.filterSettings.columns = filters;
      gridInstance.refresh(); // Odśwież grid, aby filtr się zastosował
    },

    clearDateFilter(field) {
      if (!this.$refs.grid) return;
      let gridInstance = this.$refs.requiredProjectItemsGrid;

      // Usuwamy filtr
      gridInstance.clearFiltering([field]);
      this.dateValue = null;
    },

    successMsg(resp) {
      Toast.fire({
        position: 'top-end',
        toast: true,
        icon: 'success',
        title: 'Sukces!',
        html: resp,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true
      });
    },

    errorMsg(resp) {
      Toast.fire({
        position: 'top-end',
        toast: true,
        icon: 'warning',
        title: 'Błąd!',
        html: resp,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true
      });
    },

  }
}
</script>

<style scoped>
.not-editable {
  color: orangered;
}
</style>

<style>
.selected-row {
  background-color: #f3f3f3 !important; /* Jasnoniebieski */
}
</style>
