<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="cloneRequestProjectItemModal" ref="modalEle" tabindex="-1" role="dialog"
         aria-labelledby="modelTitleId"
         aria-hidden="true">
      <div class="modal-dialog modal-full-width" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Klonuj wiersze do innego projektu/zespołu</h5>

            <button type="button" class="close ms-auto" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-if="error" class="alert alert-danger" role="alert">
              {{ error }}
            </div>

            <div class="row">
              <div class="col-md-12 col-lg-6">
                <label for="project-select">Wybierz projekt:</label>
                <select id="project-select" class="form-select form-select-sm" @change="updateSelectedProject"
                        v-model="newSelectedProjectId">
                  <option value="0" selected>Wszystkie projekty</option>
                  <option v-for="project in projects" :key="project.id" :value="project.id">
                    {{ project.label }}
                  </option>
                </select>
              </div>

              <div class="col-md-12 col-lg-6">
                <label for="team-select">Wybierz zespół:</label>
                <select id="team-select" class="form-select form-select-sm" @change="updateSelectedTeam"
                        v-model="newSelectedTeam" :disabled="!newSelectedProjectId || (allTeams.length === 0)">
                  <option value="">Wszystkie zespoły</option>
                  <option v-for="team in allTeams" :key="team.name" :value="team.name">
                    {{ team.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="table-responsive mt-5">
              <table class="table table-striped">
                <thead>
                <tr>
                  <th></th>
                  <th>Nr poz.</th>
                  <th> Podzespół nr rysunku</th>
                  <th>Nr katalogowy</th>
                  <th>Model</th>
                  <th>Nazwa/Opis</th>
                  <th>Org. ilość wymagana</th>
                  <th>Nowa ilość wymagana</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="record in selectedRecords">
                  <td class="text-danger">
                    <template v-if="record.article?.deleted_at">
                      Ten artykuł został wycofany
                      <button class="btn btn-sm btn-warning ms-2" @click="setNewArticle(record)">
                        Zmień
                      </button>
                    </template>
                  </td>
                  <td>{{ record.id }}</td>
                  <td>{{ record.subteam_drawing_no }}</td>
                  <td>{{ record.article?.catalog_number || '' }}</td>
                  <td>{{ record.article?.model }}</td>
                  <td>{{ record.article?.description }}</td>
                  <td>{{ record.org_quantity_required }}</td>
                  <td>{{ record.quantity_required }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              <i class="bi bi-x-square me-2"></i>Zamknij
            </button>

            <form @submit.prevent="submitForm">
              <button type="submit" class="btn btn-primary">
                <i class="bi bi-save me-2"></i>Klonuj
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <SetNewArticleModal ref="setNewArticleModal" :articleToChange="recordToChange" :units="units" :suppliers="suppliers"
                        :categories="categories" :locations="locations" :producers="producers" @article-selected="replaceArticle"/>

  </div>

</template>

<script>
import {Modal} from "bootstrap";
import SetNewArticleModal from "./SetNewArticleModal.vue";

let dropInstanceFilterUnits, delivererElem, multiSelectObj;

export default {
  components: {SetNewArticleModal},
  props: {
    selectedProject: Number | String,
    selectedTeam: Boolean | String,
    selectedRecords: Object,
    projects: Object,
    teams: Object,
    units: Object,
    suppliers: Object,
    producers: Object,
    categories:Object,
    locations: Object
  },

  data() {

    return {
      newSelectedProjectId: this.selectedProject,
      newSelectedTeam: this.selectedTeam,
      allTeams: this.teams,
      recordToChange: null,
      error: null,
    }
  },

  mounted() {
    this.modalObj = new Modal(this.$refs.modalEle);
  },

  watch: {
    // Obliczanie required_quantity dla zaznaczonego rekordu
    // na podstawie projekt->numeracja->team i jego quantity
    newSelectedTeam: "recalculateQuantities",
    selectedRecords: {
      handler: "recalculateQuantities",
      deep: true,
    },

  },

  methods: {
    updateSelectedProject(event) {
      this.newSelectedProjectId = Number(event.target.value);
      this.newSelectedTeam = null;
      this.$nextTick(() => {
        this.updateTeams()
      })
    },

    updateTeams() {
      if (this.newSelectedProjectId) {
        const project = this.projects.find(project => project.id === Number(this.newSelectedProjectId));
        if (project) {
          this.allTeams = project.teams;
          this.newSelectedTeam = null;
        }
      } else {
        this.newSelectedTeam = [];
        this.allTeams = null;
      }
    },

    updateSelectedTeam(event) {
      this.newSelectedTeam = event.target.value;
    },

    submitForm() {
      axios.post(route('requiredProjectItems.clone', {
            project: this.newSelectedProjectId,
            team: this.newSelectedTeam,
            records: this.selectedRecords
          }
      )).then(() => {
        this.$emit('added-success', 'Artykuły zostały sklonowane poprawnie.');
        this.$emit('updated-article');
        this.hide();
      }).catch(error => {
        this.error = error.response.data
        console.log('error', error);
      });
    },

    recalculateQuantities() {
      if (!this.newSelectedTeam || !this.allTeams) return;

      let team = this.allTeams.find(item => item.name === this.newSelectedTeam);
      if (!team) return;

      this.selectedRecords.forEach(record => {
        if (record.org_quantity_required === undefined) {
          record.org_quantity_required = record.quantity_required; // Zapisz oryginalną wartość
        }
        record.quantity_required = record.org_quantity_required * (team.quantity || 1);
      });
    },

    setNewArticle(recordToChange) {
      this.recordToChange = recordToChange;
      this.$refs.setNewArticleModal.show();
    },

    replaceArticle(newArticle) {
      this.selectedRecords.find(item => item.id === this.recordToChange.id).article = newArticle;
    },

    show() {
      if (this.modalObj) {
        this.modalObj.show();
      }
    },
    hide() {
      if (this.modalObj) {
        this.modalObj.hide();
      }
    },
  }
}

</script>

<style scoped>

</style>