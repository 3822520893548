<template>
  <AppLayout>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-six offer-card historyBackground">
        <div class="widget-heading" style="display:block">
          <div class="row">
            <div class="col-xl-12 col-md-12 col-sm-12 col-12 d-inline-flex align-items-center">
              <h4 class="me-3 my-0 text-danger">Wersja Historyczna Oferty</h4>
              <h4 class="active mb-0">
                <span id="offerNumber" class="me-2">{{ offerVersion.number }}</span>
                <span class="badge badge-top badge-success" v-if="offerVersion.is_active">Active</span>
              </h4>
              <Link :href="route('offers.show', offerVersion.offer_id)" class="btn btn-sm btn-outline-primary ms-auto">
                Przejdź do aktualnej wersji
              </Link>
            </div>
          </div>
        </div>

        <div class="widget-content">

          <div v-if="$page.props.errors">
            <ul style="list-style-type: none; padding: 0" v-for="error in $page.props.errors">
              <li class="alert alert-danger">{{ error }}</li>
            </ul>
          </div>

          <div class="row">
            <div class="col-ms-12 col-md-6">
              <div class="col-12">
                <label class="form-label" for="customer_id">Klient: </label>
                {{ this.offer.customer.name }}
              </div>
              <div class="col-12">
                <label class="form-label" for="customer_id">Osoby kontaktowe: </label>
                <span v-for="contakt in this.offer.offer_customer_contacts">
                  {{ contakt.first_name }} {{ contakt.last_name }},&nbsp;
                </span>
              </div>
            </div>

            <div class="col-ms-12 col-md-6">
              <label class="form-label" for="title">Tytuł:</label>
              <input class="form-control form-control-sm" id="title" v-model="offerVersion.title"/>
            </div>
          </div>

          <RichTextEditorComponent class="mt-2"
                                   id="editorIntroduction"
                                   label="Wstęp"
                                   modelName="introduction"
                                   :modelText="offerVersion.introduction"
                                   :hidden="true"
                                   :language="offer.language"
                                   @updateModel="">
          </RichTextEditorComponent>

          <RichTextEditorComponent class="mt-2"
                                   id="editorDescription"
                                   label="Opis"
                                   modelName="description"
                                   :modelText="offerVersion.description"
                                   :hidden="false"
                                   :language="offer.language"
                                   @updateModel="">
          </RichTextEditorComponent>

          <RichTextEditorComponent class="mt-2"
                                   id="editorTechnicalData"
                                   label="Dane techniczne"
                                   modelName="technical_data"
                                   :modelText="offerVersion.technical_data"
                                   :hidden="false"
                                   :language="offer.language"
                                   @updateModel="">
          </RichTextEditorComponent>

          <RichTextEditorComponent class="mt-2"
                                   id="editorPriceDetails"
                                   label="Rozbicie cenowe"
                                   modelName="price_details"
                                   :modelText="offerVersion.price_details"
                                   :hidden="false"
                                   :language="offer.language"
                                   @updateModel="">
          </RichTextEditorComponent>

          <RichTextEditorComponent class="mt-2"
                                   id="editorEpilogue"
                                   label="Zakończenie"
                                   modelName="epilogue"
                                   :modelText="offerVersion.epilogue"
                                   :hidden="true"
                                   :language="offer.language"
                                   @updateModel="">
          </RichTextEditorComponent>

          <div class="row">
            <div class="col-sm-12 col-md-6">

              <InputComponent class="mt-2"
                              type="number"
                              :attributes="{}"
                              label="Gwarancja (w miesiącach)"
                              modelName="warranty"
                              :modelText="offerVersion.warranty"
                              :language="offer.language"
                              :hidden="false"
                              @updateModel="">
              </InputComponent>

              <InputComponent class="mt-2"
                              type="number"
                              :attributes="{}"
                              label="Gwarancja+"
                              modelName="warranty_plus"
                              :modelText="offerVersion.warranty_plus"
                              :language="offer.language"
                              :hidden="false"
                              @updateModel="">
              </InputComponent>

              <TextAreaComponent class="mt-2"
                                 :attributes="{}"
                                 label="Opis gwarancji"
                                 modelName="warranty_description"
                                 :modelText="offerVersion.warranty_description"
                                 :language="offer.language"
                                 :hidden="false"
                                 :info="['Tag [Gwarancja] - wartość pola \'Gwarancja\' przepisuje się do \'Opisu Gwarancji\''
                                 ,'Tag [GwarancjaPlus] - j.w.']"
                                 @updateModel="">
              </TextAreaComponent>

              <InputComponent class="mt-2"
                              type="number"
                              :attributes="{}"
                              label="Czas reakcji serwisowej(w godzinach)"
                              modelName="service_response_hours"
                              :modelText="offerVersion.service_response_hours"
                              :language="offer.language"
                              :hidden="true"
                              @updateModel="">
              </InputComponent>

              <TextAreaComponent class="mt-2"
                                 :attributes="{}"
                                 label="Punkty serwisowe"
                                 modelName="service_points"
                                 :modelText="offerVersion.service_points"
                                 :language="offer.language"
                                 :hidden="true"
                                 @updateModel="">
              </TextAreaComponent>

              <TextAreaComponent class="mt-2"
                                 :attributes="{}"
                                 label="Zmiany techniczne"
                                 modelName="technical_changes"
                                 :modelText="offerVersion.technical_changes"
                                 :language="offer.language"
                                 :hidden="true"
                                 @updateModel="">
              </TextAreaComponent>

              <TextAreaComponent class="mt-2"
                                 :attributes="{}"
                                 label="Zmiany koncepcji"
                                 modelName="conceptual_changes"
                                 :modelText="offerVersion.conceptual_changes"
                                 :language="offer.language"
                                 :hidden="true"
                                 @updateModel="">
              </TextAreaComponent>

              <TextAreaComponent class="mt-2"
                                 :attributes="{}"
                                 label="Siła wyższa"
                                 modelName="force_majeure"
                                 :modelText="offerVersion.force_majeure"
                                 :language="offer.language"
                                 :hidden="true"
                                 @updateModel="">
              </TextAreaComponent>

              <TextAreaComponent class="mt-2"
                                 :attributes="{}"
                                 label="Odbiór wstępny FAT"
                                 modelName="initial_FAT_acceptance"
                                 :modelText="offerVersion.initial_FAT_acceptance"
                                 :language="offer.language"
                                 :hidden="true"
                                 @updateModel="">
              </TextAreaComponent>

              <TextAreaComponent class="mt-2"
                                 :attributes="{}"
                                 label="Przygotowanie maszyny do wysyłki"
                                 modelName="preparation_for_shipping_machine"
                                 :modelText="offerVersion.preparation_for_shipping_machine"
                                 :language="offer.language"
                                 :hidden="true"
                                 @updateModel="">
              </TextAreaComponent>

              <TextAreaComponent class="mt-2"
                                 :attributes="{}"
                                 label="Dostawa"
                                 modelName="delivery_desc"
                                 :modelText="offerVersion.delivery_desc"
                                 :language="offer.language"
                                 :hidden="true"
                                 @updateModel="">
              </TextAreaComponent>

              <TextAreaComponent class="mt-2"
                                 :attributes="{}"
                                 label="Montaż"
                                 modelName="assembly"
                                 :modelText="offerVersion.assembly"
                                 :language="offer.language"
                                 :hidden="true"
                                 @updateModel="">
              </TextAreaComponent>

              <label class="form-label">Termin realizacji:</label>
              <div class="d-block pt-2">
                <label class="w-100 switch s-icons s-outline s-outline-success"
                       style="padding-left: 56px; padding-top: 2px;" for="completTimStarFroContSub">
                  <input type="checkbox" id="completTimStarFroContSub" autocomplete="off"
                         name="completion_time_start_from_contract_submission"
                         v-model="offerVersion.completion_time_start_from_contract_submission"/>
                  <span class="slider round"></span> Liczony od przesłania umowy </label>
              </div>

              <div class="d-inline-flex" v-if="!offerVersion.completion_time_start_from_contract_submission">
                <div class="btn-group" role="group" aria-label="Termin realizacji">
                  <input type="radio" class="btn-check" name="completion_time_format" value="cw" id="ct_cw"
                         autocomplete="off" @change="changeCompletionTimeFormat"
                         v-model="offerVersion.completion_time_format"
                         :checked="offerVersion.completion_time_format === 'cw'">
                  <label class="btn btn-outline-primary" for="ct_cw">Tydzień roku</label>

                  <input type="radio" class="btn-check" name="completion_time_format" value="" id="ct_date"
                         autocomplete="off" @change="changeCompletionTimeFormat"
                         v-model="offerVersion.completion_time_format"
                         :checked="offerVersion.completion_time_format === null">
                  <label class="btn btn-outline-primary" for="ct_date">Data</label>
                </div>
              </div>

              <div class="d-inline-flex ms-2" v-if="!offerVersion.completion_time_start_from_contract_submission">
                <div class="input-group" v-if="offerVersion.completion_time_format === 'cw'">
                  <div class="input-group-text" id="btnGroupAddon">CW</div>
                  <input type="text" class="form-control form-control-sm" style="min-width: 150px; max-width: 200px"
                         placeholder="Tydzień roku" aria-label="Tydzień roku" aria-describedby="btnGroupAddon"
                         v-model="offerVersion.completion_time">
                </div>
                <div class="input-group" v-else>
                  <input type="date" class="form-control form-control-sm" style="min-width: 150px;max-width: 200px"
                         aria-label="Data" aria-describedby="btnGroupAddon" v-model="offerVersion.completion_time">
                </div>
              </div>

              <div class="d-inline-flex ms-2" v-if="offerVersion.completion_time_start_from_contract_submission">

                <div class="btn-group" role="group" aria-label="Termin realizacji">
                  <input type="radio" class="btn-check" name="completion_time_format" value="months"
                         @updateModel="" id="completion_time_unit_months" autocomplete="off"
                         v-model="offerVersion.completion_time_unit"
                         :checked="offerVersion.completion_time_unit === 'months'">
                  <label class="btn btn-outline-primary" for="completion_time_unit_months">Miesiące</label>

                  <input type="radio" class="btn-check" name="completion_time_format" value="weeks"
                         @updateModel="" id="completion_time_unit_weeks" autocomplete="off"
                         v-model="offerVersion.completion_time_unit"
                         :checked="offerVersion.completion_time_unit === 'weeks'">
                  <label class="btn btn-outline-primary" for="completion_time_unit_weeks">Tygodnie</label>
                </div>

                <input type="number" step="1" min="1" max="999" class="ms-2 form-control form-control-sm"
                       style="min-width: 250px; height: 40px" placeholder="od przesłania oferty"
                       v-model="offerVersion.completion_time" aria-label="Tydzień roku"
                       aria-describedby="btnGroupAddon">
              </div>

              <TextAreaComponent class="mt-2"
                                 :attributes="{}"
                                 label="Komentarz"
                                 modelName="completion_time_comment"
                                 :modelText="offerVersion.completion_time_comment"
                                 :language="offer.language"
                                 :hidden="true"
                                 @updateModel="">
              </TextAreaComponent>

            </div>

            <div class="col-sm-12 col-md-6">
              <TextAreaComponent class="mt-2"
                                 :attributes="{}"
                                 label="Uruchomienie"
                                 modelName="launch"
                                 :modelText="offerVersion.launch"
                                 :language="offer.language"
                                 :hidden="true"
                                 @updateModel="">
              </TextAreaComponent>

              <TextAreaComponent class="mt-2"
                                 :attributes="{}"
                                 label="Szkolenie / Wsparcie"
                                 modelName="support"
                                 :modelText="offerVersion.support"
                                 :language="offer.language"
                                 :hidden="true"
                                 @updateModel="">
              </TextAreaComponent>

              <TextAreaComponent class="mt-2"
                                 :attributes="{}"
                                 label="Odbiór końcowy SAT"
                                 modelName="final_SAT_acceptance"
                                 :modelText="offerVersion.final_SAT_acceptance"
                                 :language="offer.language"
                                 :hidden="true"
                                 @updateModel="">
              </TextAreaComponent>

              <TextAreaComponent class="mt-2"
                                 :attributes="{}"
                                 label="Dokumentacja"
                                 modelName="documentation"
                                 :modelText="offerVersion.documentation"
                                 :language="offer.language"
                                 :hidden="true"
                                 @updateModel="">
              </TextAreaComponent>

              <TextAreaComponent class="mt-2"
                                 :attributes="{}"
                                 label="Oznakowanie CE"
                                 modelName="ce_certification"
                                 :modelText="offerVersion.ce_certification"
                                 :language="offer.language"
                                 :hidden="true"
                                 @updateModel="">
              </TextAreaComponent>

              <TextAreaComponent class="mt-2"
                                 :attributes="{}"
                                 label="Dostawa"
                                 modelName="delivery"
                                 :modelText="offerVersion.delivery"
                                 :language="offer.language"
                                 :hidden="false"
                                 @updateModel="">
              </TextAreaComponent>

              <TextAreaComponent class="mt-2"
                                 :attributes="{}"
                                 label="Warunki płatności"
                                 modelName="term_of_payment"
                                 :modelText="offerVersion.term_of_payment"
                                 :language="offer.language"
                                 :hidden="false"
                                 @updateModel="">
              </TextAreaComponent>

              <InputComponent class="mt-2"
                              type="text"
                              :attributes="{}"
                              label="Kod pocztowy dostawy"
                              modelName="zip_code_delivery"
                              :modelText="offerVersion.zip_code_delivery"
                              :language="offer.language"
                              :hidden="false"
                              @updateModel="">
              </InputComponent>

              <InputComponent class="mt-2"
                              type="text"
                              :attributes="{}"
                              label="Miasto dostawy"
                              modelName="city_delivery"
                              :modelText="offerVersion.city_delivery"
                              :language="offer.language"
                              :hidden="false"
                              @updateModel="">
              </InputComponent>

              <InputComponent class="mt-2"
                              type="text"
                              :attributes="{}"
                              label="Adres dostawy (ulica i numer budynku)"
                              modelName="street_delivery"
                              :modelText="offerVersion.street_delivery"
                              :language="offer.language"
                              :hidden="false"
                              @updateModel="">
              </InputComponent>

              <InputComponent class="mt-2"
                              type="text"
                              :attributes="{}"
                              label="Numer lokalu dostawy"
                              modelName="apartment_delivery"
                              :modelText="offerVersion.apartment_delivery"
                              :language="offer.language"
                              :hidden="false"
                              @updateModel="">
              </InputComponent>

              <InputComponent class="mt-2"
                              type="number"
                              :attributes="{min:0, step:0.01}"
                              label="Cena (netto)"
                              modelName="price"
                              :modelText="offerVersion.price"
                              :language="offer.language"
                              :hidden="false"
                              @updateModel="">
              </InputComponent>

              <SelectComponent class="mt-2"
                               :attributes="{}"
                               label="Waluta"
                               modelName="currency"
                               :modelText="offerVersion.currency"
                               :language="offer.language"
                               :hidden="false"
                               :options="[{'PLN': 'PLN'}, {'EUR': 'EUR'}, {'USD': 'USD'}]"
                               @updateModel="">
              </SelectComponent>

              <TextAreaComponent class="mt-2"
                                 :attributes="{}"
                                 label="Uwagi"
                                 modelName="comments"
                                 :modelText="offerVersion.comments"
                                 :language="offer.language"
                                 :hidden="true"
                                 @updateModel="">
              </TextAreaComponent>

              <InputComponent class="mt-2"
                              type="date"
                              :attributes="{}"
                              label="Data ważności oferty"
                              modelName="expiration_date"
                              :modelText="offerVersion.expiration_date"
                              :language="offer.language"
                              :hidden="false"
                              @updateModel="">
              </InputComponent>

              <SelectComponent class="mt-2"
                               :attributes="{}"
                               label="Status"
                               modelName="status_id"
                               :modelText="offer.status_id"
                               :language="offer.language"
                               :hidden="false"
                               :options="computedStatuses"
                               @updateModel="">
              </SelectComponent>

              <div class="row mt-4 align-items-center">
                <div class="col-auto">
                  <label class="form-label" for="status">Aktywna wersja:</label>
                </div>
                <div class="col-auto">
                  <label class="switch s-icons s-outline s-outline-success mb-3 h-100">
                    <input type="checkbox" v-model="offerVersion.is_active">
                    <span class="slider round"></span> </label>
                </div>
              </div>

            </div>
          </div>

          <hr>

          <div class="row">
            <div class="d-flex justify-content-between align-items-center">
              <span class="h4 text-danger">
              Wersja Historyczna oferty
              </span>
              <div class="mx-auto">
                <offerPdfPreviewGenerator class="me-3" :offer="offer" :formData="offerVersion"
                                          aria-action="offerPdf.preview"/>
                <offerPdfDownloadGenerator :offer="offer" :formData="offerVersion" aria-action="offerPdf.preview"/>
              </div>
              <span class="h4 text-danger">
                  Wersja Historyczna oferty
              </span>
            </div>
          </div>

        </div>
      </div>
    </div>
  </AppLayout>
</template>


<script>

import {Link, usePage} from "@inertiajs/vue3";
import {nextTick, reactive, ref} from "vue";
import AppLayout from "@/Layouts/App.vue";

import CreateEditCustomer from "@/Pages/Modals/CreateEditCustomer.vue"

import offerPdfPreviewGenerator from "@/Components/OfferPdfPreviewGenerator.vue";
import offerPdfDownloadGenerator from "@/Components/OfferPdfDownloadGenerator.vue";
import OfferCustomerContactSelectedAvailable from "@/Pages/Offers/offerCustomerContactSelectedAvailable.vue";

import 'select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.min.css';
import 'select2/dist/css/select2.min.css';

import OfferVersionHistoryComponent from '@/Pages/Modals/OfferVersionHistoryComponent.vue';

import RichTextEditorComponent from "@/Pages/Offers/form-elements/RichTextEditorComponent.vue";
import InputComponent from "@/Pages/Offers/form-elements/InputComponent.vue";
import TextAreaComponent from "@/Pages/Offers/form-elements/TextAreaComponent.vue";
import SelectComponent from "@/Pages/Offers/form-elements/SelectComponent.vue";

export default {
  components: {
    OfferVersionHistoryComponent,
    CreateEditCustomer,
    SelectComponent,
    TextAreaComponent,
    InputComponent,
    RichTextEditorComponent,
    AppLayout,
    Link,
    offerPdfPreviewGenerator,
    offerPdfDownloadGenerator,
    OfferCustomerContactSelectedAvailable
  },

  props: {
    thisOffer: Object,
    offerVersion: Object,
    statuses: Object,
    files: Object,
  },

  setup() {
    const {props} = usePage();

    let offer = ref(props.thisOffer);
    // ustaw defaultowy język
    offer._rawValue.language = offer._rawValue.language ?? 'pl';


    return {
      defaultLang: offer._rawValue.language,
    };
  },

  data() {
    // ustaw pliki do offer
    let offerVersionFiles = {};
    if (this.files && Object.keys(this.files).length > 0) {
      offerVersionFiles = this.files;
    }

    return {
      offer: this.thisOffer,
      isProcessingLang: false,
      floatingButtonVisible: true,
      loading: false,
      saveButtonType: false,
      offerVersionFiles,
      loadedCustomerContacts: [],
      customers: [],
      customerContacts: this.thisOffer.offer_customer_contacts,
      chosenCustomer: this.thisOffer.customer,
      selectedCustomerId: this.thisOffer.customer.id
    }
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  computed: {
    computedStatuses() {
      let statuses = [];
      this.statuses.forEach(function (status, idx) {
        statuses.push({[status.id]: status.status});
      })
      return statuses;
    },
  },

  methods: {
    changeCompletionTimeFormat() {
      this.offerVersion.completion_time_format = event.target.value;
    },

    handleScroll() {
      // Sprawdź, czy strona została przewinięta do końca card
      this.offerCard = $('.offer-card').height();
      const scrollTop = $(window).scrollTop() + $(window).height();
      this.floatingButtonVisible = this.offerCard > scrollTop;
    },
  },
}

</script>

<style scoped>
.historyBackground {
  background-color: #e8e8e8;
}

.offer-card {
  position: relative;
  z-index: 1;
  color: black;
}
</style>