<template>
  <div>
    <div class="modal fade" id="articleSelectionModal" ref="modalEle" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-full-width h-100" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Wybierz zapytanie ofertowe</h5>
            <button type="button" class="close ms-auto" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-if="isAddingToQuoteRequest">
              <ejs-grid ref="addingToQuoteRequestRefGrid" id="addingToQuoteRequestGrid" height="76vh"
                        :dataSource="dataManager"
                        :allowTextWrap='false'
                        :allowExcelExport='false'
                        :allowPaging="true"
                        :allowSorting='true'
                        :allowFiltering='true'
                        :allowReordering='false'
                        :allowResizing='true'
                        :showColumnChooser='true'
                        :allowSelection='true'
                        :enablePersistence='false'
                        :rowSelecting="rowselecting"
                        :selectionSettings="selectionOptions"
                        :actionBegin="actionBegin"
                        :actionComplete="actionComplete"
                        :pageSettings="pageSettings"
                        :filterSettings="filterOptions"
                        :toolbar="toolbar"
                        :rowSelected="rowSelected"
                        :toolbarClick="toolbarClick">
                <e-columns>
                  <e-column type="checkbox" :headerTemplate="'cTemplate'" width="100"/>
                  <e-column field="id" headerText="ID" width="92" :isPrimaryKey="true" :visible="false"/>
                  <e-column field="request_number" headerText="Numer Zapytania"/>
                  <e-column field="project.number" headerText="Projekt" :allowFiltering="false"/>
                  <e-column field="created_at" headerText="Utworzono" type="datetime" format="dd.MM.yyyy"/>

                  <template v-slot:cTemplate>
                  </template>
                </e-columns>

              </ejs-grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Modal} from "bootstrap";
import {DataManager, Predicate, Query, WebMethodAdaptor} from "@syncfusion/ej2-data";
import {usePage} from "@inertiajs/vue3";
import {TextBox} from '@syncfusion/ej2-inputs';
import {
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  ContextMenu,
  ExcelExport,
  Filter,
  ForeignKey,
  GridComponent,
  Page,
  Reorder,
  Resize,
  Sort,
  Toolbar
} from '@syncfusion/ej2-vue-grids';
import {AutoComplete} from "@syncfusion/ej2-vue-dropdowns";
import {createElement} from "@syncfusion/ej2-base";
import {DropDownList} from "@syncfusion/ej2-dropdowns";

let dropInstanceFilterUnits;

export default {
  components: {
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    'e-column': ColumnDirective
  },

  provide: {
    grid: [Page, ContextMenu, Toolbar, Sort, Filter, Reorder, Resize, ColumnChooser, ExcelExport, ForeignKey]
  },


  props: {
    isAddingToQuoteRequest: Boolean,
    project: Number | String,
    team: Boolean | String,
  },


  mounted() {
    this.modalObj = new Modal(this.$refs.modalEle);
  },

  data() {
    return {
      articles: [],
      modalObj: null,
      selectedQuoteRequest: null,
      selectionOptions: {checkboxMode: "ResetOnRowClick"},
      toolbar: [
        {text: "Dodaj do wybranego zapytania", prefixIcon: 'e-redo', id: 'add_btn', disabled: true},
        {text: "Search", prefixIcon: 'e-search', id: "search", align: 'center'},
      ],
      dataManager: new DataManager({
        url: route('quoteRequests.getList.quoteRequestsOnly'),
        adaptor: new WebMethodAdaptor(),
        crossDomain: true,
        headers: [
          {'X-CSRF-TOKEN': usePage().props.csrf_token,}
        ],
      }),
      pageSettings: {pageSize: 50, pageCount: 5, pageSizes: [10, 20, 50, 100, 200, 500, 1000]},
      filterOptions: {type: "Menu"},
      filterDefault: {operator: 'contains', params: {minLength: 2}},
    };
  },

  methods: {

    rowselecting: function (args) {
      if (args.target && args.target.classList.contains("e-icons"))
        this.$refs.addingToQuoteRequestRefGrid.ej2Instances.clearSelection();
    },

    rowSelected(args) {
      this.selectedQuoteRequest = args.data;
      if (this.selectedQuoteRequest)
        this.$refs.addingToQuoteRequestRefGrid.ej2Instances.toolbarModule.enableItems(['add_btn'], true);
    },

    actionBegin(args) {
      //
      // WYSZUKIWARKA- wyszukiwanie wielu wartości z separatorem (przecinek
      //
      if (args.requestType === 'searching') {
        const keys = args.searchString.split(',');
        let flag = true;
        let predicate;

        if (this.$refs.addingToQuoteRequestRefGrid.ej2Instances.searchSettings.key !== '') {
          this.values = args.searchString;
          keys.forEach((key) => {
            this.$refs.addingToQuoteRequestRefGrid.ej2Instances.getColumns().forEach((col) => {
              if (flag) {
                predicate = new Predicate(col.field, 'contains', key, true);
                flag = false;
              } else {
                let predic = new Predicate(col.field, 'contains', key, true);
                predicate = predicate.or(predic);
              }
            });
          });

          this.$refs.addingToQuoteRequestRefGrid.ej2Instances.query = new Query().where(predicate);
          this.$refs.addingToQuoteRequestRefGrid.ej2Instances.searchSettings.key = '';
          this.refresh = true;
          this.valueAssign = true;
          this.removeQuery = true;
          this.$refs.addingToQuoteRequestRefGrid.ej2Instances.refresh();
        } else {
          this.$refs.addingToQuoteRequestRefGrid.ej2Instances.searchSettings.key = '';
          this.refresh = true;
          this.valueAssign = true;
          this.removeQuery = true;
          this.$refs.addingToQuoteRequestRefGrid.ej2Instances.refresh();
        }
      }
    },

    actionComplete(args) {
      //
      // WYSZUKIWARKA - wyszukiwanie wielu wartości z separatorem (przecinek
      //
      if (args.requestType === "refresh" && this.valueAssign) {
        document.getElementById(
            this.$refs.addingToQuoteRequestRefGrid.ej2Instances.element.id + "_searchbar"
        ).value = this.values;
        this.valueAssign = false;
      } else if (
          document.getElementById(
              this.$refs.addingToQuoteRequestRefGrid.ej2Instances.element.id + "_searchbar"
          ).value === "" &&
          args.requestType === "refresh" &&
          this.removeQuery
      ) {
        this.$refs.addingToQuoteRequestRefGrid.ej2Instances.query = new Query();
        this.removeQuery = false;
        this.$refs.addingToQuoteRequestRefGrid.ej2Instances.refresh();
      }
    },

    toolbarClick(args) {
      if (args.item.id === 'add_btn') {
        this.$emit("quote-request-selected", this.selectedQuoteRequest);
        this.hide();
      }
    },

    show() {
      if (this.modalObj) {
        this.modalObj.show();
      }
    },
    hide() {
      if (this.modalObj) {
        this.modalObj.hide();
      }
    },

  },
};
</script>
