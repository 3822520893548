<template>

  <button type="button" class="btn btn-sm btn-outline-info" data-bs-toggle="modal"
          :data-bs-target="'#modalAddArrangementHistory_'+model" @click="openModal">
    <i class="bi bi-clock-history"></i>&nbsp; Historia zmian
  </button>

  <!-- Modal -->
  <div class="modal fade" :id="'modalAddArrangementHistory_'+model" tabindex="-1" role="dialog" @click.self="closeModal"
       aria-labelledby="modelTitleId" aria-hidden="true" ref="modalHistory">
    <div class="modal-dialog modal-full-width" role="document">
      <div class="modal-content" :ref="'modalContent_'+model">
        <div class="modal-header">
          <h5 class="modal-title">Ostatnie zmiany</h5>
          <button type="button" class="close ms-auto" data-bs-dismiss="modal" aria-label="Close" @click="closeModal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body table-responsive text-start">
          <ejs-grid gridLines="Both" height="100%" ref="grid"
                    :allowTextWrap='false'
                    :dataSource="histories"
                    :allowPaging="false"
                    :allowSorting="false"
                    :allowFiltering="false"
                    :allowReordering="true"
                    :allowResizing="true"
                    :showColumnChooser="true"
                    :allowSelection="true"
                    :enablePersistence="false">
            <e-columns>
              <e-column field="description" headerText="Opis" type="string"></e-column>
              <e-column field="internal" headerText="Wewnętrzne" type="string"></e-column>
              <e-column field="file_url" headerText="Pliki" type="string"></e-column>
              <e-column field="created_by.email" headerText="Utworzone Przez" type="string"></e-column>
              <e-column field="created_at" headerText="Data Utworzenia" type="date"
                        format="dd.MM.yyyy hh:mm:ss"></e-column>
            </e-columns>
          </ejs-grid>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="closeModal">Zamknij</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import route from "../../../../../vendor/tightenco/ziggy/src/js/index.js";

import {
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  Filter,
  GridComponent,
  Page,
  Reorder,
  Resize,
  Sort,
} from '@syncfusion/ej2-vue-grids';
import {ref} from "vue";

export default {
  components: {
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    'e-column': ColumnDirective
  },
  props: {
    project: Object,
    model: String
  },

  setup() {
    let isModalOpen = ref(false);

    const openModal = () => {
      isModalOpen.value = true;
    }

    return {isModalOpen}
  },

  data() {
    return {
      isLoading: false,
      histories: [],
      previousRowData: null,
    };
  },

  provide: {
    grid: [Page, Sort, Filter, Reorder, Resize, ColumnChooser]
  },

  watch: {
    isModalOpen(newValue) {
      if (newValue === true) {
        this.getHistoryForProjects()
      }
    }
  },

  methods: {

    getHistoryForProjects() {
      axios.post(route('project.get.additional-arrangements-history', {project: this.project}))
          .then((response) => {
            this.histories = response.data;
          })
          .catch((error) => {
            console.log(error);
          });

    },

    openModal() {
      this.isModalOpen = true;
    },

    closeModal(model) {
      this.isModalOpen = false;
      this.histories = [];
      // Usunięcie nasłuchiwania zdarzenia hidden.bs.modal
      let refModal = 'modalHistory_' + model;
      $(this.$refs[refModal]).off("hidden.bs.modal", () => this.closeModal(model));
    },
  },
};
</script>

<style>
.different-cell {
  color: orangered !important;
  border: 1px solid orangered !important;
}
</style>
