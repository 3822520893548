<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="ShowHistoryModal" ref="modalEle" tabindex="-1" role="dialog"
         aria-labelledby="modelTitleId"
         aria-hidden="true">
      <div class="modal-dialog modal-full-width" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Pokaż historię</h5>

            <button type="button" class="close ms-auto" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-if="error" class="alert alert-danger" role="alert">
              {{ error }}
            </div>

            <div class="row mb-5">
              <div class="col-md-12 col-lg-6">
                <label for="project-select">Wybierz projekt:</label>
                <select id="project-select" class="form-select form-select-sm" @change="updateSelectedProject"
                        v-model="newSelectedProjectId">
                  <option value="0" selected>Wszystkie projekty</option>
                  <option v-for="project in projects" :key="project.id" :value="project.id">
                    {{ project.label }}
                  </option>
                </select>
              </div>

              <div class="col-md-12 col-lg-6">
                <label for="team-select">Wybierz zespół:</label>
                <select id="team-select" class="form-select form-select-sm" @change="updateSelectedTeam"
                        v-model="newSelectedTeam" :disabled="!newSelectedProjectId || (allTeams.length === 0)">
                  <option value="">Wszystkie zespoły</option>
                  <option v-for="team in allTeams" :key="team.name" :value="team.name">
                    {{ team.name }}
                  </option>
                </select>
              </div>
            </div>


            <div class="widget-content">
              <div>
                <ejs-grid ref="requiredProjectItemsHistoryGrid" id="requiredProjectItemsHistoryGrid" height="500px"
                          :dataSource="requiredProjectItemsHistoryDataManager"
                          :allowTextWrap='false'
                          :allowExcelExport='false'
                          :allowPaging="true"
                          :allowSorting='true'
                          :allowFiltering='true'
                          :allowReordering='true'
                          :allowResizing='true'
                          :showColumnChooser='true'
                          :allowSelection='false'
                          :enablePersistence='false'
                          :pageSettings="pageSettings"
                          :filterSettings="filterOptions"
                          :selectionSettings="selectionOptions"
                          :headerCellInfo="headerCellInfoEvent">
                  <e-columns>
                    <e-column field="id" headerText="Nr poz." width="125" :isPrimaryKey="true"
                    ></e-column>
                    <e-column field="team_name" headerTooltip="Zespół" headerText="Zespół"
                              :filter="filterDefault"></e-column>
                    <e-column field="subteam_drawing_no" headerTooltip="Podzespół nr rysunku"
                              headerText="Podzespół nr rysunku"
                              :filter="filterDefault"></e-column>
                    <e-column field="article.catalog_number" headerTooltip="Nr katalogowy" headerText="Nr katalogowy"
                              :filter="filterDefault"></e-column>
                    <e-column field="article.model" headerTooltip="Model" headerText="Model"
                              :filter="filterDefault"></e-column>
                    <e-column field="article.description" headerTooltip="Nazwa/Opis" headerText="Nazwa/Opis" width="200"
                              :filter="filterDefault"></e-column>
                    <e-column field="article.link" headerTooltip="Link" headerText="Link" :filter="filterDefault"
                              :template="'linkTemplate'"></e-column>
                    <e-column field="article.article_unit.name" headerTooltip="J.m." headerText="J.m." width="105"
                              :dataSource="units" :filter="filterUnits"></e-column>
                    <e-column field="article.producer.id" headerTooltip="Producent" headerText="Producent"
                              foreignKeyValue='short_name'
                              foreignKeyField='id' :dataSource="producers" :filter='filterProducers'
                    ></e-column>
                    <e-column field="article.customer_category.id" foreignKeyValue='name' foreignKeyField='id'
                              :dataSource="categories" :filter='filterCategories' headerTooltip="Kategoria"
                              headerText="Kategoria"
                    ></e-column>
                    <e-column field="quantity_required" headerTooltip="Ilość wymagana" headerText="Ilość wymagana"
                              format="N" type="number"
                              ></e-column>
                    <e-column field="suppliers_short_names" headerTooltip="Dostawcy" headerText="Dostawcy"
                               editType="string"
                              :filter='filterSuppliers' :edit="suppliersParams"></e-column>
                    <e-column field="created_by.name" headerTooltip="Dodany przez" headerText="Dodany przez"
                    ></e-column>
                    <e-column field="created_at" headerTooltip="Data dodania" headerText="Data dodania"
                              type="date"
                              format="dd.MM.yyyy H:mm:ss"></e-column>
                    <e-column field="status" headerTooltip="Status" headerText="Status"
                              editType="dropdownedit"
                              :edit="requiredProjectItemsStatusesParams"></e-column>
                    <e-column field="delivery_date" headerTooltip="Data dostawy" headerText="Data dostawy"
                               type="date"
                              format="dd.MM.yyyy" editType="datepickeredit"></e-column>
                    <e-column field="release_date" headerTooltip="Data zwolnienia" headerText="Data zwolnienia"
                               type="date"
                              format="dd.MM.yyyy" editType="datepickeredit"></e-column>
                    <e-column field="order_submission_date" headerTooltip="Data Zlec. Zam." headerText="Data Zlec. Zam."
                               type="date"
                              format="dd.MM.yyyy" editType="datepickeredit"></e-column>
                    <e-column field="order_status_confirmation_date" headerTooltip="Data Wydr. Zam."
                              headerText="Data Wydr. Zam."
                              type="date" format="dd.MM.yyyy" editType="datepickeredit"></e-column>
                    <e-column field="inventory_status" headerTooltip="Status Mag." headerText="Status Mag."
                               editType="dropdownedit"
                              :edit="inventoryStatusesParams"></e-column>
                    <e-column field="production_due_date" headerTooltip="Data Prod." headerText="Data Prod."
                               type="date"
                              format="dd.MM.yyyy"></e-column>
                    <e-column field="lead_time" headerTooltip="Czas dostawy" headerText="Czas dostawy"
                               type="date"
                              format="dd.MM.yyyy"></e-column>
                    <e-column field="comments" headerTooltip="Uwagi" headerText="Uwagi"
                              type="string"></e-column>
                    <e-column field="updated_by.name" headerTooltip="Ostatnio zmienił" headerText="Ostatnio zmienił"
                    ></e-column>
                    <e-column field="article_main_warehouse.quantity" headerTooltip="Ilość magazynowa"
                              headerText="Ilość magazynowa" format="N" type="number"
                    ></e-column>
                    <e-column field="warehouse_received" headerTooltip="Magazyn" headerText="Magazyn"
                              :filter="filterDefault"></e-column>
                    <e-column field="patent_number" headerTooltip="Nr Patentu" headerText="Nr Patentu"
                              ></e-column>
                    <e-column field="none!!!!!!" headerTooltip="Przekr. term. dost." headerText="Przekr. term. dost."
                    ></e-column>
                    <e-column :template="'quantityTemplate'" headerTooltip="Ilość do zam." headerText="Ilość do zam."
                    ></e-column>
                    <e-column field="barcode" headerTooltip="Kody kreskowe" headerText="Kody kreskowe"
                              ></e-column>
                    <e-column field="history_created_at" headerTooltip="Data Utworzenia" headerText="Data Utworzenia"
                              ></e-column>
                    <e-column field="history_created_by_name" headerTooltip="Utworzone przez" headerText="Utworzone przez"
                              ></e-column>
                  </e-columns>

                  <template v-slot:linkTemplate="{data}">
                    <a class="link link-primary" target="_blank" rel="noopener noreferrer" :href=data.article?.link>
                      {{ data.article?.link }}</a>
                  </template>

                  <template v-slot:quantityTemplate="{ data }">
                    {{ (data.numbering?.quantity || 0) * (data.quantity_required || 0) }}
                  </template>

                </ejs-grid>
              </div>

            </div>


          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              <i class="bi bi-x-square me-2"></i>Zamknij
            </button>

          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import {Modal} from "bootstrap";
import {
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  ContextMenu,
  Edit,
  ExcelExport,
  Filter, ForeignKey,
  GridComponent,
  Page,
  Reorder,
  Resize,
  Sort,
  Toolbar,
  RowDD,
  VirtualScroll
} from '@syncfusion/ej2-vue-grids';
import {createElement} from "@syncfusion/ej2-base";
import {DropDownList} from "@syncfusion/ej2-dropdowns";
import {Tooltip} from "@syncfusion/ej2-popups";
import {DataManager, WebMethodAdaptor} from "@syncfusion/ej2-data";
import {usePage} from "@inertiajs/vue3";
import {MultiSelect} from "@syncfusion/ej2-vue-dropdowns";

let dropInstanceFilterUnits, delivererElem, multiSelectObj, statusElem, statusObj;

export default {
  components: {
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    'e-column': ColumnDirective
  },

  provide: {
    grid: [Page, Edit, Toolbar, ContextMenu, Sort, Filter, Reorder, Resize, ColumnChooser, ExcelExport, ForeignKey, RowDD, VirtualScroll]
  },

  props: {
    selectedProject: Number | String,
    selectedTeam: Boolean | String,
    projects: Object,
    teams: Object,
    units: Object,
    suppliers: Object,
    statuses: Object,
    categories: Object,
    producers: Object,
    warehouses: Object,
  },

  data() {
    return {
      newSelectedProjectId: this.selectedProject,
      newSelectedTeam: this.selectedTeam,
      allTeams: this.teams,
      error: null,
      requiredProjectItemsHistoryDataManager: [],
      pageSettings: {pageSize: 50, pageCount: 5},
      filterOptions: {type: "Menu", operator: 'contains'},
      filterDefault: {operator: 'contains', params: {minLength: 2}},
      selectionOptions: {checkboxOnly: true},

      filterUnits: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.units,
              fields: {text: 'name', value: 'id'},
              placeholder: 'Szukaj jednostki',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterCategories: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.categories,
              fields: {text: 'name', value: 'id'},
              placeholder: 'Szukaj kategorii',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterProducers: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.producers,
              fields: {text: 'short_name', value: 'id'},
              placeholder: 'Szukaj producentów',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterWarehouses: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.warehouses,
              fields: {text: 'short_name', value: 'id'},
              placeholder: 'Szukaj magazynu',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterSuppliers: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.producers,
              fields: {text: 'short_name', value: 'id'},
              placeholder: 'Szukaj dostawców',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      suppliersParams: {
        create: () => {
          delivererElem = document.createElement('input');
          return delivererElem;
        },
        read: () => {
          return multiSelectObj.value.join(",");
        },
        destroy: () => {
          multiSelectObj.destroy();
        },
        write: (args) => {
          const selectedValues = args.rowData.suppliers_short_names ? args.rowData.suppliers_short_names.split(",") : [];

          // Inicjalizacja MultiSelect
          multiSelectObj = new MultiSelect({
            value: selectedValues,
            dataSource: this.suppliers,
            fields: {text: "short_name", value: "short_name"},
            placeholder: 'Wybierz dostawców',
            mode: 'Box',
            allowFiltering: true,
            popupHeight: '200px',
            closePopupOnSelect: false,
          });

          multiSelectObj.appendTo(delivererElem);
        }
      },

      requiredProjectItemsStatusesParams: {
        create: () => {
          statusElem = document.createElement('input');
          return statusElem;
        },
        read: () => {
          return statusObj.text;
        },
        destroy: () => {
          statusObj.destroy();
        },
        write: () => {
          statusObj = new DropDownList({
            dataSource: this.required_project_items_statuses,
            fields: {value: 'name', text: 'name'},
            enabled: true,
            placeholder: 'Wybierz status',
            floatLabelType: 'Never'
          });
          statusObj.appendTo(statusElem);
        }
      },

      inventoryStatusesParams: {
        create: () => {
          statusElem = document.createElement('input');
          return statusElem;
        },
        read: () => {
          return statusObj.text;
        },
        destroy: () => {
          statusObj.destroy();
        },
        write: () => {
          statusObj = new DropDownList({
            dataSource: this.inventory_statuses,
            fields: {value: 'name', text: 'name'},
            enabled: true,
            placeholder: 'Wybierz status',
            floatLabelType: 'Never'
          });
          statusObj.appendTo(statusElem);
        }
      },
    }
  },

  mounted() {
    this.modalObj = new Modal(this.$refs.modalEle);
  },

  methods: {
    updateSelectedProject(event) {
      this.newSelectedProjectId = Number(event.target.value);
      this.newSelectedTeam = null;
      this.$nextTick(() => {
        this.updateTeams()
      });

      this.$nextTick(() => {
        this.getRequiredProjectItemsForProject();
      });
    },

    updateTeams() {
      if (this.newSelectedProjectId) {
        const project = this.projects.find(project => project.id === Number(this.newSelectedProjectId));
        if (project) {
          this.allTeams = project.teams;
          this.newSelectedTeam = null;
        }
      } else {
        this.newSelectedTeam = [];
        this.allTeams = [];
      }
    },

    updateSelectedTeam(event) {
      this.newSelectedTeam = event.target.value;
      this.getRequiredProjectItemsForProject();
    },

    headerCellInfoEvent: function (args) {
      // pokazywanie tooltipów po najechanie na header
      new Tooltip(
          {
            content: args.cell.column.headerTooltip
          },
          args.node
      );
    },

    getRequiredProjectItemsForProject() {
      sessionStorage.setItem('selectedProjectId', this.selectedProjectId);
      sessionStorage.setItem('selectedTeam', this.selectedTeamName);
      sessionStorage.setItem('archivedShown', this.archivedShown);
      document.cookie = `archivedShown=${this.archivedShown || false}; path=/;`;

      this.requiredProjectItemsHistoryDataManager = new DataManager({
        url: route('requiredProjectItemsHistory.getList', {
          project: this.selectedProjectId,
          team: this.selectedTeamName
        }),
        adaptor: new WebMethodAdaptor(),
        // batchUrl: route('requiredProjectItems.saveChanges'),
        // updateUrl: route('requiredProjectItems.updateChanges'),
        crossDomain: true,
        headers: [
          {'X-CSRF-TOKEN': usePage().props.csrf_token},
          {'archivedShown': sessionStorage.getItem('archivedShown') || false}
        ],
      });
    },

    show() {
      if (this.modalObj) {
        this.modalObj.show();
      }
    },
    hide() {
      if (this.modalObj) {
        this.modalObj.hide();
      }
    },
  }
}

</script>

<style scoped>

</style>