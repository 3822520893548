<template>
  <form @submit.prevent="submitForm" ref="projectForm">
    <table class="table table-bordered">
      <thead>
      <tr>
        <th>Kategoria</th>
        <th>Dostawca 1</th>
        <th>Dostawca 2</th>
        <th>Dostawca 3</th>
        <th>Uwagi</th>
        <th>Zatwierdzenia</th>
        <th>Data Zatwierdzenia</th>
        <th>Ostatnia aktualizacja</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(deliverers, category) in projectSuppliers" :key="category">
        <!-- Kategoria -->
        <td>{{ category }}</td>

        <!-- Dostawca 1 -->
        <td>
          <div v-if="deliverers['1']">
            <div v-for="deliverer in deliverers['1']" :key="deliverer.id"
                 class="checkbox-success custom-control custom-checkbox">
              <input :id="`deliverer-1-${deliverer.id}`" type="checkbox" class="custom-control-input"
                     :checked="deliverer.is_selected" @change="changeDeliverer(deliverer, $event)">
              <label :for="`deliverer-1-${deliverer.id}`" class="custom-control-label">{{
                  deliverer.customer?.short_name
                }}</label>
            </div>
          </div>
        </td>

        <!-- Dostawca 2 -->
        <td>
          <div v-if="deliverers['2']">
            <div v-for="deliverer in deliverers['2']" :key="deliverer.id"
                 class="checkbox-success custom-control custom-checkbox">
              <input :id="`deliverer-2-${deliverer.id}`" type="checkbox" class="custom-control-input"
                     :checked="deliverer.is_selected" @change="changeDeliverer(deliverer, $event)">
              <label :for="`deliverer-2-${deliverer.id}`" class="custom-control-label">{{
                  deliverer.customer?.short_name
                }}</label>
            </div>
          </div>
        </td>

        <!-- Dostawca 3 -->
        <td>
          <div v-if="deliverers['3']">
            <div v-for="deliverer in deliverers['3']" :key="deliverer.id"
                 class="checkbox-success custom-control custom-checkbox">
              <input :id="`deliverer-3-${deliverer.id}`" type="checkbox" class="custom-control-input"
                     :checked="deliverer.is_selected" @change="changeDeliverer(deliverer, $event)">
              <label :for="`deliverer-3-${deliverer.id}`" class="custom-control-label">{{
                  deliverer.customer?.short_name
                }}</label>
            </div>
          </div>
        </td>

        <!-- Uwagi -->
        <td @dblclick="editComment(deliverers['1']?.[0], deliverers['1']?.[0]?.category_comment?.comment)">
          <div v-if="!isEditingComment(deliverers['1']?.[0].category.id)" @change="changeComment(deliverers['1']?.[0])">
            {{ deliverers['1']?.[0]?.category_comment?.comment || '' }}
          </div>
          <input v-if="isEditingComment(deliverers['1']?.[0].category.id)"
                 :id="'commentCategory_' + deliverers['1']?.[0].category.id"
                 class="additionalInput form-control form-control-sm"
                 type="text"
                 v-model="formData['category_comment'][deliverers['1']?.[0].category.id]"
                 @keydown.enter.prevent="saveComment(deliverers['1']?.[0])"
                 @blur="saveComment(deliverers['1']?.[0])"
          >
        </td>


        <!-- Zatwierdzenia -->
        <td>
          <div v-for="(name, position) in loggedUserNeedApprove">
            <div class="checkbox-success custom-control custom-checkbox text-color">
              <input type="checkbox" class="custom-control-input"
                     :id="'approveSwitch_'+position+deliverers[Object.keys(deliverers)[0]][0].id"
                     :checked="isApproved(deliverers[Object.keys(deliverers)[0]][0], position)"
                     @change="changeApprove(deliverers[Object.keys(deliverers)[0]][0].category_id, position, $event)">
              <label class="custom-control-label"
                     :for="'approveSwitch_'+position+deliverers[Object.keys(deliverers)[0]][0].id">{{ name }}</label>
            </div>
          </div>

          <div class="text-muted small" v-for="(position, approvalUser) in shouldApprovalSelectedSuppliers">
            <div class="text-success"
                 v-if="isApproved(deliverers[Object.keys(deliverers)[0]][0], approvalUser) === true">
              <i class="bi bi-check-circle"></i> {{ position }}
            </div>
            <div class="text-danger" v-else>
              <i class="bi bi-x-circle"></i> {{ position }}
            </div>
          </div>
        </td>

        <!-- Data zatwierdzenia -->
        <td>
          <div class="text-muted small" v-for="(position, approvalUser) in loggedUserNeedApprove">
            <div v-if="isApproved(deliverers[Object.keys(deliverers)[0]][0], approvalUser, 'date')"> {{
                isApproved(deliverers[Object.keys(deliverers)[0]][0], approvalUser, 'date')
              }}
            </div>
            <div v-else></div>
          </div>
        </td>

        <!-- Ostatnia aktualizacja -->
        <td>
          {{ getLastUpdateDate(deliverers) }}
        </td>
      </tr>
      </tbody>
    </table>
    <button v-if="shouldApprovalSelectedSuppliers && isDirty" type="submit" :disabled="isProcessing"
            class="btn btn-primary">Zaktualizuj
    </button>
  </form>
</template>


<script>
import route from "../../../../../vendor/tightenco/ziggy/src/js/index.js";
import {usePermission} from "@/composables/resources/js/composables/permissions.js";

const {hasPermission} = usePermission();
export default {
  components: {hasPermission},
  props: {
    projectSuppliers: Object,
    shouldApprovalSelectedSuppliers: Object,
    project: Object,
    authUser: Object
  },

  data() {
    let projectSelectedSuppliersApprovals = {};

    // sprawdzenie, czy użytkownik jest na stanowisku X
    let loggedUserNeedApprove = {};
    for (let key in this.shouldApprovalSelectedSuppliers) {
      if (this.authUser.position === this.shouldApprovalSelectedSuppliers[key]) {
        loggedUserNeedApprove[key] = this.shouldApprovalSelectedSuppliers[key];
        delete this.shouldApprovalSelectedSuppliers[key];
      }
    }

    // sprawdzenie, czy użytkownik jest przypisany do projektu jako engineer
    if (this.authUser.id === this.project.engineer_id) {
      loggedUserNeedApprove['engineer'] = this.shouldApprovalSelectedSuppliers['engineer'];
      delete this.shouldApprovalSelectedSuppliers['engineer'];
    }

    // sprawdzenie, czy użytkownik jest przypisany do projektu jako project_manager
    if (this.authUser.id === this.project.project_manager_id) {
      loggedUserNeedApprove['project_manager'] = this.shouldApprovalSelectedSuppliers['project_manager'];
      delete this.shouldApprovalSelectedSuppliers['project_manager'];
    }

    // sprawdzenie, czy użytkownik jest przypisany do projektu jako kierownik działu automatyki i programowania
    if (this.authUser.id === this.project.automation_manager_id) {
      loggedUserNeedApprove['automation_manager'] = this.shouldApprovalSelectedSuppliers['automation_manager'];
      delete this.shouldApprovalSelectedSuppliers['automation_manager'];
    }

    // sprawdzenie, czy użytkownik jest przypisany do projektu jako kierownik działu planowania
    if (this.authUser.id === this.project.planning_manager_id) {
      loggedUserNeedApprove['planning_manager'] = this.shouldApprovalSelectedSuppliers['planning_manager'];
      delete this.shouldApprovalSelectedSuppliers['planning_manager'];
    }

    return {
      hasPermission,
      projectSelectedSuppliersApprovals,
      loggedUserNeedApprove,
      isDirty: false,
      isProcessing: false,
      formData: {
        category_comment: {},
        deliverer: [],
        approves: {}
      },
      editingCategoryId: null
    }
  },

  methods: {
    formIsDirty() {
      this.isDirty = true;
    },

    formIsNotDirty() {
      this.isDirty = false;
      this.formData = {
        category_comment: {},
        deliverer: [],
        approves: {}
      }
    },

    isApproved(supplier, position, returnDate = false) {
      if (!supplier) {
        return false;
      }

      const categoryId = supplier.category_id;
      let selectedSupplier = supplier.selected_suppliers_approvals;

      if (!selectedSupplier) {
        return false;
      }

      for (const supplier of selectedSupplier) {
        if (supplier.position === position && supplier.category_id === categoryId) {
          if (supplier.approved === 1) {
            if (returnDate) {
              return supplier.updated_at || supplier.created_at
            } else
              return true;
          }
        }
      }

      return false;
    },

    changeApprove(category, position, event) {
      this.formData.approves[category] = {position: position, approved: event.target.checked};
      this.formIsDirty();
    },

    changeDeliverer(deliverer, event) {
      this.formIsDirty();
      // aktualizacja stanu checkboxa
      deliverer.is_selected = event.target.checked;
      this.formData.deliverer[deliverer.id] = deliverer;
    },

    getLastUpdateDate(deliverers) {
      let latestDate = null;

      Object.keys(deliverers).forEach(deliveryNo => {
        deliverers[deliveryNo].forEach(deliverer => {
          const updatedAt = new Date(deliverer.updated_at); // Konwertuj na obiekt Date

          // Sprawdź, czy data jest nowsza niż obecnie przechowywana najnowsza data
          if (!latestDate || updatedAt > latestDate) {
            latestDate = updatedAt;
          }
        });
      });

      // Jeśli znaleźliśmy najnowszą datę, formatujemy ją
      if (latestDate) {
        const day = String(latestDate.getDate()).padStart(2, '0');
        const month = String(latestDate.getMonth() + 1).padStart(2, '0'); // Miesiące w JS zaczynają się od 0
        const year = latestDate.getFullYear();
        const hours = String(latestDate.getHours()).padStart(2, '0');
        const minutes = String(latestDate.getMinutes()).padStart(2, '0');
        const seconds = String(latestDate.getSeconds()).padStart(2, '0');

        return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
      }

      return null;
    },

// Sprawdza, czy obecnie edytujemy komentarz dla danej kategorii
    isEditingComment(categoryId) {
      return this.editingCategoryId === categoryId;
    },

    // Zmiana komentarza - metoda do przypisania wartości do formData
    changeComment(categoryComment) {
      let newCommentVal = this.formData['category_comment'][categoryComment.category.id] || '';
      this.formData['category_comment'][categoryComment.category.id] = newCommentVal;
    },

    // Edytuj komentarz - tworzy pole input
    editComment(deliverer, value) {
      this.formIsDirty();
      let val = value === null || value === undefined ? '' : value;
      this.formData['category_comment'][deliverer.category.id] = val;
      this.editingCategoryId = deliverer.category.id; // Ustaw ID edytowanej kategorii
    },

    editApprovalDate(value) {
      this.formIsDirty();
      let val = value === null ? this.getToday() : value;
      event.target.innerHTML = '<input class="additionalInput form-control form-control-sm" type="date" name="approval_date" value="' + val + '">';
    },

    saveComment(deliverer) {
      //formData, aktualizuje wartość w kategorii
      let newComment = this.formData['category_comment'][deliverer.category.id] || '';

      // Sprawdź, czy obiekt category_comment istnieje, jeśli nie, stwórz go
      if (!deliverer.category_comment) {
        deliverer.category_comment = {}; // Inicjalizacja obiektu, jeśli jest null
      }

      // Zaktualizuj pole comment w obiekcie category_comment
      deliverer.category_comment.comment = newComment;
    },

    submitForm() {
      this.isProcessing = true;
      this.editingCategoryId = null; // zakończenie edycji komentarzy
      const self = this;
      const data = this.formData;

      axios.post(route('project.selected-suppliers.update', {project: self.project.id, data})).then(response => {
        Toast.fire({
          toast: true,
          icon: response.data.icon,
          title: response.data.title,
          html: response.data.message,
          showClass: {popup: 'animate__animated animate__fadeInDown'},
          hideClass: {popup: 'animate__animated animate__fadeOutUp'},
          timer: 2500,
        })
      }).catch(error => {
        console.log(error);
        Toast.fire({
          toast: true,
          icon: error.data.icon,
          title: error.data.title,
          html: error.data.message,
          showClass: {popup: 'animate__animated animate__fadeInDown'},
          hideClass: {popup: 'animate__animated animate__fadeOutUp'},
          timer: 2500,
        })
      }).finally(() => {
        this.formIsNotDirty();
        this.isProcessing = false;

        this.changeInputsToDiv();
      })
    },

    changeInputsToDiv() {
      $.each($('.additionalInput'), function (idx, input) {
        $(input).parent().html($(input).val());
        $(input).remove();
      })
    },

    getToday() {
      const date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1; // Miesiące są indeksowane od 0, więc dodajemy 1
      var day = date.getDate();

      return year + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
    }
  }
}
</script>
